import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BlocksModule } from "../components/blocks/blocks.module";
import { SempreNovoModule } from "../components/sempre-novo/sempre-novo.module";
import { LoadingModule } from "../components/common/loading/loading.module";
import { ModalModule } from "../components/common/modal/modal.module";
import { AfterIfModule } from "../directives/afterif/afterif.module";
import { EditorjsparsehtmlModule } from "../directives/editorjsparsehtml/editorjsparsehtml.module";
import { StrapiiconModule } from "../directives/strapiicon/strapiicon.module";
import { StrapiimageModule } from "../directives/strapiimage/strapiimage.module";
import { BlogPostComponent } from "./blog/blog-post/blog-post.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PageComponent } from "./page/page.component";
import { SeminovosBuscaComponent } from "./seminovos-busca/seminovos-busca.component";
import { ConformidadeComponent } from "./nossa-empresa/conformidade/conformidade.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SempreNovoBuscaComponent } from "./sempre-novo-busca/sempre-novo-busca.component";
import { ModeloDetalheComponent } from "./modelo-detalhe/modelo-detalhe.component";
import { DestaqueBuscaComponent } from "./destaque-busca/destaque-busca.component";

@NgModule({
  declarations: [
    PageComponent,
    NotFoundComponent,
    BlogPostComponent,
    SeminovosBuscaComponent,
    ConformidadeComponent,
    SempreNovoBuscaComponent,
    DestaqueBuscaComponent,
    ModeloDetalheComponent,
  ],
  exports: [
    // TranslateModule
  ],
  imports: [
    AfterIfModule,
    ModalModule,
    LoadingModule,
    StrapiimageModule,
    StrapiiconModule,
    EditorjsparsehtmlModule,
    BlocksModule,
    SempreNovoModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class PagesModule {}
