import { Injectable } from "@angular/core";
import { of, delay, Observable, switchMap } from "rxjs";

declare var RdIntegration: any;
declare var RdstationFormsIntegration: any;
@Injectable({
  providedIn: "root",
})
export class RdstationService {
  constructor() {}

  enviar(objData: any) {
    const dados = Object.keys(objData).map((prop) => {
      return { name: prop, value: objData[prop] };
    });

    try {
      if (RdstationFormsIntegration) {
        RdstationFormsIntegration?.Integration?.integrate?.(""); // Desabilita o form Integration 2.0 que está vindo da RDStation pois estamos utilizando o outro modo de integração que é via RdINtegration.post()
      }
    } catch {}

    return of(true).pipe(
      delay(1000),
      switchMap(() => {
        return new Observable<number>((sub) => {
          RdIntegration.post(dados, (XMLHttpRequest: any) => {
            sub.next(XMLHttpRequest.status);
          });
        });
      })
    );
  }
}
