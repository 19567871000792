import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { EditorjsparsehtmlModule } from 'src/app/directives/editorjsparsehtml/editorjsparsehtml.module';
import { StrapiimageModule } from 'src/app/directives/strapiimage/strapiimage.module';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';
import { RouterModule } from '@angular/router';
import { StrapiiconModule } from 'src/app/directives/strapiicon/strapiicon.module';


@NgModule({
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    StrapiimageModule,
    StrapiiconModule,
    StrapilinkModule,
    EditorjsparsehtmlModule
  ]
})
export class FooterModule { }
