import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IAccordion } from 'src/models/zeus/item/accordion';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      state('small', style({ height: '0px' })),
      state('large', style({ height: '*' })),
      transition('small <=> large', animate('300ms ease-in'))
    ])
  ],
})
export class AccordionComponent implements OnInit {
  @Input() public accordionContent!: IAccordion;

  public arrowToggle: boolean = true;
  public state: string = 'small';

  public environment = environment;

  ngOnInit(): void {
  }

  public toggleFaq() {
    this.state = (this.state === 'small' ? 'large' : 'small');
    this.arrowToggle = !this.arrowToggle;
  }
}
