<ng-container *ngIf="banner">
    <div class="container" [ngClass]="banner.height">
        <img [quality]="'90'" [appStrapiimage]="banner.image.data" class="fundo desk" *ngIf="isDesktopLayout">

        <img [quality]="'90'" [appStrapiimage]="banner.imageMobile?.data" class="fundo"
            *ngIf="isMobileLayout && banner.imageMobile?.data != null">
        <img [quality]="'90'" [appStrapiimage]="banner.image.data" class="fundo"
            *ngIf="isMobileLayout && banner.imageTablet?.data == null">

        <img [quality]="'90'" [appStrapiimage]="banner.imageTablet?.data" class="fundo"
            *ngIf="isTabletLayout && banner.imageTablet?.data != null">
        <img [quality]="'90'" [appStrapiimage]="banner.image.data" class="fundo"
            *ngIf="isTabletLayout && banner.imageTablet?.data == null">
        <div class="esquerda ">
            <div [appEditorjsparsehtml]="banner.content" class="content"></div>
            <app-botao-tipo1 *ngIf="banner.link as link" [strapilink]="link" icon="plus.svg" class="mt64"
                cor1="var(--white)" cor2="var(--black-translucent)"></app-botao-tipo1>
        </div>
    </div>
</ng-container>