<app-content class="container pt64 flex flex-column" [content]="content" *ngIf="this.block.content as content"
  [id]="this.block.blockIdentifier"></app-content>
<div class="container flex pt64 pb64 carousel-testimonial" style="position: relative;">

  <swiper [slidesPerView]="1" [autoHeight]="true" [spaceBetween]="50" [navigation]="true" [pagination]="false"
    [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="w-100">
    <ng-template swiperSlide *ngFor="let testimonial of this.block.testimonials">
      <app-testimonial [testimonial]="testimonial"></app-testimonial>
    </ng-template>
  </swiper>
  <div class="container next-prev estilo1" [style.top]="(this.marginFundo | async)+'px'">
    <div class="swiper-button-prev" #prev></div>
    <div class="swiper-button-next" #next></div>
  </div>
</div>