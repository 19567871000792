<div class="flex flex-column">
  <app-sn-block-features></app-sn-block-features>
  <app-sn-block-explore></app-sn-block-explore>
  <app-sn-block-form></app-sn-block-form>

  <!-- <app-content [content]="this.stringfiedText"></app-content> -->

  <div class="container legal">
    <p>
      Para todos os veículos locados, proporcionamos uma garantia estendida de até 1 ano para o trem de força (que inclui
      motor, câmbio e diferencial) de todos os veículos fabricados a partir de 2022, desde que tenham até 150.000km
      rodados.</p>
  
    <p>Para todos os veículos seminovos, proporcionamos uma garantia de 90 dias para o trem de força (que inclui motor,
      diferencial e câmbio).</p>
  
    <p><strong>ATENÇÃO: Todos os preços, características, especificações ou declarações do veiculo estão sujeitos a
        alteração sem aviso prévio.</strong></p>
  </div>
</div>