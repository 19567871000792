import { Component, Input, OnInit } from '@angular/core';
import { IBlockGlossary } from 'src/models/zeus';

@Component({
  selector: 'app-block-glossary',
  templateUrl: './block-glossary.component.html',
  styleUrls: ['./block-glossary.component.scss']
})
export class BlockGlossaryComponent {

  @Input() block!: IBlockGlossary;

}
