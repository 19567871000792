import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IBlockCarousel } from 'src/models/zeus';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-block-carousel',
  templateUrl: './block-carousel.component.html',
  styleUrls: ['./block-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockCarouselComponent implements OnInit {
  public FormStatus = false;
  public formularioFixo = false;

  @Input() block!: IBlockCarousel;
  @Input() primeiroBloco = false;

  public ngOnInit(): void {
    if (this.block?.formularioFixo) {
      this.formularioFixo = true;
    }
  }

  public displayFormStatus(status: boolean): void {
    this.FormStatus = status;
  }

  public swiperConfigMobile: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    observer: true,
    observeParents: true,
  };
}
