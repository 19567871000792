import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject, Subscription, distinctUntilChanged } from "rxjs";
import sortByOrdem from "src/app/helpers/sort/sortByOrdem";
import { IBlockCard } from "src/models/zeus";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-block-card",
  templateUrl: "./block-card.component.html",
  styleUrls: ["./block-card.component.scss"],
})
export class BlockCardComponent implements AfterContentInit {
  @Input() block!: IBlockCard;
  @ViewChild("container", { static: true })
  container?: ElementRef<HTMLDivElement>;
  public swiperConfigMobile: SwiperOptions = {
    autoHeight: true,
  };
  public marginFundo = new BehaviorSubject<number>(303.75);
  public heightFundo: number = 0;
  public subscription!: Subscription;

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  public ngAfterContentInit(): void {
    this.getAllCardsSortedByOrdem();
    this.swiperConfigMobile.breakpoints = {
      0: {
        slidesPerView: Math.min(1, this.block.perViewDesktop),
      },
      600: {
        slidesPerView: Math.min(2, this.block.perViewDesktop),
      },
      1000: {
        slidesPerView: this.block.perViewDesktop ?? 3,
      },
    };
    this.subscription = this.marginFundo
      .pipe(distinctUntilChanged())
      .subscribe((tamanho) => {
        this.calcularHeight(tamanho);
      });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public calcularHeight(margin: number) {
    this.heightFundo =
      this.elementRef.nativeElement.parentElement!.offsetHeight -
      margin -
      (this.container?.nativeElement.offsetTop ?? 0);
  }

  public getAllCardsSortedByOrdem() {
    if (this.block.ordenacao == "decrescente") {
      this.block.cards = this.block.cards.sort(sortByOrdem).reverse();
    } else if (this.block.ordenacao == "crescente") {
      this.block.cards = this.block.cards.sort(sortByOrdem);
    }
  }
}
