import { Component, Input } from "@angular/core";
import { IBlockBudgetForm } from "./../../../../models/zeus/block/block-budget-form";

@Component({
  selector: "app-block-budget-form",
  templateUrl: "./block-budget-form.component.html",
  styleUrls: ["./block-budget-form.component.scss"],
})
export class BlockBudgetFormComponent {
  @Input() block!: IBlockBudgetForm;
}
