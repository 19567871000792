import { Component, Input } from '@angular/core';
import { IBlockCardStore } from 'src/models/zeus';

@Component({
  selector: 'app-block-card-store',
  templateUrl: './block-card-store.component.html',
  styleUrls: ['./block-card-store.component.scss']
})
export class BlockCardStoreComponent {

  @Input() block!: IBlockCardStore;

}
