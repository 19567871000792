import { Component, Input, OnInit } from '@angular/core';
import sortByOrder from 'src/app/helpers/sort/sortByOrder';
import { IBlockFAQ } from 'src/models/zeus';

@Component({
  selector: 'app-block-faq',
  templateUrl: './block-faq.component.html',
  styleUrls: ['./block-faq.component.scss']
})
export class BlockFaqComponent implements OnInit {

  @Input() block!: IBlockFAQ;

  public ngOnInit(): void {
    this.block.faqs.sort();
    this.sortAllFaqsByOrder();
  }

  public sortAllFaqsByOrder(){
    if(this.block.ordenacao == "decrescente"){
      this.block.faqs = this.block.faqs.sort().reverse();
    } if(this.block.ordenacao == "crescente"){
      this.block.faqs = this.block.faqs.sort();
    }else {
      this.block.faqs = this.block.faqs.sort();
    }
  }

}
