import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { IBlockCarouselForm } from 'src/models/zeus/block/block-carousel-form';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-block-carousel-form',
  templateUrl: './block-carousel-form.component.html',
  styleUrls: ['./block-carousel-form.component.scss'],
})
export class BlockCarouselFormComponent implements OnInit, OnDestroy {
  public FormStatus = true;
  public formularioFixo = true;
  public isMobile!: boolean;
  public isTablet = false;
  public subscription!: Subscription;

  constructor(
    private globalStateService: GlobalStateService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMobile = this.globalStateService.isMobile;
  }

  @Input() block!: IBlockCarouselForm;
  @Input() primeiroBloco = false;

  public ngOnInit(): void {
    this.subscription = this.globalStateService.isMobileChanges.subscribe(
      (actualState) => {
        this.isMobile = actualState;
      }
    );

    this.globalStateService.changeToMobile(this.isMobile);

    if (
      this.document.body.clientWidth >= 768 &&
      this.document.body.clientWidth <= 991
    ) {
      this.isTablet = true;
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public swiperConfigMobile: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    observer: true,
    observeParents: true,
  };

  public onClickAction() {
    if (this.isMobile || this.isTablet) this.mobileAction();
    else this.desktopAction();
  }

  public mobileAction() {
    this.router.navigate([], { fragment: 'formulario-de-contato' });
  }

  public desktopAction() {
    this.router.navigate([], { fragment: this.block.clickAction?.href });
  }
}
