<form class="budget-locacao" [class.feedback]="sendStatus" [formGroup]="form" (submit)="submit()" #f>
  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">
      {{ "FORM.CAMPO.NOME.LABEL" | translate }}</label>
    <input formControlName="nome" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text">
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>
  <div class="flex flex-wrap mb32" style="gap: 24px;">
    <div class="flex flex-column" style="flex: 1;">
      <label class="cor-white s2 fw600 mb8">{{ "FORM.CAMPO.RAZAOSOCIAL.LABEL" | translate }}</label>
      <input formControlName="razaosocial" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text">
      <app-errormessage [form]="form" field="razaosocial"></app-errormessage>
    </div>
    <div class="flex flex-column" style="flex: 1;">
      <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.CNPJ.LABEL' | translate }}</label>
      <input formControlName="cnpj" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text"
        (keyup)="this.onCnpjTextBox($event)" maxlength="18">
      <app-errormessage [form]="form" field="cnpj"></app-errormessage>
    </div>
  </div>
  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
    <input formControlName="telefone" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="tel"
      (keyup)="this.onTextBox($event)" maxlength="15">
    <app-errormessage [form]="form" field="razaosocial"></app-errormessage>
  </div>
  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
    <input formControlName="email" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="email">
    <app-errormessage [form]="form" field="email"></app-errormessage>
  </div>

  <div class="flex flex-column mb16">
    <label class="cor-white s2 fw600 mb16">{{ 'FORM.LOCACAO.INTERESSES.LABEL' | translate }}:</label>
    <div class="flex flex-wrap" style="gap: 32px;" #checkboxList>
      <ng-container *ngFor="let data of Interests; let i=index">
        <label class="flex s2 cor-black flex-items-center">
          <input type="checkbox"
            (change)="this.onInteresseCheckBox($event);checkboxList.classList.add('checkboxList-touched')"
            [value]="data.value">{{ ("FORM.LOCACAO.INTERESSES."+data.name) | translate }}
        </label>
      </ng-container>
      <p class="cheboxListErrorMessage" *ngIf="this.form.controls['interesses'].errors?.['required']">
        Este é um campo obrigatório, selecionar ao menos uma opção.
      </p>
    </div>
  </div>

  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
    <textarea class="bg-transparent cor-white s2 pt16 pb16 pl16 pr16" name="" id=""
      formControlName="mensagem"></textarea>
  </div>

  <div class="flex flex-justify-end botao">
    <button [disabled]="!form.valid" type="submit"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">
      {{ 'FORM.BOTAO.ENVIAR' | translate }}
    </button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>
</form>