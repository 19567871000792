import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  public isMobile = false;
  public isMobileChanges = new Subject<boolean>();

  public changeToMobile(state: boolean):void {
    this.isMobileChanges.next(state);
    this.isMobile = state;
  }
} 