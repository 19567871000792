import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageSize } from 'src/models/ImageSize';

@Directive({
  selector: 'img[appStrapiimage]'
})
export class StrapiimageDirective implements AfterViewInit, OnInit {
  @Input() appStrapiimage?: ArquivoData;
  @Input() disableLazy = false;
  @Input() quality? = "90"
  @Input() widthImg?: string;
  @Input() preloadHead = false;

  @Input() incluirDataNoAttr = false;
  private query = ""
  constructor(
    private elementRef: ElementRef<HTMLImageElement>,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public ngOnInit(): void {
    this.query = `?format=webp&quality=${this.quality}`
  }

  public ngAfterViewInit(): void {
    if (this.appStrapiimage) {
      
      const tagImg = this.elementRef.nativeElement
      tagImg.style.filter = "blur(1px)";
      if (!this.disableLazy) tagImg.loading = "lazy";
      if (this.appStrapiimage.attributes.size > 100 && this.appStrapiimage?.attributes.placeholder) {
        tagImg.style.background = "url(" + this.appStrapiimage?.attributes.placeholder + ") no-repeat";
        tagImg.style.backgroundSize = "cover";
        tagImg.style.backgroundPosition = "center center";
      }
      tagImg.onload = () => {
        tagImg.style.backgroundImage = '';
        tagImg.style.filter = "";
      }

      if (this.appStrapiimage?.attributes?.formats) {
       
        const formatsList = Object.keys(this.appStrapiimage.attributes.formats)
          .map(prop => this.appStrapiimage?.attributes?.formats[prop])
          .filter((a): a is ArquivoFormat => a != undefined)
          .map<ImageSize>(format => ({ url: format.ext == '.svg' ? format.url : (`${format.url}${this.query}&w=${this.widthImg}`), tamanho: format.width }));
        
        formatsList.push({
          url: this.appStrapiimage.attributes.url + (this.appStrapiimage.attributes.ext == '.svg' ? '' : `${this.query}&w=${this.widthImg}`),
          tamanho: this.appStrapiimage.attributes.width
        });

        formatsList.sort((x, y) => x.tamanho - y.tamanho);
        tagImg.setAttribute((this.incluirDataNoAttr ? 'data-' : '') + 'srcset', this.getSrcSet(formatsList));

      } else {
        if (this.incluirDataNoAttr) {
          tagImg.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        }
        tagImg.setAttribute((this.incluirDataNoAttr ? 'data-' : '') + 'src', environment.API_URL + this.appStrapiimage.attributes.url + (this.appStrapiimage.attributes.ext == '.svg' ? '' : this.query));
      }

      tagImg.alt = this.appStrapiimage.attributes.alternativeText;
    }

    if (this.preloadHead) {
 
      this.headPreload(this.elementRef.nativeElement.srcset, this.elementRef.nativeElement.src);
    }

  }
  
  public getMenorSrc(imagemTamanho: ImageSize[]) {
    return environment.API_URL + imagemTamanho[0].url;
  }

  public getSrcSet(imagemTamanho: ImageSize[]) {

    if (imagemTamanho?.length) {
     
      return imagemTamanho
        .map(item => {
          return `${environment.API_URL + item.url} ${item.tamanho}w`
        }).filter(a => a).join(', ')
    } else return '';
  }

  public getSrcSetSizes(imagemTamanho: ImageSize[]) {
  
    if (this.widthImg && Number(this.widthImg?.replace(/px/gi, '')) > 0) {
      return this.widthImg;
    }
    if (imagemTamanho?.length) {
      return imagemTamanho.sort((x, y) => y.tamanho - x.tamanho).map(item => `(min-width: ${item.tamanho}px) ${item.tamanho}px`).join(', ') + ', 1px'
    } else return '';
  }


  public headPreload(srcSet: string, href: string) {
    const elem = this.document.createElement('link');
    elem.rel = 'preload';
    elem.as = 'image';

    if (srcSet) elem.setAttribute('imagesrcset', srcSet);
    if (href) elem.href = href;

    this.document.head.appendChild(elem);
  }
}
