<div>
  <a (click)="toggleFaq()"
    class="flex flex-justify-between flex-items-center cor-black s4 hv-cor-primary w-100 pt24 pb24 line question">
    <div class="flex flex-column flex-justify-center" [appEditorjsparsehtml]="this.faqCard.question"></div>
    <img [ngClass]="{'fechado': arrowToggle, 'aberto': !arrowToggle}" class="seta bg-black ml8" height="22px"
      width="22px" alt="">
  </a>
  <div class="cor-black s2 flex flex-column flex-justify-center answer" style="overflow: hidden;"
    [@ngIfAnimation]='state'>
    <div class=" mb16" [appEditorjsparsehtml]="this.faqCard.answer">
    </div>
    <a [href]="environment.API_URL + arquivo.attributes.url" [download]="arquivo.attributes.name" target="_blank"
      class="pt8 pb8 cor-black hv-cor-primary" *ngFor="let arquivo of this.faqCard.files?.data">
      <div class="flex flex-items-center">
        <div class="tag mr8" [attr.format]="arquivo.attributes.ext">{{ arquivo.attributes.ext}}</div>
        <p class="udl">
          <b>baixar arquivo:</b> {{ arquivo.attributes.name}} <br>
        </p>
      </div>
    </a>
  </div>
</div>