import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import phoneNumberMask from 'src/app/helpers/mask/phoneNumberMask';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { LeadService } from 'src/app/services/lead.service';
import { environment } from 'src/environments/environment';
import { Status } from 'src/models/Status';
import { IBlockContactForm } from 'src/models/zeus';

@Component({
  selector: 'app-form-contact-us-seminovos',
  templateUrl: './form-contact-us-seminovos.component.html',
  styleUrls: ['./form-contact-us-seminovos.component.scss']
})
export class FormContactUsSeminovosComponent implements OnInit, OnDestroy{
  @Input() block!: IBlockContactForm;
  @Input() identifier = this.block?.identifier ?? this.location.path(false).split('?')[0];

  public sendStatus?: Status;
  public subscription!: Subscription;

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.compose([Validators.required])),
    email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
    telefone: new UntypedFormControl('', Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(14)])),
    estado: new UntypedFormControl('', Validators.compose([Validators.required])),
    motivocontato: new UntypedFormControl('', Validators.compose([Validators.required])),
    confirmarenvio: new UntypedFormControl(false, Validators.compose([Validators.required])),
    mensagem: new UntypedFormControl(''),
  });

  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService
  ) { }

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onTextBox($event: Event){
    let textBox = ($event.target as HTMLInputElement);
    textBox.value = phoneNumberMask(textBox.value);
  }

  public submit() {
    const f = this.form.value;

    const pathname  = window.location.pathname
    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash      = window.location.hash
      this.identifier = `${newUrl}${hash}`
    }

    let dados = {
      'name': f.nome,
      'email': f.email,
      'mobile_phone': f.telefone,
      'cnpj': f.cnpj,
      'mensagem': f.mensagem,
      'estado': f.estado,
      'motivocontato': f.motivocontato,
      'identificador': this.identifier,
      'token_rdstation': environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.sendStatus = 'carregando';
      this.subscription = this.leadService.enviarComReponderPara(dados, this.block.sendToEmails?.split('\n'), this.block.sendToRdStation, this.block.replyTo).subscribe(
        (sucesso) => {
          this.sendStatus = sucesso ? 'enviado' : 'erro'

          if (this.sendStatus == 'enviado') {
            this.dataLayerService.formSucesso(this.identifier)
          }
        })
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();

  }
}