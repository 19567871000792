import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import phoneNumberMask from 'src/app/helpers/mask/phoneNumberMask';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { LeadService } from 'src/app/services/lead.service';
import { environment } from 'src/environments/environment';
import { Status } from 'src/models/Status';
import { IBlockBudgetForm } from 'src/models/zeus';

@Component({
  selector: 'app-form-make-budget-pecas-eservicos',
  templateUrl: './form-make-budget-pecas-eservicos.component.html',
  styleUrls: ['./form-make-budget-pecas-eservicos.component.scss']
})
export class FormMakeBudgetPecasEServicosComponent implements OnInit, OnDestroy {
  
  @Input() block!: IBlockBudgetForm;
  @Input() identifier = this.block?.identifier ?? this.location.path(false).split('?')[0];

  public subscription!: Subscription;
  public sendStatus?: Status;

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.compose([Validators.required])),
    telefone: new UntypedFormControl('', Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(14)])),
    email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
    mensagem: new UntypedFormControl(''),
  });

  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService) { }

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onTextBox($event: Event): void{
    let textBox = ($event.target as HTMLInputElement);
    textBox.value = phoneNumberMask(textBox.value);
  }

  public submit():void {
    const f = this.form.value;

    const pathname  = window.location.pathname
    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash      = window.location.hash
      this.identifier = `faca-seu-orcamento`
    }

    let dados = {
      'name': f.nome,
      'mobile_phone': f.telefone,
      'email': f.email,
      'mensagem': f.mensagem,
      'identificador': this.identifier,
      'token_rdstation': environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.sendStatus = 'carregando';
      this.subscription = this.leadService.enviarComReponderPara(dados, this.block.sendToEmails?.split('\n'), this.block.sendToRdStation, this.block.replyTo).subscribe(
        (sucesso) => {
          this.sendStatus = sucesso ? 'enviado' : 'erro'

          if (this.sendStatus == 'enviado') {
            this.dataLayerService.formSucesso(this.identifier)
          }
        })
    }
  }
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();

  }
}
