import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, map, Observable, Subscription, tap } from "rxjs";
import { DataLayerService } from "src/app/services/data-layer.service";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/seo.service";
import { Basic } from "src/models/strapi_retorno";
import { IPage } from "src/models/zeus";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit, OnDestroy {
  public pageSubject = new BehaviorSubject<(IPage & Basic) | null>(null);
  public page$ = this.pageSubject.asObservable();
  public sections: any[] = [];
  public modals: any[] = [];
  public subscription!: Subscription;
  public path = "/home";

  constructor(
    private pageService: PageService,
    private router: Router,
    private seoService: SeoService,
    public dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    const homeCampanha = this.router.url.split("utm")[0];

    if (homeCampanha === "/?") {
      this.path = "/home";
    } else {
      this.path = this.router.url === "/" ? "/home" : this.router.url;
    }
    this.subscription = this.loadPage(this.path)
      .pipe(
        tap((page) => {
          this.pageSubject.next(page);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public loadPage(path: string): Observable<IPage & Basic> {
    return this.pageService.byPath(path).pipe(
      map((retorno) => retorno.data[0]?.attributes),
      // EXTRAI AS SECTIONS DA PAGE E COLOCA NA PROPRIEDADE PAGE
      tap((page) => {
        if (!page) {
          //this.router.navigateByUrl('404');
          return;
        }
        this.sections = [];
        Object.keys(page)
          .filter((prop) => prop.startsWith("section"))
          .sort()
          .forEach((prop) => {
            const section = (page as any)[prop];
            this.sections.push(section);
          });
        this.modals = page.modal;
        this.pageService.temUmCarrosselNoPrimeiroBlocoDaPrimeiraSection = !!(
          this.sections?.[0]?.[0]?.["__component"] == "block.block-carousel"
        );
        this.seoService.update(page.seo);
      })
    );
  }
}
