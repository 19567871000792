import { Component, Input, OnInit } from '@angular/core';
import { IImage } from 'src/models/zeus/item/image';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() public image!: IImage;
  public link: string | undefined = "";

  ngOnInit(): void {
    if(this.image.link != undefined ) this.link = this.image.link?.href
  }
}
