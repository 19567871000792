import { Component, Input } from '@angular/core';
import { IBlockContactUs } from 'src/models/zeus';

@Component({
  selector: 'app-block-contact-us',
  templateUrl: './block-contact-us.component.html',
  styleUrls: ['./block-contact-us.component.scss']
})
export class BlockContactUsComponent {

  @Input() block!: IBlockContactUs;

}
