<div
  class="container flex flex-justify-between flex-wrap flex-items-start pt120 pb120"
  [class.pecas]="this.block.formType == 'FormMakeBudgetPecasEServicos'"
  [id]="this.block.blockIdentifier"
>
  <app-content
    class="mr16"
    [content]="content"
    *ngIf="this.block.content as content"
  ></app-content>
  <ng-container [ngSwitch]="this.block.formType">
    <app-form-make-budget-pecas-eservicos
      *ngSwitchCase="'FormMakeBudgetPecasEServicos'"
      [block]="block"
    ></app-form-make-budget-pecas-eservicos>
    <app-form-make-budget-locacao
      [identifierForm]="block.identifier"
      *ngSwitchCase="'FormMakeBudgetLocacao'"
      [block]="block"
    ></app-form-make-budget-locacao>
    <app-form-make-budget-concessionaria
      *ngSwitchCase="'FormMakeBudgetConcessionaria'"
      [block]="block"
    ></app-form-make-budget-concessionaria>
  </ng-container>
</div>
