import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, take } from "rxjs";
import { environment } from "src/environments/environment";
import { FiltrosPDV } from "src/models/FiltrosPDV";
import { Pagination } from "src/models/PDVData";
import { SempreNovoPDVParams } from "src/models/PDVParams";
import { ProdutoPdv } from "src/models/ProdutoPdv";
import { SempreNovoModelo } from "src/models/SempreNovoModelo";

interface BuscarModelosParams extends SempreNovoPDVParams {}

export interface VeiculosPorModeloResponse extends SempreNovoModelo {
  veiculos: {
    pagination: Pagination;
    results: ProdutoPdv[];
  };
}

export interface BuscarModelosResponse {
  lista: {
    results: SempreNovoModelo[];
    pagination: Pagination;
  };
}

@Injectable({
  providedIn: "root",
})
export class SempreNovoService {
  private _filtrosVeiculosSubject = new BehaviorSubject<FiltrosPDV>({
    marcas: [],
    filiais: [],
    eixos: [],
    placas: [],
    cores: [],
    implementos: [],
    estados: [],
    cidades: [],
    ultimaAtualizacao: new Date(),
  });

  public readonly filtroVeiculos$: Observable<FiltrosPDV> =
    this._filtrosVeiculosSubject.asObservable();

  constructor(private _http: HttpClient) {}

  public listarModelos(marca?: string | null) {
    return this._http.get<SempreNovoModelo[]>(
      environment.API_URL +
        `/api/sempre-novo/home${marca ? `?marca=${marca}` : ""}`
    );
  }

  public buscarModelos(parametros: BuscarModelosParams) {
    let params = new HttpParams().set(
      "categoria",
      parametros.categoria.toLowerCase()
    );
    params = params.append("modelo", JSON.stringify(parametros.modelo));
    params = params.append("order", parametros.ordem);
    params = params.append("marca", parametros.marcas);
    params = params.append("eixo", parametros.eixo);
    params = params.append("filial", parametros.filial);
    params = params.append("placa", parametros.placa);
    params = params.append("cor", parametros.cor);
    params = params.append("anoInicio", parametros.anoInicio);
    params = params.append("anoFim", parametros.anoFim);
    params = params.append("kmInicio", parametros.kmInicio);
    params = params.append("kmFim", parametros.kmFim);
    params = params.append("precoInicio", parametros.precoInicio);
    params = params.append("precoFim", parametros.precoFim);
    params = params.append("pagina", parametros.pagina);
    params = params.append("cidade", parametros.cidade);
    params = params.append("estado", parametros.estado);

    return this._http.get<BuscarModelosResponse>(
      environment.API_URL + `/api/sempre-novo/busca`,
      { params: params }
    );
  }

  public listarFiltros(): void {
    this._http
      .get<FiltrosPDV>(environment.API_URL + "/api/sempre-novo/filtros")
      .pipe(take(1))
      .subscribe((resultado) => {
        this._filtrosVeiculosSubject.next(resultado);
      });
  }

  public listarVeiuclosPorModelo(modelo: string, pagina = 1) {
    const params = new HttpParams().set("pagina", pagina.toString());

    return this._http.get<VeiculosPorModeloResponse>(
      environment.API_URL +
        `/api/sempre-novo/detalhe/${encodeURIComponent(modelo)}`,
      { params }
    );
  }
}
