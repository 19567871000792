<!-- {{ (this.url$ | async) | json }} -->
<div class="compartilhe" *ngIf="(this.url$ | async) as opt">
  <p class="cor-black s2 mb16">Compartilhe</p>
  <div class="flex">
    <a rel="noopener" [href]="'https://wa.me/?text=' + opt.url"
      class="flex flex-justify-center flex-items-center bg-primary hv-bg-secondary mr8"
      routerLinkActive="router-link-active" style="width: 36px; height: 36px;" title="compartilhar">
      <img height="20px" width="20px" src="../../../../assets/images/icon-whatsapp-white.svg" alt="">
    </a>
    <a rel="noopener" [href]="'https://www.facebook.com/share.php?u=' + opt.url"
      class="flex flex-justify-center flex-items-center bg-primary hv-bg-secondary mr8"
      style="width: 36px; height: 36px;" title="compartilhar">
      <img height="20px" width="20px" src="../../../../assets/images/icon-facebook-white.svg" alt="">
    </a>
    <a rel="noopener" [href]="'mailto:?subject=' + opt.title + '&amp;body=' + opt.url"
      class="flex flex-justify-center flex-items-center bg-primary hv-bg-secondary mr8"
      routerLinkActive="router-link-active" style="width: 36px; height: 36px;" title="compartilhar">
      <img height="20px" width="20px" src="../../../../assets/images/icon-message-white.svg" alt="">
    </a>
    <a rel="noopener" [href]="'http://twitter.com/share?text='+opt.title+'&url='+opt.url+'&hashtags=GrupoVamos'"
      class="flex flex-justify-center flex-items-center bg-primary hv-bg-secondary mr8"
      routerLinkActive="router-link-active" style="width: 36px; height: 36px;" title="compartilhar">
      <img height="20px" width="20px" src="../../../../assets/images/icon-twitter.svg" alt="">
    </a>
    <!-- <a [routerLink]="this.path" class="flex flex-justify-center flex-items-center bg-primary hv-bg-secondary mr8"
      routerLinkActive="router-link-active" style="width: 36px; height: 36px;" title="compartilhar">
      <img height="20px" width="20px" src="../../../../assets/images/icon-plus-white.svg" alt="">
    </a> -->
  </div>
</div>
