import { Component, Input, OnInit } from '@angular/core';
import { IBlockAccordion } from 'src/models/zeus/block/block-accordion';

@Component({
  selector: 'app-block-accordion',
  templateUrl: './block-accordion.component.html',
  styleUrls: ['./block-accordion.component.scss']
})
export class BlockAccordionComponent implements OnInit {

  @Input() block!: IBlockAccordion;

  public ngOnInit(): void {}

  public sortAllFaqsByOrder(){
    /* if(this.block.ordenacao == "decrescente"){
      this.block.faqs = this.block.faqs.sort().reverse();
    } if(this.block.ordenacao == "crescente"){
      this.block.faqs = this.block.faqs.sort();
    }else {
      this.block.faqs = this.block.faqs.sort();
    } */
  }


}
