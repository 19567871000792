<div class="container pt64 pb64 flex flex-column w-100" [id]="this.block.blockIdentifier">
  <div class="wrapperSelect recente mb64">
    <!-- <select class="w-100 cor-black fw400" name="ordem" id="shoptype" (change)="onChange($event)">
        <option value="loja">Selecione uma loja</option>
        <option value="komatsu">Komatsu</option>
        <option value="fendt">Vamos Fendt</option>
        <option value="seminovos">Vamos Seminovos</option>
        <option value="valtra">Vamos Valtra</option>
        <option value="transrio">Transrio</option>
      </select> -->
    <a href="/nossas-lojas"
      style="display:inline-block; padding:8px 16px; color:var(--white); border-radius:24px;font-weight:bold;--cor1:var(--white); --cor2:var(--primary); --hvcor1:var(--white); --hvcor2:var(--secondary); background-color: var(--cor2);">Voltar
      para Nossas Lojas</a>
  </div>

  <div class="w-100 grid">
    <app-shop-card class="h-100" *ngFor="let item of todasLojas" [shop]="item.attributes"
      [hideButton]="this.block.hideLinkProductsList"></app-shop-card>
  </div>
</div>