import { Component, Input } from '@angular/core';
import { IBlockYoutubeVideo } from 'src/models/zeus';

@Component({
  selector: 'app-block-youtube-video',
  templateUrl: './block-youtube-video.component.html',
  styleUrls: ['./block-youtube-video.component.scss']
})
export class BlockYoutubeVideoComponent {

  @Input() block!: IBlockYoutubeVideo;


}
