import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotaoWhatsappComponent } from './botao-whatsapp.component';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';
import { StrapiiconModule } from 'src/app/directives/strapiicon/strapiicon.module';

@NgModule({
  declarations: [
    BotaoWhatsappComponent
  ],
  exports: [
    BotaoWhatsappComponent
  ],
  imports: [
    StrapilinkModule,
    StrapiiconModule,
    CommonModule
  ]
})
export class BotaoWhatsappModule { }
