import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ISNBlockLegal } from 'src/models/zeus/sempre-novo/sn-block-legal';

@Component({
  selector: 'app-sn-block-legal',
  templateUrl: './sn-block-legal.component.html',
  styleUrls: ['./sn-block-legal.component.scss'],
})
export class SNBlockLegal implements OnInit {
  @Input() block!: ISNBlockLegal;

  public stringfiedText: string = ""
  
  ngOnInit(): void {
    this.stringfiedText = JSON.stringify({
      blocks: this.block.text
    });
  }
}
