import { DOCUMENT, KeyValuePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Route, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ISeo } from 'src/models/zeus';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router
  ) { }

  update(seo?: ISeo) {
    if (seo) {

      const canonicalPath = `${this.document.location.protocol}//${this.document.location.hostname}/${seo.canonicalURL?.replace(/^\//gi,'')}`;

      const title = seo.metaTitle ?? 'Grupo Vamos';
      const description = seo.metaDescription ?? 'Grupo Vamos';
      const robots = seo.metaRobots ?? '';
      const keywords = seo.keywords ?? '';
      const url = this.document.location.href;
      const image = seo.metaImage?.data?.attributes?.url ?? '';
      const canonical = (seo.canonicalURL && canonicalPath ) ?? this.document.location.href;
      this.updateCanonicalURL(canonical);

      this.titleService.setTitle(seo.metaTitle);
      this.metaTagService.updateTag({ name: 'title', content: title })
      this.metaTagService.updateTag({ name: 'description', content: description })
      this.metaTagService.updateTag({ name: 'robots', content: robots ?? '' })
      this.metaTagService.updateTag({ name: 'keywords', content: keywords ?? '' })
      this.metaTagService.updateTag({ charset: 'UTF-8' })

      const facebook = seo?.metaSocial?.find(x => x.socialNetwork == 'Facebook');
      this.metaTagService.updateTag({ property: "og:type", content: 'website' })
      this.metaTagService.updateTag({ property: "og:url", content: url })
      this.metaTagService.updateTag({ property: "og:title", content: (facebook?.title) ?? title })
      this.metaTagService.updateTag({ property: "og:description", content: (facebook?.description) ?? description })
      this.metaTagService.updateTag({ property: "og:image", content: environment.API_URL + ((facebook?.image?.data?.attributes?.url) ?? image) + '?format=webp&resize=1200x630&embed' })

      const twitter = seo?.metaSocial?.find(x => x.socialNetwork == 'Twitter');
      this.metaTagService.updateTag({ property: "twitter:card", content: 'summary_large_image' })
      this.metaTagService.updateTag({ property: "twitter:url", content: url })
      this.metaTagService.updateTag({ property: "twitter:title", content: (twitter?.title) ?? title })
      this.metaTagService.updateTag({ property: "twitter:description", content: (twitter?.description) ?? description })
      this.metaTagService.updateTag({ property: "twitter:image", content: environment.API_URL + ((twitter?.image?.data?.attributes?.url) ?? image) + '?format=webp&resize=1200x630&embed' })
    
    }

   
    
  }

 updateCanonicalURL(value:string) {
  const link = (this.document.querySelector('head link[rel="canonical"]') ?? this.document.head.appendChild(this.document.createElement('link')))  as HTMLLinkElement;
  link.rel= 'canonical';
  link.href= value;
}

}
