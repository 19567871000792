import { Component, Input } from '@angular/core';
import { IBlockMedal } from 'src/models/zeus';

@Component({
  selector: 'app-block-medal',
  templateUrl: './block-medal.component.html',
  styleUrls: ['./block-medal.component.scss']
})
export class BlockMedalComponent {

  @Input() block!: IBlockMedal;

}
