import { Component, Input } from '@angular/core';
import { IGlossary } from 'src/models/zeus';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent {

  @Input() public glossary!: IGlossary;

}
