import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockContactFormComponent } from './block-contact-form.component';
import { FormsModule } from '../../common/forms/forms.module';
import { ContentModule } from '../../items/content/content.module';
import { TranslateModule } from '@ngx-translate/core';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';



@NgModule({
  declarations: [BlockContactFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ContentModule,
    TranslateModule,
    StrapilinkModule
  ],
  exports: [
    BlockContactFormComponent
  ]
})
export class BlockContactFormModule { }
