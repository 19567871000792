<div class="container flex flex-wrap flex-justify-between pt80 pb32" [id]="this.block.blockIdentifier">
  <div class="flex flex-items-center newsletter mr16 mb32">
    <img class="mr48" height="122px" width="162px" [appStrapiimage]="this.block.image?.data">
    <div [appEditorjsparsehtml]="this.block.content"></div>
  </div>

  <form [formGroup]="form" [class.feedback]="envioStatus" (submit)="onSubmit()" #f>
    <div class="box flex" style="gap: 24px;">
      <div class="flex flex-column">
        <label class="cor-white s2 fw600 mb8">Nome</label>
        <input formControlName="nome" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text">
      </div>
      <div class="flex flex-column">
        <label class="cor-white s2 fw600 mb8">Email</label>
        <input formControlName="email" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="email">
      </div>
    </div>

    <div class="flex flex-wrap mt24" style="gap: 8px 32px;">
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interessecaminhao" type="checkbox" name="caminhao" [value]="true">
          Caminhão</label>
      </div>
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interessecavalomecanico" type="checkbox" name="cavalo-mecanico" [value]="true">
          Cavalo Mecânico</label>
      </div>
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interesseimplementos" type="checkbox" name="implementos" [value]="true">
          Implementos</label>
      </div>
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interesseonibus" type="checkbox" name="onibus" [value]="true">
          Ônibus</label>
      </div>
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interesselinhaverde" type="checkbox" name="linha-verde" [value]="true">
          Linha Verde</label>
      </div>
      <div class="flex-box mb16">
        <label class="flex s2 cor-white flex-items-center">
          <input formControlName="interesselinhaamarela" type="checkbox" name="linha-amarela" [value]="true">
          Linha Amarela</label>
      </div>
    </div>

    <button class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 mt8 brfull"
      type="submit">Enviar</button>

    <app-submitfeedbackmessage *ngIf="envioStatus" [form]="f" [(status)]="envioStatus"></app-submitfeedbackmessage>
  </form>
</div>