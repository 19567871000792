<ng-container *ngIf="modalService.openClose[id]">
  <div
    class="main container flex flex-justify-center flex-items-center pt64 pb64 w-100"
  >
    <div class="modal bg-white">
      <div
        class="modal-head flex w-100 pa32 w-100"
        style="padding-bottom: 16px"
      >
        <ng-content select="[head]"></ng-content>
        <button
          style="
            font-size: 40px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            appearance: none;
            border: 0px;
            cursor: pointer;
          "
          class="close hv-bg-silver bg-white flex flex-items-center flex-justify-center cor-gray hv-cor-black mlauto"
        >
          <span style="padding-bottom: 4px" (click)="fechar()">&times;</span>
        </button>
      </div>
      <div
        class="modal-body flex flex-column w-100 pa32"
        style="padding-top: 16px"
      >
        <ng-content select="[body]"></ng-content>
      </div>
    </div>
  </div>
</ng-container>
