import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BlockProductDescriptionComponent } from "./components/blocks/block-product-description/block-product-description.component";
import { BlogPostComponent } from "./pages/blog/blog-post/blog-post.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { PageComponent } from "./pages/page/page.component";
import { SeminovosBuscaComponent } from "./pages/seminovos-busca/seminovos-busca.component";
import { ConformidadeComponent } from "./pages/nossa-empresa/conformidade/conformidade.component";
import { SempreNovoBuscaComponent } from "./pages/sempre-novo-busca/sempre-novo-busca.component";
import { ModeloDetalheComponent } from "./pages/modelo-detalhe/modelo-detalhe.component";
import { DestaqueBuscaComponent } from "./pages/destaque-busca/destaque-busca.component";

const routes: Routes = [
  { path: "home", redirectTo: "", pathMatch: "full" },
  { path: "blog", component: PageComponent },
  { path: "blog", children: [{ path: ":slug", component: BlogPostComponent }] },
  { path: "", component: PageComponent, data: { pagePath: "/home" } },
  { path: "sustentabilidade", component: PageComponent },
  { path: "nossa-empresa", component: PageComponent },
  { path: "conformidade-vamos", component: ConformidadeComponent },
  { path: "nossas-lojas/vamos-maquinas", component: PageComponent },
  { path: "nossas-lojas/transrio", component: PageComponent },
  { path: "nossas-lojas/vamos-valtra", component: PageComponent },
  { path: "nossas-lojas/vamos-fendt", component: PageComponent },
  { path: "nossas-lojas/seminovos", component: PageComponent },
  { path: "nossas-lojas", component: PageComponent },
  { path: "nossas-politicas", component: PageComponent },
  { path: "imprensa", component: PageComponent },
  { path: "nossa-gente", component: PageComponent },
  { path: "central-de-atendimento", component: PageComponent },
  { path: "politica-de-cookies", component: PageComponent },
  {
    path: "concessionarias/maquinas/komatsu/escavadeiras-hidraulicas/construcao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/carregadeiras-de-rodas/construcao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/carregadeiras-de-rodas/mineracao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/tratores-de-esteiras/construcao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/caminhoes-mecanicos/construcao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/caminhoes-mecanicos/mineracao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/caminhoes-eletricos/mineracao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/motoniveladoras/construcao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/tratores-de-rodas/mineracao",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/rompedores-hidraulicos/compacto",
    component: PageComponent,
  },
  {
    path: "concessionariasmaquinas/komatsu/rompedores-hidraulicos/medio",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/rompedores-hidraulicos/pesado",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/rompedores-hidraulicos/variavel",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/rompedores-hidraulicos/xl",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/caminhoes-eletricos",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/caminhoes-mecanicos",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/carregadeiras-de-rodas",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/escavadeiras-hidraulicas",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/motoniveladoras",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/tratores-de-esteiras",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/tratores-de-rodas",
    component: PageComponent,
  },
  {
    path: "concessionarias/maquinas/komatsu/rompedores-hidraulicos",
    component: PageComponent,
  },
  {
    path: "concessionarias/manutencao-preventiva-e-corretiva",
    component: PageComponent,
  },
  { path: "concessionarias/maquinas/komatsu", component: PageComponent },
  { path: "concessionarias/maquinas/manitou", component: PageComponent },
  { path: "concessionarias/maquinas", component: PageComponent },
  { path: "concessionarias", component: PageComponent },
  { path: "seminovos/marca/:marca", component: SeminovosBuscaComponent },
  {
    path: "seminovos/destaque",
    component: DestaqueBuscaComponent,
  },
  {
    path: "seminovos/estoque-completo/:estoque",
    component: SeminovosBuscaComponent,
  },
  { path: "seminovos/estoque-completo", component: SeminovosBuscaComponent },
  { path: "seminovos/marca", component: SeminovosBuscaComponent },
  { path: "seminovos/abaixo-da-tabela", component: SeminovosBuscaComponent },
  { path: "seminovos/sempre-novo", component: SeminovosBuscaComponent },
  { path: "seminovos/utilitario", component: SeminovosBuscaComponent },
  { path: "seminovos/estoque", component: SeminovosBuscaComponent },
  { path: "seminovos/implementos", component: SeminovosBuscaComponent },
  { path: "seminovos/linha-verde", component: SeminovosBuscaComponent },
  { path: "seminovos/linha-amarela", component: SeminovosBuscaComponent },
  { path: "seminovos/onibus", component: SeminovosBuscaComponent },
  { path: "seminovos/onibus-rodoviario", component: SeminovosBuscaComponent },
  { path: "seminovos/onibus-urbano", component: SeminovosBuscaComponent },
  {
    path: "seminovos/maquinas-e-equipamentos-agricolas",
    component: SeminovosBuscaComponent,
  },
  { path: "seminovos/caminhoes", component: SeminovosBuscaComponent },

  { path: "seminovos/cavalo-mecanico", component: SeminovosBuscaComponent },
  { path: "seminovos/carreta", component: SeminovosBuscaComponent },
  { path: "seminovos/nossas-lojas", component: SeminovosBuscaComponent },
  {
    path: "seminovos/produto/:id",
    component: BlockProductDescriptionComponent,
  },
  { path: "seminovos", component: PageComponent },
  { path: "sempre-novo", component: PageComponent },
  // {
  //   path: "simulador",
  //   loadChildren: () =>
  //     import("./simulador/simulador.module").then((m) => m.SimuladorModule),
  // },
  { path: "locacao/produtos", component: PageComponent },
  { path: "locacao/caminhoes", component: PageComponent },
  { path: "locacao/linha-agro", component: PageComponent },
  { path: "locacao/linha-amarela", component: PageComponent },
  { path: "locacao/intralogistica", component: PageComponent },
  { path: "locacao/onibus", component: PageComponent },
  { path: "locacao/implementos-para-caminhao", component: PageComponent },
  { path: "locacao/nossos-planos/vamos-controle", component: PageComponent },
  { path: "locacao/nossos-planos", component: PageComponent },
  { path: "locacao/sempre-novo", component: PageComponent },
  { path: "locacao/sempre-novo/busca", component: SempreNovoBuscaComponent },
  { path: "locacao/sempre-novo/modelo", component: ModeloDetalheComponent },
  { path: "locacao", component: PageComponent },
  { path: "pecas-e-servicos", component: PageComponent },
  { path: "quero-ser-fornecedor", component: PageComponent },
  { path: "canal-de-denuncia", component: PageComponent },
  { path: "operacao-foguetes", component: PageComponent },
  { path: "arquivosdigital", component: PageComponent },
  { path: "404", component: NotFoundComponent },
  { path: "**", component: PageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
