export const environment = {
  production: true,

  // Zeus API
  API_TOKEN:
    "e01113afd3210c9422cfb043992e36fb6016be3742becb314378cfa53647daf209a5bfe27d25bb81e42e3d20a3c1e44fa376f37ec11ae6e0569cb08f3e3b095003b3a839e404d3b78938c304cd0806703c07d3d4ae4586814454d852c2ca6ba54c010829e0bf7d92fa741e450869983e6c276802b8f65e97c7b079e8e89d614a",
  API_URL: "https://gvmcmssiteinstitucionalprd.azurewebsites.net",
  API_PATH_PAGE: "/api/pages",
  API_PATH_HEADER: "/api/header",
  API_PATH_FOOTER: "/api/footer",
  API_PATH_CTAFLUTUANTE: "/api/cta-flutuantes",
  API_PATH_MENUS: "/api/menus",
  API_PATH_BLOG: "/api/blog-posts",
  API_PATH_BLOG_CATEGORIES: "/api/blog-categories",
  API_PATH_VEICULOPDV: "/api/veiculosdb",
  API_PATH_PLAN_BENEFITS_LOCACAO: "/api/plan-benefits-locacaos",
  RD_PUBLIC_TOKEN: "62f7ad88d386bd7dfea15d2019082abc",
  API_KEY_RD_STATION: "KvphVhXvTFRSsFXvJESZlFQuNxWaKCgDJjBV",
  API_PATH_LEAD: "/api/lead",
};
