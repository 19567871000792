<ng-container *ngIf="(post$ | async) as post">
  <div class="flex flex-column w-100 container flex-items-center pt64 pb64">
    <div class="block flex flex-column w-100">
      <img class="post-img w-100 mb32" [appStrapiimage]="post.mainImage.data">
      <h1 class="w-100 s10 upc cor-black mb32 title">{{ this.post.h1 }}</h1>
      <p class="w-100 s4 cor-black mb32 subtitle">{{ this.post.subTitle }}</p>
      <div class="flex flex-items-center mb48">
        <img [appStrapiicon] icon="ico-calendar.svg" class="bg-gray mr8" width="24px" height="24px">
        <time class="cor-gray">{{ post.publishedAt | date:"dd/MMMM/yyyy":'+0000':tradutorService.locale }}</time>
      </div>

      <div style="border-bottom: 1px solid var(--silver); border-top: 1px solid var(--silver);">
        <div class="post mauto">
          <app-social-share class="mt56" style="display: block;"></app-social-share>
          <div class="flex flex-column pt56 pb56" [appEditorjsparsehtml]="post.content"></div>
          <app-social-share class="mb56" style="display: block;"></app-social-share>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="container pt80 pb64" *ngIf="this.erro">
  <p class="s4 cor-black fw600">Não encontramos o post que você procurava.</p>
</div>
