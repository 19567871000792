<div class="flex flex-column" [id]="this.block.blockIdentifier">
  <app-content
    class="container pt64"
    [content]="content"
    *ngIf="this.block.content as content"
  ></app-content>
  <ng-container *ngFor="let imagetext of this.block.imagetexts">
    <app-image-text
      [imageText]="imagetext"
      [class]="this.block.espacamento"
    ></app-image-text>
  </ng-container>
</div>
