import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SempreNovoService } from "src/app/services/sempre-novo.service";
import { SempreNovoModelo } from "src/models/SempreNovoModelo";
import { ILink } from "src/models/zeus";

import { IBlockOfferSempreNovo } from 'src/models/zeus/block/block-offer-sempre-novo';

@Component({
  selector: 'app-block-offer-sempre-novo',
  templateUrl: './block-offer-sempre-novo.component.html',
  styleUrls: ['./block-offer-sempre-novo.component.scss'],
})
export class BlockOfferSempreNovoComponent implements OnInit, OnDestroy {
  @Input() block!: IBlockOfferSempreNovo;

  public marginFundo = new BehaviorSubject<number>(240);
  @ViewChild('element', { static: true }) element?: ElementRef<HTMLDivElement>;
  public subscription!: Subscription;
  public heightFundo: number = 0;
  public slidePerView = 4;
  public linkFromBlock?: ILink

  public veiculos: SempreNovoModelo[] = [];

  public modelosSubscription!: Subscription;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private sempreNovoService: SempreNovoService,
  ) {}


  ngOnInit(): void {
    this.linkFromBlock = this.block.link
      ? {
          ...this.block.link,
          href: this.block.marca
            ? this.block.link.href?.concat('?marcas=', this.block.marca)
            : this.block.link.href,
        }
      : undefined;

    this.subscription = this.marginFundo.subscribe((tamanho) => {
      this.calcularHeight(tamanho);
    });

    this.modelosSubscription = this.sempreNovoService.listarModelos(this.block.marca).subscribe((modelos) => {
      if (this.block.marca) {
        this.slidePerView = 3
        this.veiculos = modelos.slice(0, 3);
      } else {
        this.veiculos = modelos
      }

      if (modelos.length < this.slidePerView) {
        this.slidePerView = modelos.length;
      }

    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.modelosSubscription?.unsubscribe();
  }

  public calcularHeight(margin: number) {
    this.heightFundo =
      this.elementRef.nativeElement.parentElement!.offsetHeight -
      margin -
      (this.element?.nativeElement.offsetTop ?? 0);
  }

  public swiperConfigMobile: any = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1366: {
        perViewDesktop: 4,
      },
    },
  };
}
