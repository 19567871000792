import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, zip } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RdstationService } from './rdstation.service';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  constructor(
    private httpClient: HttpClient,
    private rdstationService: RdstationService
  ) {}

  private enviarEmail(
    objDados: any,
    emailsDestino: string[]
  ) {
    return this.httpClient
      .post(
        environment.API_URL + environment.API_PATH_LEAD,
        {
          destinos: emailsDestino.join(';'),
          dados: objDados
        },
        { observe: 'response' }
      )
      .pipe(map((x) => x.status));
  }

  private enviarEmailComResponderPara(
    objDados: any,
    emailsDestino: string[],
    responderPara?:string
  ) {
    return this.httpClient
      .post(
        environment.API_URL + environment.API_PATH_LEAD,
        {
          destinos: emailsDestino.join(';'),
          dados: objDados,
          responderPara: responderPara
        },
        { observe: 'response' }
      )
      .pipe(map((x) => x.status));
  }

  enviar(objDados: any, emailsDestino?: string[], enviarRdSTation?: boolean) {
    const observers: Observable<any>[] = [];
    if (emailsDestino?.length)
      observers.push(this.enviarEmail(objDados, emailsDestino));
    if (enviarRdSTation) observers.push(this.rdstationService.enviar(objDados));
    return zip(...observers).pipe(
      map((x) => x.every((o) => o == 200 || o == 204))
    );
  }
  enviarComReponderPara(objDados: any, emailsDestino?: string[], enviarRdSTation?: boolean, responderPara?:string) {
    const observers: Observable<any>[] = [];
    if (emailsDestino?.length)
      observers.push(this.enviarEmailComResponderPara(objDados, emailsDestino, responderPara));
    if (enviarRdSTation) observers.push(this.rdstationService.enviar(objDados));
    return zip(...observers).pipe(
      map((x) => x.every((o) => o == 200 || o == 204))
    );
  }
}
