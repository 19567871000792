import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: 'img[appStrapiicon]'
})
export class StrapiiconDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef<HTMLImageElement>
  ) { }

  @Input() appStrapiicon?: ArquivoData;
  @Input() icon?: string;

  public ngAfterViewInit(): void {

    const url = (this.icon && '/assets/images/' + this.icon) ?? environment.API_URL + this.appStrapiicon?.attributes.url;
    const alt = this.icon ?? this.appStrapiicon?.attributes.alternativeText ?? 'Grupo Vamos';

    this.elementRef.nativeElement.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAAH0lEQVR42mNkoBAwjhowasCoAaMGjBowasCoAcPNAACOMAAhOO/A7wAAAABJRU5ErkJggg==';
    this.elementRef.nativeElement.alt = alt;

    this.elementRef.nativeElement.style.setProperty('-webkit-mask-image', "url(" + url + ")");
    this.elementRef.nativeElement.style.setProperty('-webkit-mask-size', "contain");
    this.elementRef.nativeElement.style.setProperty('-webkit-mask-repeat', "no-repeat no-repeat");
    this.elementRef.nativeElement.style.setProperty('-webkit-mask-position', "center center");

  }
}
