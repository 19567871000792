<ng-container *ngIf="form && field">

  <div *ngIf="form.controls[field]?.errors?.['required']" class="">
    {{ 'FORM.MENSAGEM.OBRIGATORIO' | translate }}
  </div>


  <div *ngIf="form.controls[field]?.errors?.['email']" class="">
    {{ 'FORM.MENSAGEM.EMAIL' | translate }}
  </div>

  <div *ngIf="form.controls[field]?.errors?.['document']" class="">
    {{ 'FORM.MENSAGEM.DOCUMENTO' | translate }}
  </div>

  <div *ngIf="form.controls[field]?.errors?.['cnpj']" class="">
    {{ 'FORM.MENSAGEM.CNPJ' | translate }}
  </div>

  <!-- {{ form.controls[field]?.errors | json }} -->

</ng-container>
