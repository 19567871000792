<div class="background">
  <div class="container main" style="margin: auto 0;">
    <img [appStrapiimage]="this.block.image.data" class="image" />
    <div class="text-container">
      <p class="title">{{this.block.title}}</p>
      <p class="description">Com o Sempre Novo, tenha sempre caminhões revisados e garantidos<img
          height="24px" width="24px" [tooltip]="'Para todos os veículos locados, proporcionamos uma garantia estendida de até 1 ano para o trem de força (que inclui motor, câmbio e diferencial) de todos os veículos fabricados a partir de 2022, desde que tenham até 150.000km rodados. Para todos os veículos seminovos, proporcionamos uma garantia de 90 dias para o trem de força (que inclui motor, diferencial e câmbio).'"
          style="padding: 4px; display: inline-block; vertical-align: middle;"
          src="../../../../assets/images/sn-info.svg">, com baixa quilometragem e entrega
        imediata.</p>
    </div>
  </div>
</div>