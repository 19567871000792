import { Component, Input, OnInit } from '@angular/core';
import { RelationOneToMany } from 'src/models/Relation';
import { IBlockShopList, IShop } from 'src/models/zeus';

@Component({
  selector: 'app-block-shop-list',
  templateUrl: './block-shop-list.component.html',
  styleUrls: ['./block-shop-list.component.scss']
})
export class BlockShopListComponent implements OnInit {
  
  @Input() block!: Omit<IBlockShopList, 'shops'> & { shops: RelationOneToMany<IShop> };

  public todasLojas:any;


  public ngOnInit(): void {
    this.todasLojas = this.block.shops.data;
  }

  public onChange(e:Event): void {
    var filtro = e.target as HTMLSelectElement;
    if (filtro.value == "loja") {
      this.todasLojas = this.block.shops.data;
    }else{
      this.todasLojas = this.block.shops.data.filter((x) => x.attributes.shoptype == filtro.value);
    }
  }
}
