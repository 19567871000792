<div>
  <div class="column desktop-hidden">
    <img src="../../../../assets/images/sn-explore-mobile.jpg" alt="Logo do Grupo Simpar" width="150px" height="48.5px">
  </div>
  <div class="container">
    <div class="column">
      <img class="mobile-hidden" src="../../../../assets/images/sn-explore.png" alt="Logo do Grupo Simpar" width="150px" height="48.5px">
    </div>
    <div class="column right">
      <div>
        <h3>explore nossos produtos</h3>
        <p>Consulte os modelos que melhor atendem às suas necessidades acessando o nosso estoque.</p>
      </div>

      <div class="flex ctas">
        <a class="button outline" href="/seminovos/sempre-novo">Quero comprar</a>
        <a class="button" href="/locacao/sempre-novo">Quero alugar</a>
      </div>
    </div>
  </div>
</div>