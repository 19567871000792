<div class="estilo1 box-slide" style="width: 100%; aspect-ratio: 4 / 3; position: relative; overflow: hidden;" #topo>
  <ng-container>
    <swiper #swiper [slidesPerView]="1" [spaceBetween]="0" [navigation]="true" [virtual]="true" [lazy]="true"
      [preloadImages]="false" [navigation]="{enabled:true, nextEl:next , prevEl: prev}" class="estilo1 w-100"
      [pagination]="false" style="width: 100%; height: 100%; position: relative;" [autoHeight]="false">
      <ng-template swiperSlide *ngFor="let img of this.card.images?.data">
        <img [appStrapiimage]="img" [alt]="this.card.content" class="bg" [quality]="'90'" widthImg="400">
      </ng-template>
    </swiper>
  </ng-container>

  <div class="next-prev">
    <div class="glass swiper-button-prev" #prev></div>
    <div class="glass swiper-button-next" #next></div>
  </div>
</div>

<div class="box flex flex-column flex-items-start pa32 bg-white" style="flex: 1; gap: 16px;">
  <div [appEditorjsparsehtml]="this.card.content" class="w-100 mbauto"></div>
  <div class="flex flex-items-center mt32" *ngIf="this.card.datetime as dt">
    <img [appStrapiicon] icon="ico-calendar.svg" class="bg-gray mr8" width="24px" height="24px">
    <time class="cor-gray" [dateTime]="dt" pubdate>{{ dt |
      date:"dd/MMMM/yyyy":'+0000':tradutorService.locale }}</time>
  </div>
  <app-botao-tipo1 *ngFor="let link of card.link" [strapilink]="link" icon="plus.svg" class="w-100"></app-botao-tipo1>
</div>