import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotaoTipo1Component } from './botao-tipo1.component';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';
import { StrapiiconModule } from 'src/app/directives/strapiicon/strapiicon.module';



@NgModule({
  declarations: [
    BotaoTipo1Component
  ],
  exports: [
    BotaoTipo1Component
  ],
  imports: [
    StrapilinkModule,
    StrapiiconModule,
    CommonModule
  ]
})
export class BotaoTipo1Module { }
