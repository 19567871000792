import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { StrapiiconModule } from "src/app/directives/strapiicon/strapiicon.module";
import { TooltipModule } from "src/app/directives/tooltip/tooltip.module";
import SwiperCore, {
  A11y,
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper";
import { SwiperModule } from "swiper/angular";
import { EditorjsparsehtmlModule } from "../../directives/editorjsparsehtml/editorjsparsehtml.module";
import { StrapiimageModule } from "../../directives/strapiimage/strapiimage.module";
import { StrapilinkModule } from "../../directives/strapilink/strapilink.module";
import { BotaoTipo1Module } from "../common/botao-tipo1/botao-tipo1.module";
import { FormsModule } from "../common/forms/forms.module";
import { SafeModule } from "./../../pipes/safe/safe.module";
import { BadgeComponent } from "./badge/badge.component";
import { CardComponent } from "./card/card.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { FaqComponent } from "./faq/faq.component";
import { GlossaryComponent } from "./glossary/glossary.component";
import { IconTextComponent } from "./icon-text/icon-text.component";
import { ImageTextComponent } from "./image-text/image-text.component";
import { LinkComponent } from "./link/link.component";
import { MapComponent } from "./map/map.component";
import { MedalComponent } from "./medal/medal.component";
import { OfferCardComponent } from "./offer-card/offer-card.component";
import { PlanCardComponent } from "./plan-card/plan-card.component";
import { ShopCardComponent } from "./shop-card/shop-card.component";
import { SocialShareComponent } from "./social-share/social-share.component";
import { TestimonialComponent } from "./testimonial/testimonial.component";
import { YoutubeVideoComponent } from "./youtube-video/youtube-video.component";
import { BlockContactFormModule } from "../blocks/block-contact-form/block-contact-form.module";
import { ContentModule } from "./content/content.module";
import { BannerComponent } from "./banner/banner.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { ImageComponent } from "./image/image.component";
import { BotaoSimuladorComponent } from "../common/botao-simulador/botao-simulador.component";
import { IconComponent } from "../common/icon/icon.component";
import { EditVeiculoSimulacaoComponent } from "./edit-veiculo-simulacao/edit-veiculo-simulacao.component";
import { EditImplementoSimulacaoComponent } from "./edit-implemento-simulacao/edit-implemento-simulacao.component";
import { AddImplementoSimulacaoComponent } from "./add-implemento-simulacao/add-implemento-simulacao.component";
import { AddExtraSimulacaoComponent } from "./add-extra-simulacao/add-extra-simulacao.component";
import { EditExtraSimulacaoComponent } from "./edit-extra-simulacao/edit-extra-simulacao.component";
import { ItemSimulacaoComponent } from "./item-simulacao/item-simulacao.component";
import { InputNumberComponent } from "../common/input-number/input-number.component";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Lazy,
  Virtual,
]);
@NgModule({
  declarations: [
    CardComponent,
    BadgeComponent,
    CarouselComponent,
    IconTextComponent,
    ImageTextComponent,
    LinkComponent,
    MedalComponent,
    PlanCardComponent,
    TestimonialComponent,
    YoutubeVideoComponent,
    OfferCardComponent,
    FaqComponent,
    MapComponent,
    GlossaryComponent,
    SocialShareComponent,
    ShopCardComponent,
    BannerComponent,
    AccordionComponent,
    ImageComponent,
    BotaoSimuladorComponent,
    IconComponent,
    EditVeiculoSimulacaoComponent,
    EditImplementoSimulacaoComponent,
    AddImplementoSimulacaoComponent,
    AddExtraSimulacaoComponent,
    EditExtraSimulacaoComponent,
    ItemSimulacaoComponent,
    InputNumberComponent,
  ],
  exports: [
    CardComponent,
    BadgeComponent,
    CarouselComponent,
    IconTextComponent,
    ImageTextComponent,
    LinkComponent,
    MedalComponent,
    PlanCardComponent,
    TestimonialComponent,
    YoutubeVideoComponent,
    OfferCardComponent,
    FaqComponent,
    MapComponent,
    GlossaryComponent,
    SwiperModule,
    SocialShareComponent,
    ShopCardComponent,
    BlockContactFormModule,
    ContentModule,
    BannerComponent,
    AccordionComponent,
    ImageComponent,
    BotaoSimuladorComponent,
    IconComponent,
    AddImplementoSimulacaoComponent,
    AddExtraSimulacaoComponent,
    EditVeiculoSimulacaoComponent,
    EditImplementoSimulacaoComponent,
    EditExtraSimulacaoComponent,
  ],
  imports: [
    RouterModule,
    FormsModule,
    SwiperModule,
    BotaoTipo1Module,
    CommonModule,
    EditorjsparsehtmlModule,
    StrapiimageModule,
    StrapiiconModule,
    StrapilinkModule,
    TooltipModule,
    BlockContactFormModule,
    SafeModule,
    ContentModule,
  ],
})
export class ItemsModule {}
