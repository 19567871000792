<div>
    <a (click)="toggleFaq()"
        class="flex flex-justify-between flex-items-center cor-black s4 hv-cor-primary w-100 pt24 pb24 line question">
        <div class="flex flex-column flex-justify-center" [appEditorjsparsehtml]="accordionContent.Title"></div>
        <img [ngClass]="{'fechado': arrowToggle, 'aberto': !arrowToggle}" class="seta bg-black ml8" height="22px"
            width="22px" alt="">
    </a>
    <div class="cor-black s2 flex flex-column flex-justify-center answer" style="overflow: hidden;"
        [@ngIfAnimation]='state'>
        <div class="itemContainer">
            <ng-container *ngFor="let image of accordionContent.images1">
                <div class="imgContainer">
                    <app-image [image]="image">
                    </app-image>
                </div>
            </ng-container>
            <div [ngClass]="accordionContent.images3? 'itemContainer_textImg' : 'itemContainer_text'"
                [appEditorjsparsehtml]="this.accordionContent.text1">
            </div>
        </div>
        <div class="itemContainer">
            <ng-container *ngFor="let image of accordionContent.images2">
                <div class="imgContainer">
                    <app-image [image]="image">
                    </app-image>
                </div>
            </ng-container>
            <div [ngClass]="accordionContent.images2 ? 'itemContainer_textImg' : 'itemContainer_text'"
                [appEditorjsparsehtml]="this.accordionContent.text2">
            </div>
        </div>
        <div class="itemContainer">
            <ng-container *ngFor="let image of accordionContent.images3">
                <div class="imgContainer">
                    <app-image [image]="image">
                    </app-image>
                </div>
            </ng-container>
            <div [ngClass]="accordionContent.images3? 'itemContainer_textImg' : 'itemContainer_text'"
                [appEditorjsparsehtml]="this.accordionContent.text3">
            </div>
        </div>
        <div class="itemContainer containerMobile containerThumbs" *ngIf="accordionContent.thumb">
            <ng-container *ngFor="let image of accordionContent.thumb">
                <app-image [image]="image" [ngClass]="image.size == 'thumb' ? 'itemContainer_img' : 'none'">
                </app-image>
            </ng-container>
        </div>
        <div class="itemContainer_files">
            <a [href]="environment.API_URL + arquivo.attributes.url" [download]="arquivo.attributes.name"
                target="_blank" class="pt8 pb8 cor-black hv-cor-primary"
                *ngFor="let arquivo of this.accordionContent.files?.data">
                <div class="flex flex-items-center">
                    <div class="tag mr8" [attr.format]="arquivo.attributes.ext">{{ arquivo.attributes.ext}}</div>
                    <p class="udl">
                        <b>Baixar arquivo:</b> {{ arquivo.attributes.name}} <br>
                    </p>
                </div>
            </a>
        </div>
    </div>
</div>