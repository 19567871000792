import { Component, Input } from "@angular/core";
import { IImageText } from "src/models/zeus";

@Component({
  selector: "app-image-text",
  templateUrl: "./image-text.component.html",
  styleUrls: ["./image-text.component.scss"],
})
export class ImageTextComponent {
  @Input() public imageText!: IImageText;
}
