<div *ngIf="block.blockIdentifier === 'destaque' ? veiculos.length : true">
  <img
    *ngIf="this.block.backgroundImage?.data as imgdata"
    [appStrapiimage]="imgdata"
    class="fundo"
    [quality]="'60'"
    [id]="this.block.blockIdentifier"
  />

  <div class="container flex flex-column pt64">
    <app-content
      [content]="content"
      *ngIf="this.block.content as content"
    ></app-content>
  </div>
  <div class="container flex flex-wrap" style="position: relative; gap: 24px">
    <swiper
      [config]="swiperConfigMobile"
      [slidesPerView]="4"
      [spaceBetween]="24"
      [navigation]="true"
      [navigation]="{ enabled: true, nextEl: next, prevEl: prev }"
      class="my-swiper2"
      [pagination]="false"
      style="width: 100%"
    >
      <ng-template swiperSlide *ngFor="let veiculo of veiculos">
        <app-offer-card [produtofiltro]="veiculo"></app-offer-card>
      </ng-template>
    </swiper>
    <div
      class="container next-prev estilo1"
      [style.top]="(this.marginFundo | async) + 'px'"
    >
      <div class="swiper-button-prev" #prev></div>
      <div class="swiper-button-next" #next></div>
    </div>
  </div>

  <div class="flex flex-justify-center mt56 pb64">
    <app-botao-tipo1
      class="redondo"
      [strapilink]="this.block.link"
    ></app-botao-tipo1>
  </div>
</div>
