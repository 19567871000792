import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import phoneNumberMask from "src/app/helpers/mask/phoneNumberMask";
import { DataLayerService } from "src/app/services/data-layer.service";
import { LeadService } from "src/app/services/lead.service";
import { environment } from "src/environments/environment";
import { Status } from "src/models/Status";
import { Location } from "@angular/common";
import cnpjMask from "src/app/helpers/mask/cnpjMask";
import { InputMasks } from "src/app/helpers/mask/InputMask";
import { cnpjValidator, documentValidator } from "src/app/helpers/validators/documentValidator.validator";

@Component({
  selector: "app-sn-block-form",
  templateUrl: "./sn-block-form.component.html",
  styleUrls: ["./sn-block-form.component.scss"],
})
export class SNBlockForm implements OnInit, OnDestroy {
  public subscription!: Subscription;
  public formSubscription?: Subscription;
  public sendStatus?: Status;
  private identifier = this.location.path(false).split("?")[0];

  public documentLabel = 'CNPJ';

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl("", Validators.compose([Validators.required])),
    email: new UntypedFormControl(
      "",
      Validators.compose([Validators.required, Validators.email])
    ),
    motivo: new UntypedFormControl('rent', Validators.compose([Validators.required])),
    documento: new UntypedFormControl('', Validators.compose([Validators.required, cnpjValidator()])),
    telefone: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(14),
      ])
    ),
  });

  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);

    this.formSubscription = this.form.get('motivo')?.valueChanges.subscribe(val => {
      const documentControl = this.form.get('documento')!;
      documentControl.setValue('');

      if (val === 'rent') {
        this.documentLabel = "CNPJ"
        documentControl.setValidators(Validators.compose([Validators.required, cnpjValidator()]));
        return;
      }

      this.documentLabel = "CPF/CNPJ"
      documentControl.setValidators(Validators.compose([Validators.required, documentValidator()]));
    });
  }

  public onDocumento($event: Event): void {
    let reason = this.form.get('motivo')?.value || 'rent';

    let textBox = $event.target as HTMLInputElement;
    const onlyNumbers = textBox.value.replace(/\D/g, '');

    if (reason == 'buy' && onlyNumbers.length == 11) {
      textBox.value = InputMasks.cpfMask(onlyNumbers);
    } else {
      textBox.value = cnpjMask(onlyNumbers);
    }
  }

  public onWhatsApp($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = phoneNumberMask(textBox.value);
  }

  public submit(): void {
    const f = this.form.value;

    const pathname = window.location.pathname;
    if (pathname.startsWith("/")) {
      this.identifier = `sempre-novo`;
    }

    let dados = {
      name: f.nome,
      email: f.email,
      motivo: f.motivo === 'rent' ? 'Quero alugar' : 'Quero comprar',
      documento: f.documento,
      telefone: f.telefone,
      identificador: this.identifier,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.sendStatus = "carregando";
      this.subscription = this.leadService
        .enviarComReponderPara(
          dados,
          ["gvm.form.vamos@grupovamos.com.br"],
          false,
        )
        .subscribe((sucesso) => {
          this.sendStatus = sucesso ? "enviado" : "erro";

          if (this.sendStatus == "enviado") {
            this.dataLayerService.formSucesso(this.identifier);
          }
        });
    }
  }
  
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.formSubscription?.unsubscribe();
  }
}
