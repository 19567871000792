<form [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{
      "FORM.CAMPO.NOME.LABEL" | translate
      }}</label>
    <input formControlName="nome" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text" />
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>

  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.EMAIL.LABEL" | translate
        }}</label>
      <input formControlName="email" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="email" />
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.TELEFONE.LABEL" | translate
        }}</label>
      <input formControlName="telefone" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="tel"
        (keyup)="this.onTextBox($event)" maxlength="15" style="width: 290px" />
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>
  </div>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">
      {{ "FORM.CONTACTUS.MOTIVOCONTATO.LABEL" | translate }}</label>
    <select formControlName="motivocontato" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16">
      <option value="" disabled selected>
        {{ "FORM.BOTAO.SELECIONAR" | translate }}
      </option>
      <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO5' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO5" | translate }}
      </option>
      <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO7' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO7" | translate }}
      </option>
      <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO8' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO8" | translate }}
      </option>
      <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO2' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO2" | translate }}
      </option>
      <!--<option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO1' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO1" | translate }}
      </option>-->
      <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO4' | translate }}">
        {{ "FORM.CONTACTUS.MOTIVOCONTATO.OPCAO4" | translate }}
      </option>
    </select>
  </div>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{
      "FORM.CAMPO.MENSAGEM.LABEL" | translate
      }}</label>
    <textarea formControlName="mensagem" class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16" name=""
      id=""></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div class="flex flex-column mb32 confirmo">
    <label class="flex s2 cor-black flex-items-center">
      <input formControlName="confirmarenvio" type="checkbox" name="finance" [value]="true" />
      {{ "FORM.CONTACTUS.CONFIRMARENVIO" | translate }}
    </label>
  </div>
  <div>
    <button type="submit" [disabled]="!form.valid"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">
      {{ "FORM.BOTAO.ENVIAR" | translate }}
    </button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>
</form>