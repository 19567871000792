<div class="container flex flex-column" [id]="this.block.blockIdentifier">
  <div class="search-container">
    <div class="tabs">
      <button
        class="tabs-button"
        (click)="changeCategoriaAutocomplete(categoriaEnum.caminhoes)"
        [class.focused]="categoriaSelecionada === categoriaEnum.caminhoes"
      >
        Comprar Caminhão
      </button>
      <button
        class="tabs-button"
        (click)="changeCategoriaAutocomplete(categoriaEnum.implementos)"
        [class.focused]="categoriaSelecionada === categoriaEnum.implementos"
      >
        Comprar Implemento
      </button>
      <button
        class="tabs-button"
        (click)="changeCategoriaAutocomplete(categoriaEnum.maquinasAgricolas)"
        [class.focused]="
          categoriaSelecionada === categoriaEnum.maquinasAgricolas
        "
      >
        Comprar Máquina Agrícola
      </button>
      <button
        class="tabs-button"
        style="padding-right: 0"
        (click)="changeCategoriaAutocomplete(categoriaEnum.onibus)"
        [class.focused]="categoriaSelecionada === categoriaEnum.onibus"
      >
        Comprar Ônibus
      </button>
    </div>
    <div class="search-row">
      <form [formGroup]="formBuscar" class="flex flex-column pt24">
        <input
          type="text"
          placeholder="Buscar por modelo especifico (ex: 19.420 Constellation)"
          class="cor-black w-100 pt8 pb8 pl48 pr16 fw400 s3 up"
          formControlName="termoBuscado"
          (keyup)="buscar(formBuscar.value.termoBuscado!)"
        />
        <div class="searchIcon">
          <img
            src="../../../../assets/images/search.svg"
            alt="ícone de busca"
          />
        </div>
      </form>
      <button class="offer-button" (click)="navegaParaVitrineCorreta()">
        Ver ofertas ({{ getCurrentCount() }})
      </button>
    </div>
  </div>

  <ul class="searchList" *ngIf="formBuscar.value.termoBuscado?.length! >= 2">
    <ng-container *ngIf="filtroOpcoes.marca.length >= 1">
      <span class="searchList_text"><b>Marca</b></span>
      <ng-container *ngFor="let marca of filtroOpcoes.marca">
        <li class="searchList_item">
          <a class="searchList_link" (click)="navegarMarca(marca)">
            {{ marca | uppercase }}
          </a>
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="filtroOpcoes.modelo.length >= 1">
      <span class="searchList_text"><b>Modelo</b></span>
      <ng-container *ngFor="let modelo of filtroOpcoes.modelo">
        <li class="searchList_item">
          <a class="searchList_link" (click)="navegarModelo(modelo)">
            {{ modelo.nome | uppercase }}
          </a>
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="semResultados">
      <li class="searchList_sem-item">
        Nenhum veículo encontrado |
        <a
          class="searchList_link-sem-item"
          routerLink="/seminovos/estoque/cavalo-mecanico"
          [queryParams]="{ ordem: '1', pagina: '1' }"
        >
          Ver todos os veículos
        </a>
      </li>
    </ng-container>
  </ul>
</div>
