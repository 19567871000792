<ng-container *ngIf="this.carousel as c" (click)="handleClickBanner()">
  <img
    [preloadHead]="this.primeiroItemDoPrimeiroBloco"
    [quality]="'90'"
    [appStrapiimage]="c.image.data"
    class="fundo desk"
    *ngIf="isDesktopLayout"
  />

  <img
    [preloadHead]="this.primeiroItemDoPrimeiroBloco"
    [quality]="'90'"
    [appStrapiimage]="c.imageMobile?.data"
    class="fundo"
    *ngIf="isMobileLayout && c.imageMobile?.data != null"
  />
  <img
    [preloadHead]="this.primeiroItemDoPrimeiroBloco"
    [quality]="'90'"
    [appStrapiimage]="c.image.data"
    class="fundo"
    *ngIf="isMobileLayout && c.imageTablet?.data == null"
  />

  <img
    [preloadHead]="this.primeiroItemDoPrimeiroBloco"
    [quality]="'90'"
    [appStrapiimage]="c.imageTablet?.data"
    class="fundo"
    *ngIf="isTabletLayout && c.imageTablet?.data != null"
  />
  <img
    [preloadHead]="this.primeiroItemDoPrimeiroBloco"
    [quality]="'90'"
    [appStrapiimage]="c.image.data"
    class="fundo"
    *ngIf="isTabletLayout && c.imageTablet?.data == null"
  />

  <div
    class="container flex flex-justify-center flex-row flex-items-center pt64 pb32"
    [ngClass]="c.height"
    style="margin: auto 0; gap: 32px"
  >
    <ng-container *ngIf="!isLocacao">
      <div class="esquerda flex flex-items-start flex-column" style="flex: 1">
        <div [appEditorjsparsehtml]="c.content" class="content"></div>
        <app-botao-tipo1
          *ngIf="c.link as link"
          [strapilink]="link"
          icon="plus.svg"
          class="mt64"
          cor1="var(--white)"
          cor2="var(--black-translucent)"
        ></app-botao-tipo1>
      </div>
    </ng-container>

    <ng-container *ngIf="isLocacao">
      <div class="esquerda flex flex-items-start flex-column" style="flex: 1">
        <div [appEditorjsparsehtml]="c.content" class="content"></div>
        <ng-container *ngIf="isMobileLayout || isTabletLayout">
          <app-botao-tipo1
            *ngIf="c.link as link"
            [strapilink]="link"
            icon="plus.svg"
            class="mt64"
            cor1="var(--white)"
            cor2="var(--black-translucent)"
          >
          </app-botao-tipo1>
        </ng-container>
      </div>
    </ng-container>

    <div class="direita bg-white" *ngIf="!formularioFixo">
      <div *ngIf="FormStatusItem">
        <app-block-contact-form
          [formTitle]="'Fale com a vamos locação'"
          *ngIf="c.forms as bloco"
          [block]="bloco"
        ></app-block-contact-form>
      </div>
    </div>
  </div>
</ng-container>
