import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Observable, shareReplay, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StrapiRetorno } from 'src/models/strapi_retorno';
import { IPage } from 'src/models/zeus';
import { HttpStateService } from './http.state.service';
import { TradutorService } from './tradutor.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public temUmCarrosselNoPrimeiroBlocoDaPrimeiraSection: boolean = false;

  constructor(
    private httpStateService: HttpStateService,
    private tradutorService: TradutorService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.cores$.subscribe();
  }


  temas = ["vamos", "locacao---sempre-novo", "locacao", "seminovos", "agro", "concessionarias---maquinas"];

  cores$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((url: NavigationEnd) => {
      return this.temas.find(t => url.urlAfterRedirects.startsWith('/' + t.replace(/---/gi, '/')));
    })).pipe(tap(tema => {
      if (!tema) tema = 'vamos';
      this.temas.filter(t => t != tema).forEach(t => { this.document.body.classList.remove(t) });
      this.document.body.classList.add(tema);
    }));

  paginaSalva: {[key: string]: Observable<StrapiRetorno<IPage>>} = {};

  public byPath(path: string) {
    let novaPath = path.replace(/^\//gi, '');
    novaPath = novaPath.split('?')[0];
    const paginaSalva = this.paginaSalva[novaPath];

    if(!paginaSalva) {
      this.paginaSalva[novaPath] =
      this.tradutorService.locale$.pipe(mergeMap(lang => {
        // alert()
        return this.httpStateService.get<StrapiRetorno<IPage>>(environment.API_URL + environment.API_PATH_PAGE + `?filters[slug][$eq]=${lang }/${novaPath}&populate=deep,15&locale=${lang}`).pipe(tap(retorno => {
          if(!retorno.data.length){
            // se a página não exitir, a gente deleta o observable salvo no path requisitado
            delete this.paginaSalva[novaPath];
          }
        }))
        // tentamos pegar o page no transfer-state

      }), shareReplay());
    }
    // re-disparamos a cadeia de requisição ao detectar mudança de idioma
    return this.paginaSalva[novaPath];

  }
}
