import { Location } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { Subscription } from "rxjs";
import cnpjMask from "src/app/helpers/mask/cnpjMask";
import phoneNumberMask from "src/app/helpers/mask/phoneNumberMask";
import { DataLayerService } from "src/app/services/data-layer.service";
import { LeadService } from "src/app/services/lead.service";
import { environment } from "src/environments/environment";
import { Interests } from "src/models/Interests";
import { Status } from "src/models/Status";
import { IBlockBudgetForm } from "src/models/zeus";

@Component({
  selector: "app-form-make-budget-locacao",
  templateUrl: "./form-make-budget-locacao.component.html",
  styleUrls: ["./form-make-budget-locacao.component.scss"],
})
export class FormMakeBudgetLocacaoComponent implements OnInit, OnDestroy {
  @Input() block!: IBlockBudgetForm;
  @Input() identifier =
    this.block?.identifier ?? this.location.path(false).split("?")[0];
  @Input() identifierForm: string | undefined;

  public subscription!: Subscription;
  public sendStatus?: Status;
  Interests: Interests[] = [
    { name: "CAMINHAO", value: "interessecaminhao" },
    { name: "LINHAAGRO", value: "interesselinhaagro" },
    { name: "LINHAAMARELA", value: "interesselinhaamarela" },
    { name: "INTRALOGISTICA", value: "interesseintralogistica" },
    { name: "ONIBUS", value: "interesseonibus" },
    { name: "IMPLEMENTOS", value: "interesseimplementos" },
  ];

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl("", Validators.compose([Validators.required])),
    razaosocial: new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    ),
    cnpj: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(18),
        Validators.minLength(14),
      ])
    ),
    telefone: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(14),
      ])
    ),
    email: new UntypedFormControl(
      "",
      Validators.compose([Validators.required, Validators.email])
    ),
    interesses: new UntypedFormArray(
      [],
      Validators.compose([Validators.required])
    ),
    interessecaminhao: new UntypedFormControl(false),
    interesselinhaagro: new UntypedFormControl(false),
    interesselinhaamarela: new UntypedFormControl(false),
    interesseintralogistica: new UntypedFormControl(false),
    interesseonibus: new UntypedFormControl(false),
    interesseimplementos: new UntypedFormControl(false),
    mensagem: new UntypedFormControl(""),
  });

  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onTextBox($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = phoneNumberMask(textBox.value);
  }

  public onCnpjTextBox($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = cnpjMask(textBox.value);
  }

  public onInteresseCheckBox($event: Event): void {
    let checkBox = $event.target as HTMLInputElement;
    const checkArray: UntypedFormArray = this.form.get(
      "interesses"
    ) as UntypedFormArray;
    if (checkBox.checked) {
      checkArray.push(new UntypedFormControl(checkBox.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == checkBox.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  public submit() {
    const f = this.form.value;
    const pathname = window.location.pathname;
    if (pathname.startsWith("/")) {
      if (pathname.includes("implementos-para-caminhao")) {
        const newUrl = pathname.substr(1);
        this.identifier = newUrl;
      } else if (
        this.identifierForm === "quer-saber-quanto-voce-pode-economizar"
      ) {
        this.identifier = "quer-saber-quanto-voce-pode-economizar";
      } else {
        const newUrl = pathname.substr(1);
        const hash = window.location.hash;
        this.identifier = `${newUrl}${hash}`;
      }
    }

    const checkArray: UntypedFormArray = this.form.get(
      "interesses"
    ) as UntypedFormArray;
    let listaInteresses: any = [];

    checkArray.controls.forEach((item: any) => {
      if (item.value == "interessecaminhao") {
        f.interessecaminhao = "true";
        listaInteresses.push("Caminhão");
      }
      if (item.value == "interesselinhaagro") {
        f.interesselinhaagro = "true";
        listaInteresses.push("Linha Agro");
      }
      if (item.value == "interesselinhaamarela") {
        f.interesselinhaamarela = "true";
        listaInteresses.push("Linha Amarela");
      }
      if (item.value == "interesseintralogistica") {
        f.interesseintralogistica = "true";
        listaInteresses.push("Intralogística");
      }
      if (item.value == "interesseonibus") {
        f.interesseonibus = "true";
        listaInteresses.push("Ônibus");
      }
      if (item.value == "interesseimplementos") {
        f.interesseimplementos = "true";
        listaInteresses.push("Implementos");
      }
    });

    let dados = {
      name: f.nome,
      "Razão Social": f.razaosocial,
      cnpj: f.cnpj,
      mobile_phone: f.telefone,
      email: f.email,
      Interesse: listaInteresses,
      mensagem: f.mensagem,
      identificador: this.identifier,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.sendStatus = "carregando";
      this.subscription = this.leadService
        .enviarComReponderPara(
          dados,
          this.block.sendToEmails?.split("\n"),
          this.block.sendToRdStation,
          this.block.replyTo
        )
        .subscribe((sucesso) => {
          this.sendStatus = sucesso ? "enviado" : "erro";
          if (this.sendStatus == "enviado") {
            this.dataLayerService.formSucesso(this.identifier);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
