import { Component, Input } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { IBlockLatestBlogPosts } from 'src/models/zeus';
import { map } from 'rxjs';

@Component({
  selector: 'app-block-latest-blog-posts',
  templateUrl: './block-latest-blog-posts.component.html',
  styleUrls: ['./block-latest-blog-posts.component.scss']
})
export class BlockLatestBlogPostsComponent {

  @Input() block!: IBlockLatestBlogPosts;

  constructor(
    private blogService: BlogService
  ) { }

  public posts$ = this.blogService.getList$.pipe(map(retorno => retorno.data), map(q => q.map(k => this.blogService.converterIPostParaICard(k))));

  public swiperConfigMobile: any = {
    slidesPerView: 'auto',
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      }
    }
  }
}
