<div id="sn-form">
  <div class="container flex pa120">
    <div class="column left">
      <svg xmlns="http://www.w3.org/2000/svg" width="113" height="145" viewBox="0 0 113 145" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M79.675 6.78174C65.0699 6.78174 52.9614 18.1482 52.9614 32.4888C52.9614 39.3575 55.7629 45.578 60.2786 50.1636C60.3971 50.6286 60.2545 52.1028 58.739 54.2779C57.9636 55.2317 57.748 56.5237 58.1722 57.6778C58.5959 58.8313 59.5971 59.6769 60.8055 59.9013C64.9435 60.6711 70.0822 59.5254 73.7492 57.5756C75.4421 57.8933 77.9504 58.1976 79.675 58.1958C94.2801 58.1958 106.389 46.8294 106.389 32.4888C106.389 18.1482 94.2801 6.78174 79.675 6.78174ZM72.67 30.0109C70.7457 30.0109 69.1856 31.5709 69.1856 33.4953C69.1856 35.4196 70.7457 36.9797 72.67 36.9797H72.7076C74.6319 36.9797 76.192 35.4196 76.192 33.4953C76.192 31.5709 74.6319 30.0109 72.7076 30.0109H72.67ZM86.6075 30.0109C84.6832 30.0109 83.1231 31.5709 83.1231 33.4953C83.1231 35.4196 84.6832 36.9797 86.6075 36.9797H86.6451C88.5694 36.9797 90.1295 35.4196 90.1295 33.4953C90.1295 31.5709 88.5694 30.0109 86.6451 30.0109H86.6075Z"
          fill="#C4161C" />
        <g opacity="0.4">
          <path
            d="M19.2803 57.8859C19.2803 48.9055 26.5603 41.6255 35.5407 41.6255C44.521 41.6255 51.8012 48.9055 51.8012 57.8859C51.8012 66.8663 44.521 74.1463 35.5407 74.1463C26.5603 74.1463 19.2803 66.8663 19.2803 57.8859Z"
            fill="#141B34" />
          <path
            d="M18.4711 83.0923C28.9334 77.3579 42.1479 77.3579 52.61 83.0923C54.6143 84.1306 58.999 86.4108 61.2466 88.4359C62.6325 89.6847 64.232 91.5649 64.5294 94.0699C64.8583 96.8393 63.4966 99.2217 61.3665 101.089C57.8905 104.137 53.6131 106.667 48.0585 106.667H23.0226C17.4684 106.667 13.191 104.137 9.71455 101.089C7.58481 99.2217 6.22288 96.8393 6.5518 94.0699C6.84928 91.5649 8.44898 89.6847 9.83483 88.4359C12.0825 86.4108 16.4669 84.1306 18.4711 83.0923Z"
            fill="#141B34" />
        </g>
      </svg>
      <h3>ficou alguma dúvida?</h3>
      <p>Nosso time está disponível para te ajudar a ter seu veículo sempre novo.</p>
      <p>Preencha seus dados ao lado.</p>
    </div>
    <div class="column right">
      <form [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f>
        <div class="flex flex-column mb32">
          <label class="fw600 mb8">Seu nome</label>
          <input formControlName="nome" id="nome" class="bg-transparent pt8 pb8 pl16 pr16" type="text"
            placeholder="Informe seu nome completo">
          <app-errormessage [form]="form" field="nome"></app-errormessage>
        </div>

        <div class="flex flex-wrap mb32" style="gap: 24px;">
          <div class="flex flex-column" style="flex: 1;">
            <label class="  fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
            <input formControlName="email" id="email" class="bg-transparent pt8 pb8 pl16 pr16" type="email"
              placeholder="Informe o seu email">
            <app-errormessage [form]="form" field="email"></app-errormessage>
          </div>
        </div>

        <div class="flex flex-wrap mb32" style="gap: 24px;">
          <div class="flex flex-row" style="flex: 1; gap: 1rem;">
            <input formControlName="motivo" id="alugar" name="motivo" class="bg-transparent pt8 pb8 pl16 pr16" type="radio" value="rent">
            <label for="alugar">Quero alugar veículos</label>
            <input formControlName="motivo" id="comprar" name="motivo" class="bg-transparent pt8 pb8 pl16 pr16" type="radio" value="buy">
            <label for="comprar">Quero comprar veículos</label>
            <app-errormessage [form]="form" field="motivo"></app-errormessage>
          </div>
        </div>

        <div class="flex flex-column mb32">
          <div class="flex flex-column" style="flex: 1;">
            <label class="fw600 mb8">{{documentLabel}}</label>
            <input formControlName="documento" id="documento" class="bg-transparent pt8 pb8 pl16 pr16" type="text"
              (keyup)="this.onDocumento($event)" maxlength="18" placeholder="Digite somente os números">
            <app-errormessage [form]="form" field="documento"></app-errormessage>
          </div>
        </div>

        <div class="flex flex-column mb32">
          <div class="flex flex-column" style="flex: 1;">
            <label class="fw600 mb8">WhatsApp</label>
            <input formControlName="telefone" id="telefone" class="bg-transparent pt8 pb8 pl16 pr16" type="text"
              (keyup)="this.onWhatsApp($event)" maxlength="15" placeholder="Digite seu Whatsapp com DDD">
            <app-errormessage [form]="form" field="telefone"></app-errormessage>
          </div>
        </div>

        <div class="flex flex-justify-center">
          <button [disabled]="!form.valid" type="submit"
            class="cor-white bg-global-black hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull w-100">
            {{ 'FORM.BOTAO.ENVIAR' | translate }}
          </button>
        </div>

        <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>

      </form>
    </div>
  </div>
</div>