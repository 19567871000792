import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EditorjsparsehtmlModule } from "src/app/directives/editorjsparsehtml/editorjsparsehtml.module";
import { SwiperModule } from "swiper/angular";
import { ItemsModule } from "../items/items.module";
import { SNBlockHeader } from "./sn-block-header/sn-block-header.component";
import { SNBlockSubheader } from "./sn-block-subheader/sn-block-subheader.component";
import { SNBlockLegal } from "./sn-block-legal/sn-block-legal.component";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { StrapiiconModule } from "src/app/directives/strapiicon/strapiicon.module";
import { StrapiimageModule } from "src/app/directives/strapiimage/strapiimage.module";
import { StrapilinkModule } from "src/app/directives/strapilink/strapilink.module";
import { TooltipModule } from "src/app/directives/tooltip/tooltip.module";
import { SortModule } from "src/app/pipes/sort/sort.module";
import { BotaoTipo1Module } from "../common/botao-tipo1/botao-tipo1.module";
import { BotaoWhatsappModule } from "../common/botao-whatsapp/botao-whatsapp.module";
import { FormsModule } from "../common/forms/forms.module";
import { LoadingModule } from "../common/loading/loading.module";
import { ModalModule } from "../common/modal/modal.module";
import { AfterIfModule } from "src/app/directives/afterif/afterif.module";
import { DateFilterModule } from "src/app/pipes/date-filter/date-filter.module";
import { ReactiveFormsModule, FormsModule as fm } from "@angular/forms";
import { ContentModule } from "../items/content/content.module";
import { SafeModule } from "src/app/pipes/safe/safe.module";
import { SNBlockFeatures } from "./sn-block-features/sn-block-features.component";
import { SNFeature } from "./sn-feature/sn-feature.component";
import { SNBlockExplore } from "./sn-block-explore/sn-block-explore.component";
import { SNBlockForm } from "./sn-block-form/sn-block-form.component";

@NgModule({
  declarations: [
    SNBlockHeader,
    SNBlockSubheader,
    SNBlockFeatures,
    SNBlockExplore,
    SNBlockForm,
    SNFeature,
    SNBlockLegal,
  ],
  exports: [
    SNBlockHeader,
    SNBlockSubheader,
    SNBlockFeatures,
    SNBlockExplore,
    SNBlockForm,
    SNFeature,
    SNBlockLegal,
    ItemsModule,
    SwiperModule,
    fm,
    ContentModule,
  ],
  imports: [
    DateFilterModule,
    AfterIfModule,
    ModalModule,
    SortModule,
    LoadingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    BotaoTipo1Module,
    BotaoWhatsappModule,
    StrapilinkModule,
    StrapiiconModule,
    StrapiimageModule,
    EditorjsparsehtmlModule,
    CommonModule,
    ItemsModule,
    TooltipModule,
    SwiperModule,
    fm,
    ContentModule,
    SafeModule,
  ],
})
export class SempreNovoModule {}
