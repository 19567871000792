<img *ngIf="this.block.backgroundImage?.data as imgdata" [appStrapiimage]="imgdata" class="fundo" [quality]="'60'"
  [id]="this.block.blockIdentifier">

<div class="flex flex-column container pt64 pb64" style="position: relative;">
  <app-content [content]="content" *ngIf="this.block.content as content"></app-content>

  <div class="bloco flex flex-wrap flex-row bg-white">
    <div class="flex flex-column lojas" style="border-right: 1px solid var(--gray); ">
      <div class="flex flex-column wrapperSelect box pt48 pb8 pl32 pr32">
        <select class="pt8 pb8 pl8 pr8" (change)="filtrarPorEstado($event)">
          <option value="">Selecione seu estado</option>
          <option *ngFor="let state of obterTodosEstados()" value={{state}}>{{state}}</option>
        </select>
      </div>

      <div #elementoNaLista *ngFor="let mark of this.filteredMarkers" class="box pt48 pb40 pl32 pr32"
        style="border-bottom: 1px solid var(--gray); ">
        <ng-container *ngIf="mark.shop as item">
          {{ bindarElementoNoMark(mark, elementoNaLista) }}
          <h3 class="cor-primary s3 upc mb16">{{ item.name }}</h3>
          <p class="cor-black s2 mb24" style="line-height: 1.2;">{{ item.street }}, {{ item.streetnumber }} - {{
            item.district }},
            {{ item.city }} - {{ item.state }}, {{ item.zipcode }}</p>
          <div class="flex flex-wrap flex-justify-between" style="gap: 16px;">
            <a href="tel:{{ item.phone }}" class="flex flex-items-center hv-cor-primary cor-black">
              <img style="margin-right: 4px;" height="18px" width="18px" src="../../../../assets/images/icon-phone.svg"
                alt="ícone de um telefone">
              <p>{{ item.phone }}</p>
            </a>
            <a *ngIf="item.latitude && item.longitude"
              href="https://waze.com/ul?ll={{item.latitude}},{{item.longitude}}&navigate=yes" target="_blank"
              class="flex flex-items-center hv-cor-primary cor-black press" (click)="focarPontoNoMapa(mark)">
              <img style="margin-right: 4px;" height="18px" width="18px" src="../../../../assets/images/icon-waze.svg"
                alt="ícone de mapa">
              <p>Ver no Waze</p>
            </a>
            <a *ngIf="item.latitude && item.longitude"
              href="https://www.google.com/maps/dir/?api=1&destination={{item.latitude}},{{item.longitude}}"
              target="_blank" class="flex flex-items-center hv-cor-primary cor-black press"
              (click)="focarPontoNoMapa(mark)">
              <img style="margin-right: 4px;" height="18px" width="18px"
                src="../../../../assets/images/icon-google-maps.svg" alt="ícone de mapa">
              <p>Ver no Google Maps</p>
            </a>
            <div class="flex flex-items-center hv-cor-primary cor-black press" (click)="focarPontoNoMapa(mark)">
              <img style="margin-right: 4px;" height="18px" width="18px"
                src="../../../../assets/images/ico-location-maps.svg" alt="ícone de mapa">
              <p>Ver no mapa</p>
            </div>
          </div>
        </ng-container>
      </div>

    </div>

    <google-map width="100%" height="100%" [options]="options" [center]="center">
      <map-marker-clusterer imagePath="/assets/images/map/m">
        <map-marker *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title"
          [options]="marker.options" (mapClick)="mostrarNaLista(marker)">
        </map-marker>
      </map-marker-clusterer>
    </google-map>
  </div>


</div>