import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { GoogleMap, MapMarkerClusterer } from "@angular/google-maps";
import { StrapiRetorno } from "src/models/strapi_retorno";
import { IBlockShopMap, IShop } from "src/models/zeus";

type mark = {
  shop: IShop;
  position: google.maps.LatLngLiteral;
  title: string;
  options: google.maps.MarkerOptions;
  elementoNaLista?: HTMLElement;
};

@Component({
  selector: "app-block-shop-map",
  templateUrl: "./block-shop-map.component.html",
  styleUrls: ["./block-shop-map.component.scss"],
})
export class BlockShopMapComponent implements AfterViewInit {
  @Input() block!: IBlockShopMap;
  @ViewChild(GoogleMap, { static: false }) map?: GoogleMap;
  @ViewChild(MapMarkerClusterer, { static: false })
  public shops: IShop[] = [];
  center: google.maps.LatLngLiteral = {
    lat: -23.542082214220766,
    lng: -46.23145201622518,
  };
  bboxEstados = [
    {
      uf: "GOIÁS",
      bbox: {
        north: -12.382928,
        south: -19.445874,
        east: -45.944824,
        west: -59.853516,
      },
    },
    {
      uf: "MATO GROSSO",
      bbox: {
        north: -7.362467,
        south: -18.104087,
        east: -49.746094,
        west: -61.567383,
      },
    },
    {
      uf: "MINAS GERAIS",
      bbox: {
        north: -14.221789,
        south: -22.917923,
        east: -39.825439,
        west: -51.075439,
      },
    },
    {
      uf: "PARANÁ",
      bbox: {
        north: -22.532854,
        south: -26.696545,
        east: -48.056946,
        west: -54.591064,
      },
    },
    {
      uf: "PERNAMBUCO",
      bbox: {
        north: -7.269843,
        south: -9.476154,
        east: -34.810181,
        west: -41.358063,
      },
    },
    {
      uf: "RIO GRANDE DO SUL",
      bbox: {
        north: -27.117813,
        south: -33.669497,
        east: -48.80127,
        west: -57.612305,
      },
    },
    {
      uf: "SANTA CATARINA",
      bbox: {
        north: -25.948166,
        south: -29.257649,
        east: -48.405762,
        west: -53.824768,
      },
    },
    {
      uf: "SÃO PAULO",
      bbox: {
        north: -19.880392,
        south: -25.254633,
        east: -44.110107,
        west: -53.10791,
      },
    },
  ];

  public markers: mark[] = [];
  public filteredMarkers: mark[] = [];
  public mapClusterer?: MapMarkerClusterer;

  public options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 100,
    minZoom: 0,
    zoom: 4.5,
  };

  public ngAfterViewInit(): void {
    (this.block.shops as StrapiRetorno<IShop>)?.data.forEach((shop) => {
      const s = { ...shop.attributes, id: shop.id?.toString() };
      this.markers.push({
        shop: s,
        position: { lat: Number(s.latitude), lng: Number(s.longitude) },
        title: s.name + "",
        options: { animation: google.maps.Animation.DROP },
      });
      this.shops = [s];
    });

    var bounds = new google.maps.LatLngBounds();
    this.markers.forEach((m) => {
      bounds.extend(m.position);
    });

    this.filteredMarkers = this.markers.sort(this.sortByShopName);
  }

  public sortByShopName(a: any, b: any) {
    let _a: any;
    let _b: any;
    if (a.shop.name != undefined && b.shop.name != undefined) {
      _a = a.shop["name"];
      _b = b.shop["name"];

      if (_a < _b) {
        return -1;
      }

      if (_a > _b) {
        return 1;
      }
    }
    return 0;
  }

  public focarPontoNoMapa(mark: mark) {
    this.map?.googleMap?.setZoom(10);
    this.map?.googleMap?.setCenter(mark.position);
  }

  public mostrarNaLista(mark: mark) {
    mark.elementoNaLista?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  }

  public bindarElementoNoMark(mark: mark, elemento: HTMLElement) {
    mark.elementoNaLista = elemento;
  }

  public obterTodosEstados() {
    const listaEstados: Array<string | undefined> = [];
    this.markers.forEach((s) => {
      if (listaEstados.indexOf(s.shop.state?.toUpperCase()) == -1) {
        listaEstados.push(s.shop.state?.toUpperCase());
      }
    });
    return listaEstados.sort();
  }

  public filtrarPorEstado(e: Event | null): void {
    const target = e?.target as HTMLInputElement;
    const query = target.value;
    if (query.length == 0) {
      this.filteredMarkers = this.markers;
      this.map?.googleMap?.setZoom(4.5);
    } else {
      const bounds = this.bboxEstados.find((estado) => estado.uf === query);
      if (bounds) {
        this.map?.googleMap?.fitBounds(bounds.bbox, 10);
      }

      this.filteredMarkers = this.markers
        .filter((m) => m.shop.state?.toUpperCase() == query)
        .sort(this.sortByShopName);
    }
  }
}
