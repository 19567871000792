import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from 'src/models/Status';

@Component({
  selector: 'app-submitfeedbackmessage',
  templateUrl: './submitfeedbackmessage.component.html',
  styleUrls: ['./submitfeedbackmessage.component.scss']
})
export class SubmitfeedbackmessageComponent {

  @Input() status?: Status;
  @Input() form?: HTMLFormElement;
  @Output() statusChange = new EventEmitter<Status>();
  @Output() clickBotaoConcluido = new EventEmitter<any>();
  @Output() clickBotaoErro = new EventEmitter<any>();
  @Output() clickBotaoCarregando = new EventEmitter<any>();

  public changeStatus(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.status = undefined;
    this.statusChange.emit(this.status);
  }

}
