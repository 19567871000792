export class InputMasks {
    public static cpfMask(cpf: string | null | undefined): string {
      if (cpf != null) {
        cpf = cpf.toString().replace(/[^0-9]/g, '');
        const formatedCPF = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
          6,
          9
        )}-${cpf.slice(9)}`;
        return formatedCPF;
      } else return (cpf = '');
    }
  
    public static postalCode(postalCode: string | null | undefined): string {
      if (postalCode != null) {
        postalCode = postalCode.replace(/[^0-9]/g, '');
        postalCode.toString;
        const formatedPostalCode = `${postalCode.slice(0, 5)}-${postalCode.slice(5)}`;
        return formatedPostalCode;
      } else return (postalCode = '');
    }
  
    public static rgMask(rg: string | null | undefined): string {
      if (rg != null) {
        rg = rg.toString().replace(/[^0-9Xx]/g, '');
        const formatedRG = `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(
          5,
          8
        )}-${rg.slice(8)}`;
        return formatedRG;
      } else return (rg = '');
    }
  
    public static cellphoneMask(cellphone: string | null | undefined): string {
      if (cellphone != null) {
        cellphone = cellphone.toString().replace(/[^0-9]/g, '');
        const formatedCellphone = `+55 (${cellphone.slice(0, 2)}) ${cellphone.slice(
          2,
          7
        )}-${cellphone.slice(7)}`;
        return formatedCellphone;
      } else return (cellphone = '');
    }

    public static priceMask(price: number | string ): string {
      let limpo = price;

      if(typeof price == "string"){
        limpo = price.replace(/\D/g, '');
      }
      const formatter = new Intl.NumberFormat('pt-BR');
      return formatter.format((+limpo));
    }

    public static kmMask(km : string): string {
      let limpo = km;
      
      if(typeof km == "string"){
        limpo = km.replace(/\D/g, '');
      }
      const formatter = new Intl.NumberFormat('pt-BR');
      return formatter.format((+limpo));
    }
  }