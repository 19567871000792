import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule as fm, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FormContactUsComponent } from "./form-contact-us/form-contact-us.component";
import { FormMakeBudgetLocacaoComponent } from "./form-make-budget-locacao/form-make-budget-locacao.component";
import { FormMakeBudgetPecasEServicosComponent } from "./form-make-budget-pecas-eservicos/form-make-budget-pecas-eservicos.component";
import { FormSupplierRegistryComponent } from "./form-supplier-registry/form-supplier-registry.component";
import { LoadingModule } from "../../common/loading/loading.module";
import { ErrormessageComponent } from "./errormessage/errormessage.component";
import { SubmitfeedbackmessageComponent } from "./submitfeedbackmessage/submitfeedbackmessage.component";
import { BotaoTipo1Module } from "../botao-tipo1/botao-tipo1.module";
import { StrapiiconModule } from "src/app/directives/strapiicon/strapiicon.module";
import { FormSeminovosTenhoInteresseComponent } from "./form-seminovos-tenho-interesse/form-seminovos-tenho-interesse.component";
import { FormContactUsLocacaoComponent } from "./form-contact-us-locacao/form-contact-us-locacao.component";
import { FormContactUsSeminovosComponent } from "./form-contact-us-seminovos/form-contact-us-seminovos.component";
import { FormMakeBudgetConcessionariaComponent } from "./form-make-budget-concessionaria/form-make-budget-concessionaria.component";

@NgModule({
  declarations: [
    FormContactUsComponent,
    FormMakeBudgetLocacaoComponent,
    FormMakeBudgetPecasEServicosComponent,
    FormSupplierRegistryComponent,
    ErrormessageComponent,
    SubmitfeedbackmessageComponent,
    FormSeminovosTenhoInteresseComponent,
    FormContactUsLocacaoComponent,
    FormContactUsSeminovosComponent,
    FormMakeBudgetConcessionariaComponent,
  ],
  exports: [
    FormSeminovosTenhoInteresseComponent,
    FormContactUsComponent,
    FormMakeBudgetLocacaoComponent,
    FormMakeBudgetPecasEServicosComponent,
    FormSupplierRegistryComponent,
    ErrormessageComponent,
    ReactiveFormsModule,
    SubmitfeedbackmessageComponent,
    FormContactUsLocacaoComponent,
    FormContactUsSeminovosComponent,
    FormMakeBudgetConcessionariaComponent,
    fm,
  ],
  imports: [
    StrapiiconModule,
    BotaoTipo1Module,
    fm,
    LoadingModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
  ],
})
export class FormsModule {}
