<div class="pt64 w-100 pb64" [id]="this.block.blockIdentifier">
  <div class="container">
    <app-content [content]="content" *ngIf="this.block.content as content"></app-content>
  </div>

  <div class="container flex flex-wrap flex-justify-between w-100" style="gap: 40px 105px;">
    <div class="flex flex-column colunaEsquerda">
      <div class="grid">
        <ng-container *ngIf="(listaFiltrada$  | async) as items">
          <app-card
            *ngFor="let item of  items | paginate: { itemsPerPage: 13, currentPage: paginacao, id: 'paginador'} "
            [card]="item"></app-card>
        </ng-container>
        <!-- <app-card *ngFor="let card of cards" [card]="card"></app-card> -->
      </div>


      <div class="flex w-100 flex-items-center flex-justify-center mt32">
        <pagination-controls (pageChange)="paginadorMudou($event)" id="paginador" [maxSize]="10" [directionLinks]="true"
          [responsive]="true" previousLabel="{{ 'PAGINACAO.ANTERIOR' | translate }}"
          nextLabel="{{ 'PAGINACAO.PROXIMO' | translate }}"
          screenReaderPaginationLabel="{{ 'PAGINACAO.SCREENREADERPAGINATIONLABEL' | translate }}"
          screenReaderPageLabel="{{ 'PAGINACAO.SCREENREADERPAGELABEL' | translate }}"
          screenReaderCurrentLabel="{{ 'PAGINACAO.SCREENREADERCURRENTLABEL' | translate }}"></pagination-controls>
      </div>
    </div>
    <div class="flex flex-column colunaDireita" style="flex: 1 1 290px; gap: 48px;">
      <div class="flex flex-column">
        <p class="cor-gray upc pb16 s4 fw500" style="border-bottom: 1px solid var(--primary);">
          {{ 'PAGE.BLOGLIST.CATEGORIA' | translate }}
        </p>
        <a class="pt16 pb16 fw300 cor-gray hv-cor-secondary s2" *ngFor="let item of ((categories$ | async))"
          routerLink="/blog" [queryParams]="{ categoria: item.link}" routerLinkActive="router-link-active"
          style="text-transform: capitalize; border-bottom: 1px solid var(--silver);">
          {{ item.nome }} ({{item.quantidade}})</a>
      </div>

      <div class="flex flex-column">
        <p class="cor-gray upc pb16 s4 fw500" style="border-bottom: 1px solid var(--primary);">
          {{ 'PAGE.BLOGLIST.POSTSRECENTES' | translate }}
        </p>

        <a class="pt16 pb16 fw300 cor-gray hv-cor-secondary s2" *ngFor="let item of ((latestPosts$ | async))"
          routerLink="/blog/{{item.attributes.slug}}" routerLinkActive="router-link-active"
          style="text-transform: capitalize; border-bottom: 1px solid var(--silver);">
          {{ item.attributes.h1 }}
        </a>
      </div>

      <div class="flex flex-column">
        <p class="cor-gray upc pb16 s4 fw500" style="border-bottom: 1px solid var(--primary);">
          {{ 'PAGE.BLOGLIST.ARQUIVO' | translate }}
        </p>

        <a class="pt16 pb16 fw300 cor-gray hv-cor-secondary s2" *ngFor="let item of ((archive$ | async))"
          routerLink="/blog" [queryParams]="{ mes: item.link}" routerLinkActive="router-link-active"
          style="text-transform: capitalize; border-bottom: 1px solid var(--silver);">
          {{ item.dataISO | date:'MMMM/yyyy':'+0000':tradutorService.locale }} ({{item.items.length}})</a>
      </div>
    </div>
  </div>
</div>