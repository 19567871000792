<div class="container flex flex-column pt64" style="position:relative;" [id]="this.block.blockIdentifier">
  <app-content [content]="content" *ngIf="this.block.content as content"></app-content>
</div>

<ng-container *ngIf="!isMobile">
  <div class="pt48 pb32 w-100 ova container">
    <table class="mt32" cellPadding="0" cellSpacing="0">
      <tr>
        <th></th>
        <th class="bg-silver pa32" style="position: relative;" *ngFor="let plano of this.planos">
          <p *ngIf="plano.label" class="label pa16 pt8 pb8 bg-secondary cor-white w-100 text-center upc fw400">
            {{plano.label}}
          </p>
          <p class="fw600 mb32 s5">{{ plano.name }}</p>
          <p class="fw400 mb32 s2">{{ plano.description }}</p>
          <app-botao-tipo1 *ngIf="plano.link as link" style="text-align: center;" class="redondo" cor2="var(--primary)"
            cor1="var(--white)" [strapilink]="link">
          </app-botao-tipo1>
        </th>
      </tr>

      <tr *ngFor="let beneficio of this.planosBeneficios">
        <!-- <td>{{ type.value }} <img src="/assets/images/information-outline.svg"></td> -->
        <td class="pl32 pr32 pt16 pb16 s2">
          <div class="flex flex-items-center">
            {{beneficio.name}}
            <img class="hv-bg-silver ml8" height="24px" width="24px" [tooltip]="beneficio.description"
              style="padding: 4px;" src="../../../../assets/images/icon-info.svg" [alt]="beneficio.description">
          </div>
        </td>
        <td class="pt16 pb16" style="text-align: center;" *ngFor="let plano of planos">
          <img *ngIf="planoPossuiBeneficioLocacao(plano,beneficio)" src="../../../../assets/images/icon-check.svg"
            alt="Planos para locação de caminhão">
        </td>
      </tr>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="isMobile">
  <div class="pt48 pb32 w-100 ova container">
    <table class="mt32" cellPadding="0" cellSpacing="0">
      <tr>
        <th class="bg-silver pa32" style="position: relative;">
          <p *ngIf="planos![0].label" class="label pa16 pt8 pb8 bg-secondary cor-white w-100 text-center upc fw400">
            {{planos![0].label}}
          </p>
          <p class="fw600 mb32 s5">{{ planos![0].name }}</p>
          <p class="fw400 mb32 s2">{{ planos![0].description }}</p>
          <app-botao-tipo1 *ngIf="planos![0].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </th>
      </tr>
      <ng-container *ngFor="let beneficio of this.planosBeneficios">
        <!-- <td>{{ type.value }} <img src="/assets/images/information-outline.svg"></td> -->
        <tr class="s2" *ngIf="planoPossuiBeneficioLocacao(planos![0],beneficio)">
          <div class="lineContainer bgSilver">
            {{beneficio.name}}
            <img class="hv-bg-silver ml8" height="24px" width="24px" [tooltip]="beneficio.description"
              style="padding: 4px;" src="../../../../assets/images/icon-info.svg" [alt]="beneficio.description">
          </div>
        </tr>
        <tr *ngIf="planoPossuiBeneficioLocacao(planos![0],beneficio)">
          <div class="lineContainer bgLightsilver">
            <img *ngIf="planoPossuiBeneficioLocacao(planos![0],beneficio)"
              src="../../../../assets/images/icon-check.svg" alt="Planos para locação de frota">
          </div>
        </tr>
      </ng-container>
      <tr class="bgSilver">
        <div class="ctaBtn">
          <app-botao-tipo1 *ngIf="planos![0].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </div>
      </tr>
    </table>
  </div>
  <div class="pt48 pb32 w-100 ova container">
    <table class="mt32" cellPadding="0" cellSpacing="0">
      <tr>
        <th class="bg-silver pa32" style="position: relative;">
          <p *ngIf="planos![1].label" class="label pa16 pt8 pb8 bg-secondary cor-white w-100 text-center upc fw400">
            {{planos![1].label}}
          </p>
          <p class="fw600 mb32 s5">{{ planos![1].name }}</p>
          <p class="fw400 mb32 s2">{{ planos![1].description }}</p>
          <app-botao-tipo1 *ngIf="planos![1].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </th>
      </tr>
      <ng-container *ngFor="let beneficio of this.planosBeneficios">
        <!-- <td>{{ type.value }} <img src="/assets/images/information-outline.svg"></td> -->
        <tr class="s2" *ngIf="planoPossuiBeneficioLocacao(planos![1],beneficio)">
          <div class="lineContainer bgSilver">
            {{beneficio.name}}
            <img class="hv-bg-silver ml8" height="24px" width="24px" [tooltip]="beneficio.description"
              style="padding: 4px;" src="../../../../assets/images/icon-info.svg" [alt]="beneficio.description">
          </div>
        </tr>
        <tr *ngIf="planoPossuiBeneficioLocacao(planos![1],beneficio)">
          <div class="lineContainer bgLightsilver">
            <img *ngIf="planoPossuiBeneficioLocacao(planos![1],beneficio)"
              src="../../../../assets/images/icon-check.svg" alt="Planos para locação de frota">
          </div>
        </tr>
      </ng-container>
      <tr class="bgSilver">
        <div class="ctaBtn">
          <app-botao-tipo1 *ngIf="planos![1].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </div>
      </tr>
    </table>
  </div>
  <div class="pt48 pb32 w-100 ova container">
    <table class="mt32" cellPadding="0" cellSpacing="0">
      <tr>
        <th class="bg-silver pa32" style="position: relative;">
          <p *ngIf="planos![2].label" class="label pa16 pt8 pb8 bg-secondary cor-white w-100 text-center upc fw400">
            {{planos![2].label}}
          </p>
          <p class="fw600 mb32 s5">{{ planos![2].name }}</p>
          <p class="fw400 mb32 s2">{{ planos![2].description }}</p>
          <app-botao-tipo1 *ngIf="planos![2].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </th>
      </tr>
      <ng-container *ngFor="let beneficio of this.planosBeneficios">
        <!-- <td>{{ type.value }} <img src="/assets/images/information-outline.svg"></td> -->
        <tr class="s2" *ngIf="planoPossuiBeneficioLocacao(planos![2],beneficio)">
          <div class="lineContainer bgSilver">
            {{beneficio.name}}
            <img class="hv-bg-silver ml8" height="24px" width="24px" [tooltip]="beneficio.description"
              style="padding: 4px;" src="../../../../assets/images/icon-info.svg" [alt]="beneficio.description">
          </div>
        </tr>
        <tr *ngIf="planoPossuiBeneficioLocacao(planos![2],beneficio)">
          <div class="lineContainer bgLightsilver">
            <img *ngIf="planoPossuiBeneficioLocacao(planos![2],beneficio)"
              src="../../../../assets/images/icon-check.svg" alt="Planos para locação de frota">
          </div>
        </tr>
      </ng-container>
      <tr class="bgSilver">
        <div class="ctaBtn">
          <app-botao-tipo1 *ngIf="planos![2].link as link" class="redondo" cor2="var(--primary)" cor1="var(--white)"
            [strapilink]="link">
          </app-botao-tipo1>
        </div>
      </tr>
    </table>
  </div>
</ng-container>