import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay, switchMap } from 'rxjs';
import { HttpStateService } from 'src/app/services/http.state.service';
import { TradutorService } from 'src/app/services/tradutor.service';
import { environment } from 'src/environments/environment';
import { StrapiRetorno } from 'src/models/strapi_retorno';
import { ICtaFlutuante } from 'src/models/zeus';

@Component({
  selector: 'app-flutuante',
  templateUrl: './flutuante.component.html',
  styleUrls: ['./flutuante.component.scss'],
})
export class FlutuanteComponent {
  constructor(
    private httpStateService: HttpStateService,
    private tradutorService: TradutorService,
    private router: Router
  ) {}

  public ctaData$ = this.tradutorService.locale$
    .pipe(
      switchMap((idioma) => {
        const url =
          environment.API_URL +
          environment.API_PATH_CTAFLUTUANTE +
          '?populate=deep&locale=' +
          idioma;
        return this.httpStateService.get<StrapiRetorno<ICtaFlutuante>>(url);
      })
    )
    .pipe(
      shareReplay(),
      map((x) =>
        x.data.sort((a, b) => b.attributes.url.length - a.attributes.url.length)
      )
    );

  public actualCta$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((navigationEnd: NavigationEnd) => {
      return navigationEnd.urlAfterRedirects;
    }),
    switchMap((url) => {
      return this.ctaData$.pipe(map((lista) => {
          return lista.filter((x) => url.startsWith(x.attributes.url))[0];
        })
      );
    })
  );

}
