<div *ngIf="shop as s" class="h-100 bg-white flex flex-column ">
  <img *ngIf="s.image?.data as img" [appStrapiimage]="img" class="w-100"
    style="aspect-ratio: 4 / 3; object-fit: cover;">
  <div class="flex flex-column pa32" style="gap: 32px; flex: 1;">
    <p class="s3 fw500 w-100 mtauto">{{s.name}}</p>
    <address class="fw400 s2">
      <ng-container *ngIf="s.street && s.streetnumber">{{ s.street + ' ' + s.streetnumber }}<br></ng-container>
      <ng-container *ngIf="s.district">{{ s.district }}<br></ng-container>
      <ng-container *ngIf="s.city && s.state">{{ s.city +' - '+ s.state }}<br></ng-container>
    </address>

    <div *ngIf="s as item" class="flex flex-column " style="gap: 16px;">

      <a *ngIf="item.latitude && item.longitude"
        href="https://waze.com/ul?ll={{item.latitude}},{{item.longitude}}&navigate=yes" target="_blank"
        class="flex flex-items-center hv-cor-primary cor-black press link-externo hv-udl">
        <img style="margin-right: 4px;" height="18px" width="18px" src="../../../../assets/images/icon-waze.svg"
          alt="ícone de mapa">
        <p>Ver no Waze</p>
      </a>
      <a *ngIf="item.latitude && item.longitude"
        href="https://www.google.com/maps/dir/?api=1&destination={{item.latitude}},{{item.longitude}}" target="_blank"
        class="flex flex-items-center hv-cor-primary cor-black press link-externo hv-udl">
        <img style="margin-right: 4px;" height="18px" width="18px" src="../../../../assets/images/icon-google-maps.svg"
          alt="ícone de mapa">
        <p>Ver no Google Maps</p>
      </a>
    </div>

    <a href="tel:{{ s.phone }}" class="flex flex-justify-center hv-udl flex-items-center cor-black text-center w-100"
      title="Ligar agora" *ngIf="s.phone">
      <img class="mr8" height="28px" width="28px" src="../../../../assets/images/icon-phone.svg"
        alt="ícone de um telefone">
      <p class="s8 fw600">{{ s.phone }}</p>
    </a>

    <!-- <app-botao-tipo1 class="redondo text-center" label="Ver produtos dessa loja"
      href="/seminovos/estoque-completo?vendedor={{ getStoreName(s.name) }}" *ngIf="!hideButton"></app-botao-tipo1> -->
  </div>
</div>