import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { IBanner } from 'src/models/zeus/item/banner';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() public banner!: IBanner;

  public isMobileLayout = false;
  public isTabletLayout = false;
  public isDesktopLayout = false;
  
  constructor(@Inject(DOCUMENT) private document: Document){}

  public ngOnInit(): void {

    if (this.document.body.clientWidth <= 767) {
      this.isMobileLayout = true;
    } else

      if (this.document.body.clientWidth >= 768 && this.document.body.clientWidth <= 991) {
        this.isTabletLayout = true;
      } else {
        this.isDesktopLayout = true;
      }
  }


}
