<div class="container pt64 pb64" [id]="this.block.blockIdentifier">
  <div class="grid" style="gap: 24px;">
    <div class="box">
      <div>
        <img src="../../../../assets/images/bg-placeholder.jpg" alt="" width="100%" class="bg">
      </div>
      <div class="flex flex-column flex-items-start pa32 bg-white" style="flex: 1;">
        <div class="w-100 mbauto">
          <h3 class="s4 cor-black ejs-header mb32">City (state)</h3>
          <p class="cor-black s2 ejs-paragraph">Address line 1</p>
          <p class="cor-black s2 ejs-paragraph">Address line 2</p>
          <p class="cor-black s2 ejs-paragraph">Address line 3</p>

          <div class="flex flex-items-center w-100 mt32 mb32">
            <img class="mr8" width="16px" height="16px" src="../../../../assets/images/ico-location-maps.svg" alt="">
            <a class="cor-black hv-cor-primary s2 ejs-paragraph udl" href="">Ver no Google Maps</a>
          </div>

          <a class="cor-black hv-cor-primary s10 fw500" href="tel:(19) 3280-1055">(19) 3280-1055</a>
        </div>
        <a class="botao bg-primary hv-bg-secondary s4 cor-white text-center mt32 pt16 pb16 pl24 pr24 brfull w-100"
          href="">Ver todos produtos</a>
      </div>
    </div>
    <div class="box">
      <div>
        <img src="../../../../assets/images/bg-placeholder.jpg" alt="" width="100%" class="bg">
      </div>
      <div class="flex flex-column flex-items-start pa32 bg-white" style="flex: 1;">
        <div class="w-100 mbauto">
          <h3 class="s4 cor-black ejs-header mb32">City (state)</h3>
          <p class="cor-black s2 ejs-paragraph">Address line 1</p>
          <p class="cor-black s2 ejs-paragraph">Address line 2</p>
          <p class="cor-black s2 ejs-paragraph">Address line 3</p>

          <div class="flex flex-items-center w-100 mt32 mb32">
            <img class="mr8" width="16px" height="16px" src="../../../../assets/images/ico-location-maps.svg" alt="">
            <a class="cor-black hv-cor-primary s2 ejs-paragraph udl" href="">Ver no Google Maps</a>
          </div>

          <a class="cor-black hv-cor-primary s10 fw500" href="tel:(19) 3280-1055">(19) 3280-1055</a>
        </div>
        <a class="botao bg-primary hv-bg-secondary s4 cor-white text-center mt32 pt16 pb16 pl24 pr24 brfull w-100"
          href="">Ver todos produtos</a>
      </div>
    </div>
    <div class="box">
      <div>
        <img src="../../../../assets/images/bg-placeholder.jpg" alt="" width="100%" class="bg">
      </div>
      <div class="flex flex-column flex-items-start pa32 bg-white" style="flex: 1;">
        <div class="w-100 mbauto">
          <h3 class="s4 cor-black ejs-header mb32">City (state)</h3>
          <p class="cor-black s2 ejs-paragraph">Address line 1</p>
          <p class="cor-black s2 ejs-paragraph">Address line 2</p>
          <p class="cor-black s2 ejs-paragraph">Address line 3</p>

          <div class="flex flex-items-center w-100 mt32 mb32">
            <img class="mr8" width="16px" height="16px" src="../../../../assets/images/ico-location-maps.svg" alt="">
            <a class="cor-black hv-cor-primary s2 ejs-paragraph udl" href="">Ver no Google Maps</a>
          </div>

          <a class="cor-black hv-cor-primary s10 fw500" href="tel:(19) 3280-1055">(19) 3280-1055</a>
        </div>
        <a class="botao bg-primary hv-bg-secondary s4 cor-white text-center mt32 pt16 pb16 pl24 pr24 brfull w-100"
          href="">Ver todos produtos</a>
      </div>
    </div>
    <div class="box">
      <div>
        <img src="../../../../assets/images/bg-placeholder.jpg" alt="" width="100%" class="bg">
      </div>
      <div class="flex flex-column flex-items-start pa32 bg-white" style="flex: 1;">
        <div class="w-100 mbauto">
          <h3 class="s4 cor-black ejs-header mb32">City (state)</h3>
          <p class="cor-black s2 ejs-paragraph">Address line 1</p>
          <p class="cor-black s2 ejs-paragraph">Address line 2</p>
          <p class="cor-black s2 ejs-paragraph">Address line 3</p>

          <div class="flex flex-items-center w-100 mt32 mb32">
            <img class="mr8" width="16px" height="16px" src="../../../../assets/images/ico-location-maps.svg" alt="">
            <a class="cor-black hv-cor-primary s2 ejs-paragraph udl" href="">Ver no Google Maps</a>
          </div>

          <a class="cor-black hv-cor-primary s10 fw500" href="tel:(19) 3280-1055">(19) 3280-1055</a>
        </div>
        <a class="botao bg-primary hv-bg-secondary s4 cor-white text-center mt32 pt16 pb16 pl24 pr24 brfull w-100"
          href="">Ver todos produtos</a>
      </div>
    </div>
    <div class="box">
      <div>
        <img src="../../../../assets/images/bg-placeholder.jpg" alt="" width="100%" class="bg">
      </div>
      <div class="flex flex-column flex-items-start pa32 bg-white" style="flex: 1;">
        <div class="w-100 mbauto">
          <h3 class="s4 cor-black ejs-header mb32">City (state)</h3>
          <p class="cor-black s2 ejs-paragraph">Address line 1</p>
          <p class="cor-black s2 ejs-paragraph">Address line 2</p>
          <p class="cor-black s2 ejs-paragraph">Address line 3</p>

          <div class="flex flex-items-center w-100 mt32 mb32">
            <img class="mr8" width="16px" height="16px" src="../../../../assets/images/ico-location-maps.svg" alt="">
            <a class="cor-black hv-cor-primary s2 ejs-paragraph udl" href="">Ver no Google Maps</a>
          </div>

          <a class="cor-black hv-cor-primary s10 fw500" href="tel:(19) 3280-1055">(19) 3280-1055</a>
        </div>
        <a class="botao bg-primary hv-bg-secondary s4 cor-white text-center mt32 pt16 pb16 pl24 pr24 brfull w-100"
          href="">Ver todos produtos</a>
      </div>
    </div>
  </div>
</div>