import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AfterIfDirective } from './after-if.directive';



@NgModule({
  declarations: [
    AfterIfDirective,
  ],
  exports: [
    AfterIfDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class AfterIfModule { }
