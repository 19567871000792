import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, Observable, shareReplay, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
import { IPost } from 'src/models/IPost';
import { Attributes, Basic, StrapiRetorno } from 'src/models/strapi_retorno';
import { ICard } from 'src/models/zeus';
import { HttpStateService } from './http.state.service';
import { TradutorService } from './tradutor.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  getFilteredList$ = this.activatedRoute.queryParams.pipe(map(params => {
    const categoria = params['categoria'];
    const mes = params['mes'];
    const r = [];
    if (mes) {
      const mesObj = moment(mes);
      const proxMesObj = moment(mesObj).add(1, 'M');
      r.push('filters[updatedAt][$gte]=' + mesObj.format('YYYY-MM'))
      r.push('filters[updatedAt][$lte]=' + proxMesObj.format('YYYY-MM'))
    }
    if (categoria) {
      r.push(`filters[categoria][name][$contains]=` + categoria)
    }
    // filters[updatedAt][$gte]=2021-09-01&filters[updatedAt][$lte]=2022-09-01
    // filters\[categoria][name][$contains]=Agronegócio
    const reqParams = r.join('&');
    return reqParams;
  }), mergeMap(reqParams => {
    return this.tradutorService.locale$.pipe(mergeMap(lang => {
      // tentamos pegar a list no transfer-state
      return this.httpStateService.get<StrapiRetorno<IPost>>(environment.API_URL + environment.API_PATH_BLOG + `?locale=${lang}&sort[0]=publishedAt:desc&populate=categoria,mainImage&` + reqParams)
    }), shareReplay());
  }), map(lista => {
    //conversor de IPost para ICard
    const listaConvertida = lista.data.map(x => this.converterIPostParaICard(x));
    return listaConvertida;
  }));

  getList$ = this.tradutorService.locale$.pipe(mergeMap(lang => {
    // tentamos pegar a list no transfer-state
    return this.httpStateService.get<StrapiRetorno<IPost>>(environment.API_URL + environment.API_PATH_BLOG + `?locale=${lang}&populate=categoria,mainImage&sort[0]=publishedAt:desc`)
  }), shareReplay());
  postSalvo: { [key: string]: Observable<StrapiRetorno<IPost>> } = {};

  constructor(
    private tradutorService: TradutorService,
    private httpStateService: HttpStateService,
    // private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  byPath(path: string) {
    path = path.replace(/^\//gi, '');

    const postSalvo = this.postSalvo[path];
    if (!postSalvo) {
      this.postSalvo[path] = this.tradutorService.locale$.pipe(mergeMap(lang => {
        // tentamos pegar o page no transfer-state
        return this.httpStateService.get<StrapiRetorno<IPost>>(environment.API_URL + environment.API_PATH_BLOG + `?filter[slug][eq]=${path}&populate=deep`)

      }), shareReplay());
    }
    // re-disparamos a cadeia de requisição ao detectar mudança de idioma
    return this.postSalvo[path];
  }


converterIPostParaICard(post: Attributes<IPost & Basic>): ICard {
  return {
    id: post.attributes.id,
    content: `
    {
      "blocks": [
          {
              "type": "header",
              "data": {
                  "level": 1,
                  "text": "${post.attributes.h1}"
              }
          },
          {
              "type": "paragraph",
              "data": {
                  "text": "${post.attributes.subTitle}"
              }
          }
      ]
  }
  `,
    datetime: post.attributes.createdAt,
    images: { data: [post.attributes.mainImage?.data] },
    link: [{
      href: '/blog/' + post.attributes.slug,
      download: false,
      hreflang: post.attributes.locale as any,
      id: post.attributes.id,
      referrerpolicy: 'same-origin',
      target: '_self',
      title: post.attributes.seo?.metaTitle ?? post.attributes.h1,
      label: 'Ver'
    }]
  }
}
}
