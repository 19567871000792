import { DOCUMENT, Location } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ILink } from 'src/models/zeus';

@Directive({
  selector: 'a[appStrapilink]'
})
export class StrapilinkDirective implements AfterViewInit, OnChanges {

  public isExternalLink?: boolean;
  @Input() aplicarClassesDeLink = false;
  @Input() appStrapilink?: Partial<ILink>;
  @HostBinding() queryParams?: {};


  private readonly linkexterno = [
    'tel:',
    'http://',
    'https://',
    'mailto:'
  ]
  constructor(
    private elementRef: ElementRef<HTMLAnchorElement>,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }


  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['appStrapilink']){
      this.ngAfterViewInit();
    }
  }

  public ngAfterViewInit(): void {
    if (this.appStrapilink?.href != undefined) {

      // substituição de chaves
      const chaveUrl = [
        {chave: '{{API_URL}}', valor: environment.API_URL}
      ];
      chaveUrl.forEach(c => {
        if(this.appStrapilink)
          this.appStrapilink.href = this.appStrapilink.href?.replace(c.chave, c.valor);
      });

      const linkAndQuery = this.appStrapilink.href.split('?');
      const path = linkAndQuery[0].replace(/\/$/gi, ''); // remove a barra no final
      const query = linkAndQuery[1];
      this.elementRef.nativeElement.href = query ? path + '?' + query : path;
      if (query) this.queryParams = new URLSearchParams(query);
      this.appStrapilink.download && (this.elementRef.nativeElement.download = '');
      this.elementRef.nativeElement.hreflang = this.appStrapilink.hreflang ?? '';
      this.elementRef.nativeElement.title = this.appStrapilink.title ?? '';
      this.elementRef.nativeElement.target = this.appStrapilink.target ?? '_self';
      this.elementRef.nativeElement.rel = this.appStrapilink.rel ?? '';
      this.elementRef.nativeElement.referrerPolicy = this.appStrapilink.referrerpolicy ?? '';
    }
    if (this.elementRef.nativeElement.href != '') {

      const hrefNoElemento = new URL(this.elementRef.nativeElement.href);
      const urlAtual = new URL(this.document.location.href);
      this.isExternalLink = hrefNoElemento.host != urlAtual.host;
      this.elementRef.nativeElement.target = this.isExternalLink ? '_blank' : '_self';
      if (hrefNoElemento.host == urlAtual.host && hrefNoElemento.hash && hrefNoElemento.pathname == '/') {
        urlAtual.hash = hrefNoElemento.hash;
        this.elementRef.nativeElement.href = urlAtual.href;
      }
    }
    this.aplicarClasses();
  }

  @HostListener('click', ['$event']) private click(e: MouseEvent) {
    e.preventDefault();
    if (this.isExternalLink) {
      this.document.defaultView?.open(this.elementRef.nativeElement.href, this.elementRef.nativeElement.target)
    } else {
      const url = new URL(this.elementRef.nativeElement.href); 
      const path = url.href.split(url.host)[1];
      this.router.navigateByUrl(path);
    }
  }

  public aplicarClasses() {
    if(this.aplicarClassesDeLink) {
      this.elementRef.nativeElement.classList.remove('link-externo');
      this.elementRef.nativeElement.classList.remove('link-interno');
      if(this.isExternalLink === true) {
        this.elementRef.nativeElement.classList.add('link-externo');
      }
      if(this.isExternalLink === false) {
        this.elementRef.nativeElement.classList.add('link-interno');
      }
    }
  }


}
