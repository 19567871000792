import { Component, Input } from '@angular/core';
import { IYoutubeVideo } from 'src/models/zeus';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class YoutubeVideoComponent {

  @Input() public youtubeVideo!: IYoutubeVideo;


}
