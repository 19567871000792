<div class="container flex flex-column pt64 pb64" *ngIf="!!this.modeloDetalhe">
  <div class="header">
    <div>
      <h1 class="">{{ this.modeloDetalhe.modelo }}</h1>
      <div class="info">
        <span>Marca: {{ this.modeloDetalhe.marca }}</span>
        <span>Eixo: {{ this.veiculos[0].eixo }}</span>
      </div>
    </div>

    <app-botao-tipo1
      icon="icon-whatsapp-white.svg"
      [href]="this.whatsAppLink"
      alt="Ir para o WhatsApp"
      label="Alugue agora"
    ></app-botao-tipo1>
  </div>

  <div class="images">
    <div class="large-image">
      <img [src]="images[0].url" [alt]="images[0].alt" />
    </div>
    <div class="small-images" *ngIf="images.length > 1">
      <img [src]="images[1].url" [alt]="images[1].alt" />
      <img [src]="images[2].url" [alt]="images[2].alt" />
    </div>
  </div>

  <div class="content">
    <div class="header">
      <h2>UNIDADES DISPONÍVEIS</h2>
    </div>

    <ul class="item_container">
      <li
        *ngFor="
          let veiculo of veiculos
            | paginate
              : {
                  id: 'paginador',
                  itemsPerPage: 8,
                  currentPage: paginacao.paginaAtual,
                  totalItems: paginacao.itensTotais
                }
        "
        class="item"
      >
        <div style="position: relative">
          <img
            class="vehicle-img"
            [src]="veiculo.imagens.split(',')[0]"
            widthImg="505"
          />
          <img
            class="sn-selo"
            src="../../../../assets/images/sn-selo.png"
            *ngIf="veiculo.anoFabricacao >= 2022 && veiculo.km < 150000"
            [tooltip]="
              'Veículos fabricados a partir de 2022 com até 150.000 km rodados'
            "
          />
        </div>

        <div class="info">
          <div class="flex flex-justify-between">
            <div class="flex flex-column" style="gap: 12px">
              <span
                style="color: var(--primary); font-size: 20px; font-weight: 700"
                >{{ veiculo.modelo }}</span
              >
              <span style="color: #414042; font-size: 18px; font-weight: 500">{{
                veiculo.marca
              }}</span>
            </div>
            <app-botao-tipo1
              [href]="veiculo.whatsAppLink"
              alt="Ir para o WhatsApp"
              icon="icon-whatsapp-white.svg"
              label="Alugue agora"
            ></app-botao-tipo1>
          </div>

          <ul class="flex flex-column" style="gap: 12px">
            <li class="flex flex-items-center" style="gap: 4px">
              <img
                height="24px"
                width="24px"
                icon="ico-location.svg"
                [appStrapiicon]
                style="background-color: #c4161c; height: 24px"
              />
              <span style="font-size: 16px; color: #414042; font-weight: 700"
                >Local:</span
              >
              <span style="font-size: 16px; color: #414042; font-weight: 400">{{
                veiculo.filial_nome
              }}</span>
            </li>
            <li class="flex flex-items-center" style="gap: 4px">
              <img
                height="24px"
                width="24px"
                icon="ico-km.svg"
                [appStrapiicon]
                style="background-color: #c4161c; height: 24px"
              />
              <span style="font-size: 16px; color: #414042; font-weight: 700"
                >Quilometragem:</span
              >
              <span style="font-size: 16px; color: #414042; font-weight: 400"
                >{{ veiculo.km.toLocaleString("pt-br") }}km</span
              >
            </li>
            <li class="flex flex-items-center" style="gap: 4px">
              <img
                height="24px"
                width="24px"
                icon="ico-calendar.svg"
                [appStrapiicon]
                style="background-color: #c4161c; height: 24px"
              />
              <span style="font-size: 16px; color: #414042; font-weight: 700"
                >Ano de fabricação:</span
              >
              <span style="font-size: 16px; color: #414042; font-weight: 400">{{
                veiculo.ano
              }}</span>
            </li>
          </ul>

          <div class="flex flex-column">
            <span style="color: #414042; font-size: 16px">A partir de</span>
            <div>
              <span style="color: #414042; font-size: 32px; font-weight: 700">{{
                this.currencyValue(veiculo.precoMin)
              }}</span>
              <span style="color: #414042; font-size: 16px">/mês</span>
            </div>
            <span style="color: #414042; font-size: 16px; margin-top: 8px">
              Plano de {{ veiculo.minContrato }} / franquia mensal de
              {{ veiculo.kmFranquia | number : "1.0-0" : "pt-BR" }}km
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div
    *ngIf="this.veiculos.length >= 1"
    class="flex w-100 flex-items-center flex-justify-center mt32"
  >
    <pagination-controls
      id="paginador"
      [maxSize]="8"
      [responsive]="true"
      (pageChange)="pageChanged($event)"
      previousLabel="{{ 'PAGINACAO.ANTERIOR' | translate }}"
      nextLabel="{{ 'PAGINACAO.PROXIMO' | translate }}"
      screenReaderPaginationLabel="{{
        'PAGINACAO.SCREENREADERPAGINATIONLABEL' | translate
      }}"
      screenReaderPageLabel="{{
        'PAGINACAO.SCREENREADERPAGELABEL' | translate
      }}"
      screenReaderCurrentLabel="{{
        'PAGINACAO.SCREENREADERCURRENTLABEL' | translate
      }}"
    >
    </pagination-controls>
  </div>
</div>

<div class="fake" *ngIf="!this.modeloDetalhe">
  <div class="carregando-page">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
