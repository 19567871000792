import { Component, Input } from '@angular/core';
import { IShop } from 'src/models/zeus';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss']
})
export class ShopCardComponent {

  @Input() shop?: IShop;
  @Input() hideButton?: boolean;

 
  public getStoreName(nomeCompleto: string | undefined): string | undefined {
    const nomeSemParenteses = nomeCompleto?.replace(/\s\(.*?\)/, '');
    return nomeSemParenteses?.toLowerCase();
  }

}
