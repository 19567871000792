<div
  class="flex flex-column w-100 h-100"
  style="position: relative; max-width: 463px"
>
  <div
    class="flex flex-column estilo1"
    style="
      width: 100%;
      aspect-ratio: 290 / 200;
      position: relative;
      overflow: hidden;
    "
  >
    <div
      *ngIf="!slideAtivo"
      class="b"
      style="display: block; height: 100%; width: 100%; position: relative"
    >
      <a [href]="this.linkParaDetalhe">
        <img
          [src]="this.images[0].url"
          widthImg="298"
          style="
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
          "
        />
      </a>
    </div>
  </div>

  <div
    class="flex flex-column flex-items-start pa32 w-100 bg-white"
    style="flex: 1"
  >
    <a [href]="this.linkParaDetalhe">
      <h2 class="ejs-header s4 ejs-h2" style="color: #c4161c">
        {{ this.card.modelo }}
      </h2>
    </a>

    <p
      class="ejs-paragraph cor-black s4 fw500 upc mbauto"
      style="margin-top: 12px"
    >
      {{ this.card.marca }}
    </p>

    <div class="line w-100 mt8 mb16 pt8 pb16">
      <div class="flex flex-wrap flex-column w-100" style="gap: 8px 16px">
        <div class="flex flex-items-center" style="gap: 4px">
          <img
            height="24px"
            width="24px"
            icon="ico-km.svg"
            [appStrapiicon]
            style="background-color: #c4161c"
          />
          <p style="color: #414042">
            {{ this.card.kmMin.toLocaleString("pt-br") }} km
            <span style="color: #a7a9ac">a</span>
            {{ this.card.kmMax.toLocaleString("pt-br") }} km
          </p>
        </div>

        <div class="flex flex-items-center" style="gap: 4px">
          <img
            height="24px"
            width="24px"
            icon="ico-data.svg"
            [appStrapiicon]
            style="background-color: #c4161c"
          />
          <p style="color: #414042">
            {{ this.card.anoMin }} <span style="color: #a7a9ac">a</span>
            {{ this.card.anoMax }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-column" style="color: #414042; gap: 4px">
      <span>A partir de</span>
      <div>
        <strong class="cor-black s10 fw600 mtauto">{{
          this.currencyValue(this.card.precoMin)
        }}</strong>
        <span>/mês</span>
      </div>
    </div>

    <app-botao-tipo1
      [href]="this.whatsAppLink"
      class="whitespace-nowrap"
      style="margin-top: 16px"
      alt="Alugue o modelo {{ this.card.marca }} {{ this.card.modelo }}"
      icon="icon-whatsapp-white.svg"
      label="ALUGUE ESTE MODELO"
    >
    </app-botao-tipo1>
  </div>
</div>
