import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { StrapiimageModule } from 'src/app/directives/strapiimage/strapiimage.module';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';
import { EditorjsparsehtmlModule } from 'src/app/directives/editorjsparsehtml/editorjsparsehtml.module';
import { RouterModule } from '@angular/router';
import { StrapiiconModule } from 'src/app/directives/strapiicon/strapiicon.module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { CapitalizePipe } from 'src/app/pipes/capitalize/capitalize.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    CapitalizePipe
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    NgProgressModule,
    NgProgressHttpModule,
    RouterModule,
    CommonModule,
    StrapiimageModule,
    StrapiiconModule,
    StrapilinkModule,
    EditorjsparsehtmlModule
  ]
})
export class HeaderModule { }
