import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import sortByOrdem from 'src/app/helpers/sort/sortByOrdem';
import { IBlockBadge } from 'src/models/zeus';

@Component({
  selector: 'app-block-badge',
  templateUrl: './block-badge.component.html',
  styleUrls: ['./block-badge.component.scss']
})
export class BlockBadgeComponent implements OnInit, OnDestroy {
  public marginFundo = new BehaviorSubject<number>(240);
  public subscription!: Subscription;
  public heightFundo: number = 0;

  @Input() block!: IBlockBadge;
  @ViewChild('teste', {static: true}) teste?: ElementRef<HTMLDivElement>;

  constructor(
    public elementRef: ElementRef<HTMLElement>
    ) { }

  public ngOnInit(): void {
    this.sortAllBadgesByOrder();
   this.subscription = this.marginFundo.subscribe(tamanho => {
      this.calculateHeight(tamanho);
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public calculateHeight(margin: number){
    this.heightFundo = this.elementRef.nativeElement.parentElement!.offsetHeight - (margin) - (this.teste?.nativeElement.offsetTop ?? 0);

  }

  public sortAllBadgesByOrder(){
    if(this.block.ordenacao == "decrescente"){
      this.block.badges = this.block.badges.sort(sortByOrdem).reverse();
    } else if(this.block.ordenacao == "crescente"){
      this.block.badges = this.block.badges.sort(sortByOrdem);
    }
  }

  public swiperConfigMobile: any = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1366: {
        perViewDesktop: 4,
      }
    }
  }
}
