<form [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f>
  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.NOME.LABEL' | translate }}</label>
    <input formControlName="nome" id="nome" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text">
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>
  <div class="flex flex-wrap mb32" style="gap: 24px;">
    <div class="flex flex-column" style="flex: 1;">
      <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
      <input formControlName="telefone" id="telefone" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="text"
        (keyup)="this.onTextBox($event)" maxlength="15">
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>
    <div class="flex flex-column" style="flex: 1;">
      <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
      <input formControlName="email" id="email" class="bg-transparent cor-white s2 pt8 pb8 pl16 pr16" type="email">
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
  </div>

  <div class="flex flex-column mb32">
    <label class="cor-white s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
    <textarea formControlName="mensagem" class="bg-transparent cor-white s2 pt16 pb16 pl16 pr16" name=""
      id="mensagem"></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div class="flex flex-justify-end">
    <button [disabled]="!form.valid" type="submit"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">
      {{ 'FORM.BOTAO.ENVIAR' | translate }}
    </button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>

</form>