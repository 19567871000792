import {
  Component,
} from '@angular/core';

@Component({
  selector: 'app-sn-block-features',
  templateUrl: './sn-block-features.component.html',
  styleUrls: ['./sn-block-features.component.scss'],
})
export class SNBlockFeatures {
}
