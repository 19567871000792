import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, PLATFORM_ID } from '@angular/core';
import {
  BrowserTransferStateModule,
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';

// const i18nMap = require('../../assets/i18n/autogen/map.json');
import * as i18nMap from '../../assets/i18n/autogen/map.json';

@NgModule({
  imports: [
    HttpClientModule,
    BrowserTransferStateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
})
export class I18nBrowserModule {
  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {
    this.translateService.addLangs(['pt', 'en']);
    this.translateService.setDefaultLang('pt');

    const browserLang =
      this.cookieService.get(
        'lang'
      ) /*|| this.translateService.getBrowserLang()*/ || '';
    // this.translateService.use(browserLang.match(/pt|en/) ? browserLang : 'pt');
    this.translateService.use('pt'); // FORÇAR PT POIS O CONTEÚDO EM INGLES AINDA NAO FOI CADASTRADO
  }
}

export class TranslateBrowserStateLoader implements TranslateLoader {
  constructor(
    private transferState: TransferState,
    private http: HttpClient,
    private prefix: string = 'i18n',
    private suffix: string = '.json'
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key = makeStateKey<any>('transfer-translate-' + lang);
    const data = this.transferState.get(key, null);

    const suffix = `.${(i18nMap as any)[lang]}${this.suffix}`;

    // First we are looking for the translations in transfer-state, if none found, http load as fallback
    return data
      ? of(data)
      : new TranslateHttpLoader(this.http, this.prefix, suffix).getTranslation(
          lang
        );
  }
}

export function translateLoaderFactory(
  httpClient: HttpClient,
  transferState: TransferState
) {
  const prefix = './assets/i18n/autogen/';
  return new TranslateBrowserStateLoader(transferState, httpClient, prefix);
}
