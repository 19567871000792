import { Component, Input } from '@angular/core';
import { ITestimonial } from 'src/models/zeus';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent  {


  @Input() public testimonial!: ITestimonial;


}
