import { Component, Input } from '@angular/core';
import { IBlockBanner } from 'src/models/zeus/block/block-banner';

@Component({
  selector: 'app-block-banner',
  templateUrl: './block-banner.component.html',
  styleUrls: ['./block-banner.component.scss']
})
export class BlockBannerComponent {

  @Input() block!: IBlockBanner;

}
