import { IBlockContactForm } from "./../../../../models/zeus/block/block-contact-form";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-block-contact-form",
  templateUrl: "./block-contact-form.component.html",
  styleUrls: ["./block-contact-form.component.scss"],
})
export class BlockContactFormComponent {
  @Input() block!: IBlockContactForm;
  @Input() formTitle!: string;
}
