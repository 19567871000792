export default function(a:any,b:any){

    if(a.ordem == undefined && b.ordem == undefined){
        return -1;
    }

    if(a.ordem == null){
        a.ordem = 0;
    }

    if(b.ordem == null){
        b.ordem = 0;
    }

    return a.ordem - b.ordem;
}