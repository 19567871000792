<form [formGroup]="form" [class.feedback]="envioStatus" (submit)="submit()" #f>
    <!-- {{ a() }} -->
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.NOME.LABEL' | translate }}</label>
      <input formControlName="nome" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
      <app-errormessage [form]="form" field="nome"></app-errormessage>
    </div>
    <!-- <div class="flex flex-column mb32">
        <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.RAZAOSOCIAL.LABEL' | translate }}</label>
        <input formControlName="razaosocial" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
        <app-errormessage [form]="form" field="razaosocial"></app-errormessage>
    </div> -->
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
      <input formControlName="email" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="email">
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">
        {{ 'FORM.CONCESSIONARIA.QUALLOJA.LABEL' | translate }}</label>
      <select formControlName="qualloja" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16">
        <option value="" disabled selected>{{ 'FORM.BOTAO.SELECIONAR' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO1' | translate }}">
          {{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO1' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO2' | translate }}">
          {{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO2' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO3' | translate }}">
          {{ 'FORM.CONCESSIONARIA.QUALLOJA.OPCAO3' | translate }}</option>
      </select>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">
        {{ 'FORM.CONTACTUS.MOTIVOCONTATO.LABEL' | translate }}</label>
      <select formControlName="motivocontato" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16">
        <option value="" disabled selected>{{ 'FORM.BOTAO.SELECIONAR' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO1' | translate }}">
          {{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO1' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO2' | translate }}">
          {{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO2' | translate }}</option>
        <option value="{{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO3' | translate }}">
          {{ 'FORM.CONCESSIONARIA.MOTIVOCONTATO.OPCAO3' | translate }}</option>
      </select>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
      <input formControlName="telefone" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="tel" (keyup)="this.onTextBox($event)" maxlength="15"
        style="width: 290px;">
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
      <textarea formControlName="mensagem" class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16" name=""
        id=""></textarea>
      <app-errormessage [form]="form" field="mensagem"></app-errormessage>
    </div>
  
    <div class="flex flex-column mb32">
      <label class="flex s2 cor-black flex-items-center">
        <input formControlName="confirmarenvio" type="checkbox" name="finance" [value]="true"> {{ 'FORM.CONTACTUS.CONFIRMARENVIO' | translate }}
      </label>
    </div>
    <div>
      <button type="submit" [disabled]="(!form.valid)"
        class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">{{ 'FORM.BOTAO.ENVIAR' |
        translate }}</button>
    </div>
  
    <app-submitfeedbackmessage *ngIf="envioStatus" [form]="f" [(status)]="envioStatus"></app-submitfeedbackmessage>
  </form>
  <!--
  FORMULÁRIO VÁLIDO: {{ form.valid }} <br>
  {{ form.value | json }} -->
  