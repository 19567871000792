import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { pdvService } from "src/app/services/pdv.service";
import { FiltroBusca, Parametros } from "src/models/FiltroBusca";
import { CategoriaVeiculoEnum, ProdutoPdv } from "src/models/ProdutoPdv";
import { IBlockSeminovosSearchBar } from "src/models/zeus";

@Component({
  selector: "app-block-seminovos-search-bar",
  templateUrl: "./block-seminovos-search-bar.component.html",
  styleUrls: ["./block-seminovos-search-bar.component.scss"],
})
export class BlockSeminovosSearchBarComponent implements OnDestroy, OnInit {
  @Input() block!: IBlockSeminovosSearchBar;
  public formBuscar = new FormGroup({
    termoBuscado: new FormControl(""),
  });
  public filtroOpcoes: FiltroBusca = {
    categoria: [""],
    marca: [""],
    modelo: [{ nome: "", url: " " }],
  };
  public semResultados = false;
  public subscription!: Subscription;
  public icon = "../../assets/images/search.svg";
  categoriaEnum = CategoriaVeiculoEnum;
  categoriaSelecionada = "";
  countCategorias = {
    countTodos: 0,
    countCaminhao: 0,
    countImplemento: 0,
    countMaquinasAgricolas: 0,
    countOnibus: 0,
  };

  constructor(private pdvService: pdvService, private router: Router) {}

  ngOnInit(): void {
    this.getCountCategorias();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public buscar(palavra: string | null): void {
    if (palavra!.length > 1) {
      this.subscription = this.pdvService
        .buscarAutocomplete({
          termo: palavra ?? "",
          categoria: this.categoriaSelecionada ?? "",
        })
        .subscribe((result) => {
          this.filtroOpcoes = this.removerResultadosDuplicados(result.lista);
        });
    }
  }

  public getCountCategorias() {
    this.pdvService.countVeiculosPorCategoria().subscribe((result) => {
      this.countCategorias = result;
    });
  }

  public removerResultadosDuplicados(resultados: ProdutoPdv[]) {
    let modelosTodos = [];
    modelosTodos = resultados.map((prod) => {
      return {
        nome: prod.modelo,
        url: prod.url,
      };
    });

    let resultadoLimpo: FiltroBusca = {
      categoria: [...new Set(resultados.map((prod) => prod.categoria))].sort(),
      marca: [...new Set(resultados.map((prod) => prod.marca))].sort(),
      modelo: modelosTodos.sort(),
    };

    const categoriaFiltrada = resultadoLimpo.categoria.filter((palavra) => {
      const categoria = this._procurarPalavra(
        this.formBuscar.value.termoBuscado!,
        palavra
      );
      return categoria ? true : false;
    });

    const marcaFiltrada = resultadoLimpo.marca.filter((palavra) => {
      const marca = this._procurarPalavra(
        this.formBuscar.value.termoBuscado!,
        palavra
      );
      return marca ? true : false;
    });

    if (
      categoriaFiltrada.length == 0 &&
      modelosTodos.length == 0 &&
      marcaFiltrada.length == 0
    ) {
      this.semResultados = true;
    } else {
      this.semResultados = false;
    }

    resultadoLimpo = {
      categoria: categoriaFiltrada,
      marca: marcaFiltrada,
      modelo: modelosTodos,
    };

    resultadoLimpo = this._limitarListaResultado(resultadoLimpo);

    return resultadoLimpo;
  }

  private _limitarListaResultado(resultado: FiltroBusca) {
    const totalPalavras =
      resultado.categoria.length +
      resultado.marca.length +
      resultado.modelo.length;

    if (totalPalavras > 8) {
      let quantidadeRestante = 8;
      const limiteCategoria = quantidadeRestante - resultado.categoria.length;
      quantidadeRestante = limiteCategoria;
      const limiteMarca = quantidadeRestante - resultado.marca.length;
      quantidadeRestante = limiteMarca;
      const limiteModelo = quantidadeRestante - resultado.modelo.length;

      resultado.categoria = resultado.categoria.slice(0, limiteCategoria);
      resultado.marca = resultado.marca.slice(0, limiteMarca);
      resultado.modelo = resultado.modelo.slice(0, limiteModelo);
    }

    return resultado;
  }

  public navegarCategoria(destino: string) {
    if (destino == "CAMINHÃO") {
      destino = "caminhoes";
    }
    if (destino == "MAQ. LINHA VERDE" || destino == "MAQ. LINHA AMARELA") {
      destino = "maquinas-e-equipamentos-agricolas";
    }

    destino = this._higienizaPalavraEspaçoViraTraco(destino);

    this.router.navigate([`/seminovos/${destino}`]);
  }

  public navegarMarca(marca: string) {
    if (marca == "vw-- volkswagen") {
      marca = "vw-volkswagen";
    }
    this.router.navigate(["/seminovos/marca/", marca]);
  }

  public navegarModelo(modelo: Parametros) {
    const modeloFiltrado: Parametros[] = [];

    this.filtroOpcoes.modelo.forEach((produto) => {
      if (modelo.nome === produto.nome) {
        modeloFiltrado.push(produto);
      }
    });

    if (modeloFiltrado.length === 1) {
      this.router.navigateByUrl(modelo.url);
    } else {
      this.router.navigate(["/seminovos/estoque-completo"], {
        queryParams: { modelo: modelo.nome },
      });
    }
  }

  private _procurarPalavra(palavra: string, str: string) {
    let palavraLimpa = this._higienizaPalavra(palavra);
    let stLimpa = this._higienizaPalavra(str);
    return RegExp(palavraLimpa).test(stLimpa);
  }

  private _higienizaPalavra(palavra: string): string {
    let text = palavra.toLowerCase();

    text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
    text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
    text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
    text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
    text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
    text = text.replace(new RegExp("[Ç]", "gi"), "c");

    text = text.replace(/[^a-zA-Z0-9]/g, "");

    return text;
  }

  private _higienizaPalavraEspaçoViraTraco(palavra: string): string {
    let text = palavra.toLowerCase();
    text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
    text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
    text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
    text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
    text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
    text = text.replace(new RegExp("[Ç]", "gi"), "c");
    text = text.replace(/\((.*?)\)/g, "-");
    text = text.replace(/\s+/g, "-");
    text = text.replace(/---/g, "");

    return text;
  }

  changeCategoriaAutocomplete(categoria: CategoriaVeiculoEnum) {
    if (this.categoriaSelecionada === categoria) {
      this.categoriaSelecionada = "";
    } else {
      this.categoriaSelecionada = categoria;
    }
  }

  getCurrentCount() {
    switch (this.categoriaSelecionada) {
      case CategoriaVeiculoEnum.caminhoes:
        return this.countCategorias.countCaminhao;
      case CategoriaVeiculoEnum.maquinasAgricolas:
        return this.countCategorias.countMaquinasAgricolas;
      case CategoriaVeiculoEnum.implementos:
        return this.countCategorias.countImplemento;
      case CategoriaVeiculoEnum.onibus:
        return this.countCategorias.countOnibus;
      default:
        return this.countCategorias.countTodos;
    }
  }

  navegaParaVitrineCorreta() {
    switch (this.categoriaSelecionada) {
      case CategoriaVeiculoEnum.caminhoes:
        return this.router.navigate(["/seminovos/caminhoes"]);
      case CategoriaVeiculoEnum.maquinasAgricolas:
        return this.router.navigate([
          "/seminovos/maquinas-e-equipamentos-agricolas",
        ]);
      case CategoriaVeiculoEnum.implementos:
        return this.router.navigate(["/seminovos/implementos"]);
      case CategoriaVeiculoEnum.onibus:
        return this.router.navigate(["/seminovos/onibus"]);
      default:
        return this.router.navigate(["/seminovos/estoque-completo"]);
    }
  }
}
