import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { ISNBlockSubheader } from 'src/models/zeus/sempre-novo/sn-block-subheader';

@Component({
  selector: 'app-sn-block-subheader',
  templateUrl: './sn-block-subheader.component.html',
  styleUrls: ['./sn-block-subheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SNBlockSubheader {
  @Input() block!: ISNBlockSubheader;
}
