<form [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.NOME.LABEL' | translate }}</label>
    <input formControlName="nome" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>

  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
      <input formControlName="email" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="email">
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
      <input formControlName="telefone" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="tel"
        (keyup)="this.onTextBox($event)" maxlength="15" style="width: 290px;">
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>
  </div>

  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.RAZAOSOCIAL.LABEL' | translate }}</label>
      <input formControlName="razaosocial" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
      <app-errormessage [form]="form" field="razaosocial"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.CNPJ.LABEL' | translate }}</label>
      <input formControlName="cnpj" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text"
        (keyup)="this.onCnpjTextBox($event)" maxlength="18">
      <app-errormessage [form]="form" field="cnpj"></app-errormessage>
    </div>
  </div>

  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
    <textarea formControlName="mensagem" class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16" name=""
      id=""></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div class="flex flex-column mb16 interesses">
    <label class="cor-black s2 fw600 mb16">{{ 'FORM.SUPPLIER.INTERESSES.LABEL' | translate }}</label>
    <div class="radio flex flex-wrap flex-justify-between">
      <div class="mb16">
        <label class="flex s2 cor-black flex-items-center">
          <input formControlName="interessecaminhao" type="checkbox" name="caminhao" value="">
          {{ 'FORM.SUPPLIER.INTERESSES.CAMINHAO' | translate }}
        </label>
        <app-errormessage [form]="form" field="interessecaminhao"></app-errormessage>
      </div>
      <div class="mb16">
        <label class="flex s2 cor-black flex-items-center">
          <input formControlName="interesselinhaagro" type="checkbox" name="linha-agro" value="">
          {{ 'FORM.SUPPLIER.INTERESSES.LINHAAGRO' | translate }}
        </label>
        <app-errormessage [form]="form" field="interesselinhaagro"></app-errormessage>
      </div>
      <div class="mb16">
        <label class="flex s2 cor-black flex-items-center">
          <input formControlName="interesselinhaamarela" type="checkbox" name="linha-amarela" value="">
          {{ 'FORM.SUPPLIER.INTERESSES.LINHAAMARELA' | translate }}
        </label>
        <app-errormessage [form]="form" field="interesselinhaamarela"></app-errormessage>
      </div>
      <div class="mb16">
        <label class="flex s2 cor-black flex-items-center">
          <input formControlName="interesseintralogistica" type="checkbox" name="intralogistica" value="">
          {{ 'FORM.SUPPLIER.INTERESSES.INTRALOGISTICA' | translate }}
        </label>
        <app-errormessage [form]="form" field="interesseintralogistica"></app-errormessage>
      </div>
    </div>
  </div>

  <div>
    <button type="submit" [disabled]="(!form.valid)"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">{{
      "FORM.BOTAO.ENVIAR" | translate }}</button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>

</form>