<img *ngIf="this.block.backgroundImage?.data as imgdata" [quality]="'50'" [appStrapiimage]="imgdata" class="fundo"
  [id]="this.block.blockIdentifier">

<div class="container">
  <app-content class="titulo pt64" [content]="content" *ngIf="this.block.content as content"
    style="display: block;"></app-content>
</div>

<div class="container-box-meio pt64 pb64">
  <div class="box-meio flex flex-column pa64 glass"
    [style.background-color]="'var('+this.block.background?.data?.attributes?.class +')'"
    [class.black]="this.block.background?.data?.attributes?.class=='--black-translucent'">
    <app-content [content]="content" *ngIf="this.block.contentTitleMedal as content"></app-content>
    <div class="grid w-100">
      <ng-container *ngFor="let medal of this.block.medals">
        <app-medal class="m flex flex-justify-center" [medal]="medal"></app-medal>
      </ng-container>
    </div>
  </div>
</div>