import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, distinctUntilChanged, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TradutorService {

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
  ) {
    const _locale$ = new BehaviorSubject(translateService.currentLang);
    this.translateService.onLangChange.pipe(map(x => x.lang), distinctUntilChanged()).subscribe(lang => _locale$.next(lang));

    this.locale$ = _locale$.pipe(distinctUntilChanged(), shareReplay())
    this.locale$.subscribe(l => this.locale = l);
  }

  public locale: string = 'pt';
  public locale$;


  trocarIdioma(idioma: string, origemDefinidora: string) {

    const self = this;
    if (idioma == self.translateService.currentLang) return;

    this.translateService.use(idioma).subscribe({
      next(r) {
        self.cookieService.set('lang', self.translateService.currentLang);
        self.cookieService.set('origemDefinidora', origemDefinidora);
      },
      error(err) {
        origemDefinidora = 'lingua padrão';
      },
      complete() {
        self.cookieService.set('lang', self.translateService.currentLang);
        self.cookieService.set('origemDefinidora', origemDefinidora);
      }
    });
  }
}
