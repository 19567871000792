import { Component, Input, OnInit } from '@angular/core';
import sortByOrder from 'src/app/helpers/sort/sortByOrder';
import { IPlanLocacao } from 'src/models/zeus';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {

  @Input() public planCard!: IPlanLocacao;

  public ngOnInit(): void {
    if (this.planCard.plan_benefits_locacao) {
      this.planCard.plan_benefits_locacao.data = this.planCard.plan_benefits_locacao.data.sort(sortByOrder);
    }
  }
}
