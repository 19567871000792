import { Component, Input } from '@angular/core';
import { IFAQ } from 'src/models/zeus';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      state('small', style({ height: '0px' })),
      state('large', style({ height: '*' })),
      transition('small <=> large', animate('300ms ease-in'))
    ])
  ],
})
export class FaqComponent {
  @Input() public faqCard!: IFAQ;

  public arrowToggle: boolean = true;
  public state: string = 'small';

  public environment = environment;

  public toggleFaq() {
    this.state = (this.state === 'small' ? 'large' : 'small');
    this.arrowToggle = !this.arrowToggle;
  }

}
