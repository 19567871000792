import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  Subscription, combineLatest, map, shareReplay } from 'rxjs';
import { BlogService } from 'src/app/services/blog.service';
import { TradutorService } from 'src/app/services/tradutor.service';
import { IPost } from 'src/models/IPost';
import { Attributes } from 'src/models/strapi_retorno';
import { IBlockBlogPostListAndFilter } from 'src/models/zeus/block/block-blog-post-list-and-filter';

@Component({
  selector: 'app-block-blog-post-list-and-filter',
  templateUrl: './block-blog-post-list-and-filter.component.html',
  styleUrls: ['./block-blog-post-list-and-filter.component.scss']
})
export class BlockBlogPostListAndFilterComponent implements OnInit, OnDestroy {

  public paginacao = 1;
  public subscription!: Subscription;
  @Input() block!: IBlockBlogPostListAndFilter;
  
  constructor(
    public tradutorService: TradutorService,
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.subscription = combineLatest([this.archive$, this.categories$]).subscribe()
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();

  }

  public listaPura$ = this.blogService.getList$.pipe(map(lista => [...lista.data]), shareReplay());

  public archive$ = this.listaPura$.pipe(
    map(lista => {

      const retorno: { dataObj: Date, dataISO: string, anoMes: string, link: string, items: Attributes<IPost>[] }[] = [];

      lista.forEach(post => {
        const dataObj = this.padronizarData(post.attributes.updatedAt);
        const dataISO = dataObj.toISOString();
        const anoMes = dataObj.toLocaleDateString('default', { month: '2-digit', year: 'numeric' });
        const link = dataObj.getFullYear() + '-' + (("0" + (dataObj.getMonth() + 1)).slice(-2));
        const grupo = retorno.find(x => x.anoMes == anoMes);
        if (grupo) {
          grupo.items.push(post);
        } else {
          retorno.push({ dataObj, dataISO, anoMes, link, items: [post] })
        }
      })
      retorno.sort(
        (a, b) =>
          a.dataObj.getTime() - b.dataObj.getTime()
      )

      return retorno;
    })
  );

  public latestPosts$ = this.listaPura$.pipe(map(lista => {
    return lista.slice(0, 5)
  }))

  public listaFiltrada$ = this.blogService.getFilteredList$;

  public categories$ = this.listaPura$.pipe(map(lista => {
    const todasCategorias = [...new Set(lista.flatMap(x => x.attributes.categoria?.data?.attributes?.name))].filter(x => x);

    return todasCategorias.map(cat => {
      return { nome: cat, link: cat, quantidade: lista.filter(x => x.attributes.categoria?.data?.attributes?.name == cat).length }
    });

  }));


  private padronizarData(data: string) {
    return new Date(new Date(data).toDateString());
  }

  async paginadorMudou(pagina: number) {
    this.paginacao = pagina;
    await this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { pagina },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
}
