import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StrapiimageModule } from 'src/app/directives/strapiimage/strapiimage.module';
import { StrapilinkModule } from 'src/app/directives/strapilink/strapilink.module';
import { FlutuanteComponent } from './flutuante.component';



@NgModule({
  declarations: [
    FlutuanteComponent
  ],
  exports: [
    FlutuanteComponent
  ],
  imports: [
    StrapilinkModule,
    StrapiimageModule,
    CommonModule
  ]
})
export class FlutuanteModule { }
