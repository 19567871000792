<app-content [content]="content" *ngIf="this.block.content as content" [id]="this.block.blockIdentifier"></app-content>

<swiper #swiper [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="estilo1 bg-white"
    [pagination]="{type: 'bullets', clickable: true}" [autoplay]="{delay: 6000, disableOnInteraction: true}"
    [config]="this.swiperConfigMobile" [lazy]="true" [preloadImages]="false">
    <ng-template swiperSlide *ngFor="let carousel of (this.block.carousels | dateFilter); let i = index">
        <app-carousel [formularioFixo]="formularioFixo" [FormStatus]="FormStatus" (displayForm)="FormStatus"
            [firstSlide]="i == 0" class="flex flex-column" [carousel]="carousel"
            [primeiroItemDoPrimeiroBloco]="primeiroBloco && i == 0" (click)="onClickAction()"></app-carousel>
    </ng-template>
</swiper>


<div class="forms-carosel" swiperSlide *ngFor="let carousel of (this.block.carousels| dateFilter); let i = index">
    <div class="form-carosel direita bg-white">
        <app-block-contact-form [formTitle]="'Fale com a vamos locação'" [block]="this.block"></app-block-contact-form>
    </div>
</div>



<div class="container next-prev estilo1">
    <div class="swiper-button-prev" #prev></div>
    <div class="swiper-button-next" #next></div>
</div>