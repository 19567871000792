import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

  transform(value: {dateStart?: string, dateEnd?:string}[], ...args: unknown[]): any[] {
    return value.filter(item => {
      const dateStart = item.dateStart && moment.utc(item.dateStart);
      const dateEnd = item.dateEnd && moment.utc(item.dateEnd);
      let permiteStart = !dateStart;
      let permiteEnd = !dateEnd;
      if(dateStart && moment() > dateStart ) {
        permiteStart = true;
      }
      if(dateEnd && moment() < dateEnd ) {
        permiteEnd = true;
      }
      return permiteStart && permiteEnd;
    })
  }

}
