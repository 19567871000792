<div class="container pa104">
  <h3 class="features-header ejs-h3"><mark class="cdx-marker">VANTAGENS</mark></h3>

  <div class="features pt64 pb64" style="flex-wrap: wrap;">
    <app-sn-feature image="sn-wallet.svg" title="Parcelas que cabem no seu bolso"
      description="Cara de novo, preço de seminovo"></app-sn-feature>

    <app-sn-feature image="sn-check.svg" title="Garantia"
      description="Você opera com segurança e com um produto de procedência."
      tooltipText="Para todos os veículos locados, proporcionamos uma garantia estendida de até 1 ano para o trem de força (que inclui motor, câmbio e diferencial) de todos os veículos fabricados a partir de 2022, desde que tenham até 150.000km rodados. Para todos os veículos seminovos, proporcionamos uma garantia de 90 dias para o trem de força (que inclui motor, diferencial e câmbio)."></app-sn-feature>

    <app-sn-feature image="sn-speed.svg" title="BAIXA QUILOMETRAGEM"
      description="Mais desempenho com menos custo de manutenção."></app-sn-feature>

    <app-sn-feature image="sn-box.svg" title="Variedade de marcas e modelos"
      description="Temos os melhores produtos do mercado."></app-sn-feature>

    <app-sn-feature image="sn-truck.svg" title="Caminhão já implementado"
      description="O produto que você precisa pronto pra uso."></app-sn-feature>

    <app-sn-feature image="sn-wrench.svg" title="Todos os tipos de implementos"
      description="Você escolhe, a gente tem."></app-sn-feature>
  </div>

  <div class="flex ctas">
    <a class="button outline" href="/seminovos/sempre-novo">Quero comprar</a>
    <a class="button" href="/locacao/sempre-novo">Quero alugar</a>
  </div>
</div>