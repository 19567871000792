import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import phoneNumberMask from 'src/app/helpers/mask/phoneNumberMask';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { LeadService } from 'src/app/services/lead.service';
import { environment } from 'src/environments/environment';
import { Status } from 'src/models/Status';
import { IBlockBudgetForm } from 'src/models/zeus';

@Component({
  selector: 'app-form-make-budget-concessionaria',
  templateUrl: './form-make-budget-concessionaria.component.html',
  styleUrls: ['./form-make-budget-concessionaria.component.scss'],
})
export class FormMakeBudgetConcessionariaComponent implements OnInit, OnDestroy {
  @Input() block!: IBlockBudgetForm;
  @Input() identifier = this.block?.identifier ?? this.location.path(false).split('?')[0];

  public envioStatus?:Status;
  public subscription!: Subscription;

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.compose([Validators.required])),
    telefone: new UntypedFormControl('', Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(14)])),
    email: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    mensagem: new UntypedFormControl(''),
    qualloja: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
    confirmarenvio: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
    motivocontato: new UntypedFormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });
  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onTextBox($event: Event) {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = phoneNumberMask(textBox.value);
  }

  public submit() {
    const f = this.form.value;
    let _subjectToEmails: any;

    const pathname  = window.location.pathname
    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash      = window.location.hash
      this.identifier = `${newUrl}${hash}`
    }

    let dados = {
      name: f.nome,
      mobile_phone: f.telefone,
      email: f.email,
      qualloja: f.qualloja,
      mensagem: f.mensagem,
      identificador: this.identifier,
      motivocontato: f.motivocontato,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
    };
    if (this.form.valid) {
      if (
        this.block.subjectToEmails != undefined &&
        this.block.subjectToEmails != null
      ) {
        if (typeof this.block.subjectToEmails[f.motivocontato] == 'object') {
          _subjectToEmails =
            this.block.sendToEmails +
            '\n' +
            this.block.subjectToEmails[f.qualloja].to;
          this.block.replyTo = this.block.subjectToEmails[f.qualloja].replyTo;
        } else {
          _subjectToEmails =
            this.block.sendToEmails +
            '\n' +
            this.block.subjectToEmails[f.qualloja];
        }
      }

      this.envioStatus = 'carregando';
      this.subscription = this.leadService
        .enviarComReponderPara(
          dados,
          _subjectToEmails?.split('\n'),
          this.block.sendToRdStation,
          this.block.replyTo
        )
        .subscribe((sucesso) => {
          this.envioStatus = sucesso ? 'enviado' : 'erro';

          if (this.envioStatus == 'enviado') {
            this.dataLayerService.formSucesso(this.identifier);
          }
        });
    }
  }


  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();

  }
}