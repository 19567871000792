<div>
  <div class="bg-wrapper">
    <img
      class="bg-image mobile-hidden"
      [appStrapiimage]="this.block.backgroundDesktop.data"
      style="position: absolute; z-index: -1;"
      width="100%"
      height="596px"
    />
    <img
      class="bg-image desktop-hidden"
      [appStrapiimage]="this.block.backgroundMobile?.data"
      style="position: absolute; z-index: -1;"
      width="100%"
      height="661px"
    />
  </div>
  <div
    class="container header"
    style="margin: auto 0;"
  >
    <div>
      <img
        [appStrapiimage]="this.block.logo.data"
      />
    </div>
    <p class="title">{{this.block.title}}</p>
    <div class="flex ctas">
      <a class="button outline" href="/seminovos/sempre-novo">{{this.block.ctaBuy}}</a>
      <a class="button" href="/locacao/sempre-novo">{{this.block.ctaRent}}</a>
    </div>
  </div>
</div>
