import {
  Component,
} from '@angular/core';

@Component({
  selector: 'app-sn-block-explore',
  templateUrl: './sn-block-explore.component.html',
  styleUrls: ['./sn-block-explore.component.scss'],
})
export class SNBlockExplore {
}
