import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'app-sn-feature',
  templateUrl: './sn-feature.component.html',
  styleUrls: ['./sn-feature.component.scss'],
})
export class SNFeature {
  @Input() image!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() tooltipText?: string;
}
