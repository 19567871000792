import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey, StateKey } from '@angular/platform-browser';
import { Observable, of, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpStateService {

  private isServer = false;

  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  get<T>(url: string, valorPadrao: any = []) {
    const key = this.getStateKey(url) as any;
    if (this.transferState.hasKey(key)) {
      return of(this.transferState.get<T>(key, valorPadrao)).pipe(shareReplay());
    } else {
      return this.httpClient.get<T>(url).pipe(
        tap((data) => {
          if (this.isServer) {
            this.transferState.set<T>(key, data);
          }
        }),
        shareReplay()
      );
    }
  }

  private getStateKey(key: string) {
    return makeStateKey(key);
  }


}
