import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeEn from "@angular/common/locales/en";
import localePt from "@angular/common/locales/pt";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "./components/common/forms/forms.module";
import { ModalModule } from "./components/common/modal/modal.module";
import { FlutuanteModule } from "./components/flutuante/flutuante.module";
import { FooterModule } from "./components/footer/footer.module";
import { HeaderModule } from "./components/header/header.module";
import { PagesModule } from "./pages/pages.module";
import { AfterIfModule } from "./directives/afterif/afterif.module";
import { ReactiveFormsModule } from "@angular/forms";
import { LocalStorageService } from "./services/local-storage.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

registerLocaleData(localeEn, "en");
registerLocaleData(localePt, "pt");

@NgModule({
  declarations: [AppComponent],
  exports: [AppComponent],
  imports: [
    FormsModule,
    ModalModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    HttpClientModule,
    PagesModule,
    TranslateModule,
    HeaderModule,
    FooterModule,
    AfterIfModule,
    FlutuanteModule,
    ReactiveFormsModule,
  ],
  providers: [
    CookieService,
    LocalStorageService,
    // { provide: HTTP_INTERCEPTORS, useClass: StrapiInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
