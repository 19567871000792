<h2 class="form-title" *ngIf="formTitle">{{ formTitle }}</h2>
<form
  [formGroup]="form"
  [class.feedback]="sendStatus"
  (submit)="submit($event)"
  #f
>
  <div class="flex flex-column mb32">
    <label hidden class="cor-black s2 fw600 mb8">{{
      "FORM.CAMPO.NOME.LABEL" | translate
    }}</label>
    <input
      formControlName="nome"
      [placeholder]="'FORM.CAMPO.NOME.LABEL' | translate"
      class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16"
      type="text"
    />
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>

  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label hidden class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.RAZAOSOCIAL.LABEL" | translate
      }}</label>
      <input
        formControlName="razaosocial"
        [placeholder]="'FORM.CAMPO.RAZAOSOCIAL.LABEL' | translate"
        class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16"
        type="text"
      />
      <app-errormessage [form]="form" field="razaosocial"></app-errormessage>
    </div>

    <div class="flex flex-column mb32">
      <label hidden class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.CNPJ.LABEL" | translate
      }}</label>
      <input
        formControlName="cnpj"
        [placeholder]="'FORM.CAMPO.CNPJ.LABEL' | translate"
        class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16"
        type="text"
        (keyup)="this.onCnpjTextBox($event)"
        maxlength="18"
      />
      <app-errormessage [form]="form" field="cnpj"></app-errormessage>
    </div>
  </div>

  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label hidden class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.TELEFONE.LABEL" | translate
      }}</label>
      <input
        formControlName="telefone"
        [placeholder]="'FORM.CAMPO.TELEFONE.LABEL' | translate"
        class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16"
        type="tel"
        (keyup)="this.onTextBox($event)"
        maxlength="15"
        style="width: 290px"
      />
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>

    <div class="flex flex-column mb32">
      <label hidden class="cor-black s2 fw600 mb8">{{
        "FORM.CAMPO.EMAIL.LABEL" | translate
      }}</label>
      <input
        formControlName="email"
        [placeholder]="'FORM.CAMPO.EMAIL.LABEL' | translate"
        class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16"
        type="email"
      />
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
  </div>

  <div class="flex flex-column mb32 interesses">
    <label class="cor-black s2 fw600 mb16"
      >{{ "FORM.LOCACAO.INTERESSES.LABEL" | translate }}:</label
    >
    <div
      class="flex flex-wrap flex-justify-between"
      style="gap: 32px"
      #checkboxList
    >
      <ng-container *ngFor="let data of Interests; let i = index">
        <label class="flex s2 cor-black flex-items-center">
          <input
            type="checkbox"
            (change)="
              this.onInteresseCheckBox($event);
              checkboxList.classList.add('checkboxList-touched')
            "
            [value]="data.value"
          />{{ "FORM.LOCACAO.INTERESSES." + data.name | translate }}
        </label>
      </ng-container>
      <p
        class="cheboxListErrorMessage"
        *ngIf="this.form.controls['interesses'].errors?.['required']"
      >
        Este é um campo obrigatório, selecionar ao menos uma opção.
      </p>
    </div>
  </div>
  <div class="flex flex-column mb32">
    <label hidden class="cor-black s2 fw600 mb8">{{
      "FORM.CAMPO.MENSAGEM.LABEL" | translate
    }}</label>
    <textarea
      formControlName="mensagem"
      [placeholder]="'FORM.CAMPO.MENSAGEM.LABEL' | translate"
      class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16"
      name=""
      id=""
    ></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div class="flex flex-column mb32 confirmo">
    <label class="flex s2 cor-black flex-items-center">
      <input
        formControlName="confirmarenvio"
        type="checkbox"
        required
        [value]="form.value.confirmarenvio"
      />
      {{ "FORM.CONTACTUS.CONFIRMARENVIO" | translate }}
    </label>
    <p class="cheboxListErrorMessage" *ngIf="!this.form.value.confirmarenvio">
      Este é um campo obrigatório
    </p>
  </div>
  <div>
    <button
      type="submit"
      [disabled]="!form.valid"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull"
    >
      {{ "FORM.BOTAO.ENVIAR" | translate }}
    </button>
  </div>

  <app-submitfeedbackmessage
    *ngIf="sendStatus"
    [form]="f"
    [(status)]="sendStatus"
  ></app-submitfeedbackmessage>
</form>
