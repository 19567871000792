<div class="container flex flex-justify-between flex-wrap flex-items-start pt64 pb64" style="gap: 32px;"
  [id]="this.block.blockIdentifier">
  <div class="box-esquerdo mr16">
    <app-content style="display: block;" [content]="content" *ngIf="this.block.content as content"></app-content>
    <ng-container [ngSwitch]="this.block.formType">
      <app-form-contact-us *ngSwitchCase="'FormContactUs'" [block]="block"></app-form-contact-us>
      <app-form-contact-us-locacao [formTitle]="formTitle" *ngSwitchCase="'FormContactUsLocacao'"
        [block]="block"></app-form-contact-us-locacao>
      <app-form-contact-us-seminovos *ngSwitchCase="'FormContactUsSeminovos'"
        [block]="block"></app-form-contact-us-seminovos>
      <app-form-supplier-registry *ngSwitchCase="'FormSupplierRegistry'" [block]="block"></app-form-supplier-registry>
    </ng-container>
  </div>

  <div class="contact bg-white pa32 mb56" *ngIf="this.block.phone || this.block.email || this.block.whatsapp">
    <h3 class="title s4 cor-gray upc pb16 mb40">Outras formas de nos contactar</h3>

    <ng-container *ngIf="this.block.phone as phone">
      <p class="flex flex-items-center s4 cor-black mb16"><img class="mr16" height="18px" width="18px"
          src="../../../../assets/images/icon-phone-red.svg" alt=""> {{ "PAGE.CONTACT.CALLUS" | translate }}</p>
      <a [appStrapilink]="phone" class="s4 fw600 cor-black udl hv-cor-primary mb40">{{
        phone.label }}</a>
    </ng-container>

    <ng-container *ngIf="this.block.email as email">
      <p class="flex flex-items-center s4 cor-black mb16"><img class="mr16" height="18px" width="18px"
          src="../../../../assets/images/icon-email.svg" alt=""> {{ "PAGE.CONTACT.TEXTUS" | translate }}</p>
      <a [appStrapilink]="email" class="s4 fw600 cor-black udl hv-cor-primary mb40">{{
        email.label }}</a>
    </ng-container>

    <ng-container *ngIf="this.block.whatsapp as whatsapp">
      <p class="flex flex-items-center s4 cor-black mb16"><img class="mr16" height="18px" width="18px"
          src="../../../../assets/images/icon-whatsapp-red.svg" alt=""> {{ "PAGE.CONTACT.WHATSAPPUS" | translate }}</p>
      <a [appStrapilink]="whatsapp" class="s4 fw600 cor-black udl hv-cor-primary">{{
        whatsapp.label }}</a>
    </ng-container>
  </div>
</div>