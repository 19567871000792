<div class="flex flex-column w-100 h-100">

  <ng-container *ngIf="this.planCard.image?.data as imagem">
    <img #imagem [appStrapiimage]="imagem" alt="Plan" width="396" width="240" class="bg" [quality]="'50'"
      widthImg="400">
  </ng-container>

  <div class="flex flex-column flex-items-start pa32 bg-white mb8" style="flex: 1;">
    <h2 class="ejs-header ejs-h2">{{ this.planCard.name }}</h2>
    <p class="ejs-paragraph">{{ this.planCard.description }}</p>

    <div class="flex card-info flex-items-center w-100" *ngFor="let item of this.planCard.plan_benefits_locacao.data">
      <ng-container *ngIf="item.attributes as feature">
        <img height="24px" width="24px" style="padding: 4px 4px 4px 0;" src="../../../../assets/images/icon-check.svg"
          alt="">
        <p class="s2 cor-black ma8">{{ feature.name }}</p>
        <img class="hv-bg-silver" height="24px" width="24px" [tooltip]="feature.description" style="padding: 4px;"
          src="../../../../assets/images/icon-info.svg" alt="">
      </ng-container>
    </div>
    <div class="pt16 w-100"></div>
    <a *ngIf="this.planCard.link as link"
      class="bg-primary s5 cor-white hv-bg-secondary text-center brfull w-100 pa16 mtauto" [appStrapilink]="link">
      {{ link.label }}
    </a>
  </div>
</div>