import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalComponent } from "./modal/modal.component";
import { ClicouModule } from "src/app/directives/clicou/clicou.module";
import { AfterIfModule } from "src/app/directives/afterif/afterif.module";

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [AfterIfModule, ClicouModule, CommonModule],
})
export class ModalModule {}
