<div class="flex flex-column w-100 h-100" style="position: relative">
  <div
    class="flex flex-column bg-silver estilo1"
    style="
      width: 100%;
      aspect-ratio: 290 / 200;
      position: relative;
      overflow: hidden;
    "
  >
    <ng-container *ngIf="slideAtivo">
      <swiper
        #swiper
        [slidesPerView]="1"
        [spaceBetween]="0"
        [navigation]="true"
        [lazy]="true"
        [preloadImages]="false"
        [virtual]="true"
        [navigation]="{ enabled: true, nextEl: next, prevEl: prev }"
        [pagination]="false"
        class="w-100 h-100 b"
      >
        <ng-template swiperSlide *ngFor="let image of offerCard.images">
          <a
            [routerLink]="offerCard.link?.href"
            style="
              display: block;
              height: 100%;
              width: 100%;
              position: relative;
            "
          >
            <img
              [src]="image"
              [alt]="offerCard.description"
              class="w-100 h-100 swiper-lazy"
              loading="lazy"
              widthImg="298"
              [alt]="this.offerCard.activelabel"
            />
          </a>
          <div class="swiper-lazy-preloader"></div>
        </ng-template>
      </swiper>
    </ng-container>

    <a
      *ngIf="!slideAtivo"
      [routerLink]="offerCard.link?.href"
      class="b"
      style="display: block; height: 100%; width: 100%; position: relative"
    >
      <img
        [src]="offerCard.images?.[0]"
        [alt]="this.offerCard.activelabel"
        widthImg="298"
        style="width: 100%; height: 100%; position: relative; object-fit: cover"
      />
    </a>

    <div class="next-prev" (click)="turnOnSwiper()">
      <div class="glass swiper-button-prev swiper-button-disabled" #prev></div>
      <div class="glass swiper-button-next" #next></div>
    </div>

    <div
      class="flex flex-items-center flag glass br5 bg-white-translucent"
      style="padding: 2px 6px; z-index: 2"
    >
      <img
        class="bg-primary"
        style="margin-right: 4px"
        [appStrapiicon]
        icon="ico-label.svg"
        alt=""
        width="18"
        height="18"
      />
      <p style="margin-top: 1px" class="cor-primary s2 fw600 upc">
        {{ this.offerCard.activelabel }}
      </p>
    </div>
  </div>

  <a
    [routerLink]="offerCard.link?.href"
    class="flex flex-column flex-items-start pa32 w-100 bg-white"
    style="flex: 1"
  >
    <h2 class="ejs-header s4 cor-primary ejs-h2">{{ this.offerCard.title }}</h2>
    <p class="ejs-paragraph cor-black s4 fw500 upc mbauto">
      {{ this.offerCard.description }}
    </p>

    <div class="line w-100 mt24 mb24 pt24 pb24">
      <div class="flex flex-wrap w-100" style="gap: 8px 16px">
        <div
          class="flex flex-items-center"
          *ngFor="let feature of offerCard.features"
        >
          <img
            height="24px"
            width="24px"
            class="bg-primary"
            style="margin-right: 4px"
            [appStrapiicon]
            [icon]="feature.image.data.attributes.url"
            alt=""
          />
          <p class="cor-black s2">{{ feature.text }}</p>
        </div>
      </div>
    </div>
    <strong class="cor-black s10 fw600 mtauto">{{
      this.currencyValue(offerCard.price)
    }}</strong>
    <!-- <p class="cor-black s2 mt16">[detalhes]</p> -->
  </a>
</div>
