import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-destaque-busca",
  templateUrl: "./destaque-busca.component.html",
  styleUrls: ["./destaque-busca.component.css"],
})
export class DestaqueBuscaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
