<div class=" status glass w-100 h-100 flex flex-column flex-items-center flex-justify-center" *ngIf="status as st">

  <div class="carregando" *ngIf="st === 'carregando'">
    <app-loading></app-loading>
  </div>

  <div class="sucesso flex flex-column flex-items-center flex-justify-center"
    *ngIf="this.status === 'enviado'">
    <img src="../../../../../assets/images/check-bold.svg" alt="Sucesso" title="Formulário enviado com sucesso." width="64px" height="64px" style="object-fit: contain;">
    <p class="w-100 s8 fw600 text-center mb8 mt16">Enviado com sucesso.</p>
    <p class="w-100 s3 fw400 text-center mb16">Em breve retornaremos o contato.</p>
    <button type="button" class="bg-primary cor-white brfull pt16 pb16 pl64 pr64 s2 fw500 upc" (click)="changeStatus($event); clickBotaoConcluido.emit(); this.form?.reset()">Legal!</button>
  </div>
  
  <div class="sucesso flex flex-column flex-items-center flex-justify-center"
    *ngIf="this.status === 'enviado-newsletter'">
    <img src="../../../../../assets/images/check-bold.svg" alt="Sucesso" title="Formulário enviado com sucesso." width="64px" height="64px" style="object-fit: contain;">
    <p class="w-100 s8 fw600 text-center mb8 mt16">Cadastro realizado com sucesso.</p>
  </div>
  
  <div class="erro flex flex-column flex-items-center flex-justify-center center" *ngIf="st === 'erro'">
    <img [appStrapiicon] icon="times.svg" alt="Sucesso" title="Formulário enviado com sucesso." width="64px" height="64px" style="object-fit: contain;" class="bg-primary">
    <p class="w-100 s8 fw600 text-center mb16 mt16">Ocorreu um erro</p>
    <p class="w-100 s3 fw400 text-center mb16 ">Não foi possível enviar seu contato, tente novamente em alguns instantes.</p>
    <button type="button" class="bg-primary cor-white brfull pt16 pb16 pl64 pr64 s2 fw500 upc" (click)="changeStatus($event); clickBotaoErro.emit()">Ok</button>
  </div>
</div>
