<ng-container *ngIf="(footer$ | async) as footer">
  <div class="container bg-gray pb64 flex flex-column">

    <div class="box flex flex-items-start flex-wrap w-100" style="gap: 32px;">

      <a [routerLink]="['/']" routerLinkActive="router-link-active" class="flex mr128">
        <img height="160px" width="93px" [appStrapiimage]="footer.logo?.data" style="box-shadow: 0 0 16px -8px black;">
      </a>

      <div class="box-esquerdo pt64 flex flex-column ">
        <div class="mb48 cor-white text-normal fw300" [appEditorjsparsehtml]="footer.address"></div>

        <div class="mb48">
          <p class="cor-white s2 fw400 upc mb16" [appEditorjsparsehtml]="footer.contactus"></p>
          <a class="cor-white s2 fw300 hv-udl flex flex-align-center" [appStrapilink]="footer.contactuslink">
            <img class="mr8 bg-white" height="16px" width="16px" [appStrapiicon] icon="icon-phone.svg"
              alt="ícone de um telefone">
            {{ footer.contactuslink?.label}}
          </a>
        </div>

        <div class="flex flex-items-center">
          <p class="cor-white s3 fw400 upc mr8">{{ footer.followus }}</p>
          <a class="cor-branco bg-cor-primary mr8 glass flex flex-align-center flex-justify-center"
            style="padding: 4px;" *ngFor="let l of footer.socialmediaicons" [appStrapilink]="l">
            <img height="20px" width="20px" [appStrapiicon]="l.image?.data" class="bg-white hv-bg-primary"
              style="object-fit: contain;">
          </a>

        </div>
      </div>

      <div class="box-direito flex flex-wrap flex-justify-between pt64 mb32"
        style="display: grid; flex: 3 1 456px; grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); max-width: 100%; gap: 32px 16px; min-width: 0;">
        <ng-container *ngIf="(menu$ | async) as menu">


          <div *ngFor="let raiz of menu.items.data" class=" flex flex-column" style="width: 100%;">
            <p class="cor-white s4 fw400 upc mb8">{{ raiz.attributes.title }}</p>
            <a style="position: relative;" *ngFor="let sub of raiz.attributes.children.data"
              [appStrapilink]="menuToILink(sub)" [aplicarClassesDeLink]="true"
              class="sub cor-white s2 fw400 flex flex-items-center ">
              <span style="z-index: 1; display: inline-block; position: relative;" class="w-100 hv-udl">
                <p class="fw300 s2 w-100" style="position: relative; z-index: 1;">
                  {{ sub.attributes.title }}
                </p>
              </span>
            </a>

          </div>
        </ng-container>
      </div>

    </div>

    <div class="flex flex-wrap-reverse flex-justify-between flex-items-center w-100 mt40" style="gap: 48px;">
      <div class="footer__logo">
        <a href="https://simpar.com.br" target="_blank">
          <img src="../../../assets/images/simpar.png" alt="Logo do Grupo Simpar" width="150px" height="48.5px">
        </a>
      </div>
      <div class="flex flex-wrap footer__sites">
        <a class="flex cor-branco bg-cor-primary" *ngFor="let l of footer.parceiros" [appStrapilink]="l">
          <img height="55px" width="80px" [appStrapiimage]="l.image?.data">
        </a>
      </div>

    </div>
  </div>

  <div class="container flex flex-items-center bg-white pt16 pb16 copyright">
    <div class="mrauto">
      <p class="cor-black s0 fw500 upc">{{ footer.copyright }}</p>
    </div>
    <div class="mlauto">
      <a class="cor-black s0 fw500 upc hv-cor-primary" [appStrapilink]="footer.termLink">{{ footer.termLink?.label}}</a>
    </div>
  </div>
</ng-container>