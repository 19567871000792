<div style="background-color: #fff;" *ngIf="!!this.block.marca ? this.veiculos.length > 0 : true">
  <div class="container flex flex-column pt64">
    <app-content [content]="content" *ngIf="this.block.content as content"></app-content>
  </div>

  <div class="container flex flex-wrap" style="position:relative; gap: 24px;">
    <swiper [config]="swiperConfigMobile" [slidesPerView]="this.slidePerView" [spaceBetween]="24" [navigation]="true"
      [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="my-swiper2" [pagination]="false">
      <ng-template swiperSlide *ngFor="let veiculo of veiculos">
        <app-veiculo-modelo-card [card]="veiculo"></app-veiculo-modelo-card>
      </ng-template>
    </swiper>
    <div class="container next-prev estilo1" [style.top]="(this.marginFundo | async)+'px'">
      <div class="swiper-button-prev" #prev></div>
      <div class="swiper-button-next" #next></div>
    </div>
  </div>

  <div class="flex flex-justify-center mt56 pb64" *ngIf="this.linkFromBlock">
    <app-botao-tipo1 class="redondo" [strapilink]="this.linkFromBlock"></app-botao-tipo1>
  </div>
</div>
