<div class="container flex flex-column pt64" style="position:relative;" [id]="this.block.blockIdentifier">
  <app-content [content]="content" *ngIf="this.block.content as content"></app-content>
</div>

<div class="container pb64 flex flex-column w-100" style="position:relative;">

  <ng-container *ngIf="(posts$ | async) as cards">

    <swiper [config]="swiperConfigMobile" [autoHeight]="true" [spaceBetween]="24"
      [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="w-100">
      <ng-template swiperSlide *ngFor="let card of cards" style="flex: 1">
        <app-card class="h-100" [card]="card"></app-card>
      </ng-template>
    </swiper>

    <div class="container next-prev estilo1">
      <div class="swiper-button-prev" #prev></div>
      <div class="swiper-button-next" #next></div>
    </div>
  </ng-container>

  <div class="pt64 botao-todos" style="position: relative; z-index: 2;">
    <div class="flex flex-items-center flex-wrap flex-justify-center" style="gap: 16px; ">
      <app-botao-tipo1 class="redondo" href="/blog" label="Ver mais" [cor2]="'var(--primary)'"
        [cor1]="'var(--white)'"></app-botao-tipo1>
    </div>
  </div>
</div>