import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { editorJsParser } from 'editorjs-data-parser';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appEditorjsparsehtml]'
})
export class EditorjsparsehtmlDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) { }


  public ngAfterViewInit(): void {
    if (this.appEditorjsparsehtml) {
      this.corrigeImagensLinks();
      this.adicionaTargetBlank();
    }
  }

  private corrigeImagensLinks():void {
     const temSeta = this.startsWith(this.appEditorjsparsehtml);
     let htmlPuro = "";
     let html = this.appEditorjsparsehtml;

    if(!temSeta){
      htmlPuro = editorJsParser(JSON.parse(html as string).blocks);
      // APPEND NO LINK DAS IMAGENS QUE COMEÇAM COM /uploads PARA CONTER O LINK DO BACKEND
      html = htmlPuro.replace(/((src=)'|")(?=\/uploads)/g, "$1" + environment.API_URL);
    }
      
    this.elementRef.nativeElement.innerHTML = html; 
  }

  private adicionaTargetBlank():void{
    /* const html = document.createElement('div');
    html.innerHTML = this.appEditorjsparsehtml!;
    const links = html.getElementsByTagName('a');
    for (let i = 0, len = links.length; i < len; i += 1) {
      links[i].target = '_blank';
      links[i].rel = 'noopener noreferrer';
    }
    this.elementRef.nativeElement.innerHTML = html.innerHTML; */
     
  }

  private startsWith(text: string | undefined): boolean {
    if(text == undefined) return false;
    return text.startsWith('<');
  }

  @Input() appEditorjsparsehtml?: string ;


}
