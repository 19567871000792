<form *ngIf="form" [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f class="flex flex-wrap"
  style="gap: 0 16px;">
  <div class="flex flex-column mb32" style="flex: 1 1 100%;">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.NOME.LABEL' | translate }}</label>
    <input formControlName="nome" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>

  <div class="flex flex-column mb32" style="flex: 1 1 300px;">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
    <input formControlName="email" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="email">
    <app-errormessage [form]="form" field="email"></app-errormessage>
  </div>

  <div class="flex flex-column mb32" style="flex: 1 1 300px;">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
    <input formControlName="telefone" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="tel"
      (keyup)="this.onTextBox($event)" maxlength="15">
    <app-errormessage [form]="form" field="telefone"></app-errormessage>
  </div>



  <div class="flex flex-column mb32" style="flex: 1 1 100%;">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
    <textarea formControlName="mensagem" class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16" name=""
      id=""></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div style="flex: 1 1 100%;">
    <button type="submit" [disabled]="(!form.valid)"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">{{ 'FORM.BOTAO.ENVIAR' |
      translate }}</button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"
    (clickBotaoConcluido)="sent.emit()"></app-submitfeedbackmessage>
</form>