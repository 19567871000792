import { Component, Input } from '@angular/core';
import { IBlockContent } from 'src/models/zeus';

@Component({
  selector: 'app-block-content',
  templateUrl: './block-content.component.html',
  styleUrls: ['./block-content.component.scss']
})
export class BlockContentComponent {

  @Input() block!: IBlockContent;

}
