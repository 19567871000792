import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, delay, of, tap } from "rxjs";
import { SempreNovoModelo } from "src/models/SempreNovoModelo";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: 'app-veiculo-modelo-card',
  templateUrl: './veiculo-modelo-card.component.html',
  styleUrls: ['./veiculo-modelo-card.component.scss'],
})
export class VeiculoModeloCardComponent implements OnDestroy {
  public slideAtivo = false;
  public subscription!: Subscription;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @Input() public card!: SempreNovoModelo;

  public get images() {
    return this.card.imagens.split(",").map((url) => ({
      url: url,
      alt: this.card.marca,
    }));
  }

  public get linkParaDetalhe() {
    return `/locacao/sempre-novo/modelo?id=${this.card.key}`;
  }

  public get whatsAppLink() {
    const modeloURL = `${window.location.origin}/${this.linkParaDetalhe}`;
    return `https://api.whatsapp.com/send?phone=5511978379385&text=Olá, tenho interesse em alugar um Sempre Novo do modelo: ${modeloURL}`;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public turnOnSwiper() {
    if (!this.slideAtivo) {
      this.slideAtivo = true;
      this.subscription = of(true).pipe(
        delay(500),
        tap(() => {
          this.swiper?.swiperRef.slideNext();
        })).subscribe();

    }
  }

  public currencyValue(value: number | undefined) {
    if (value != undefined) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }
    return value;
  }
}
