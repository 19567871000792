<img *ngIf="this.block.backgroundImage?.data as imgdata" [appStrapiimage]="imgdata" class="fundo" [quality]="'60'"
  [id]="this.block.blockIdentifier">


<div class="container flex flex-column pt64" style="position:relative;">
  <app-content [content]="content" *ngIf="this.block.content as content"></app-content>
</div>

<div class="container pb64" style="position:relative;">

  <swiper [config]="swiperConfigMobile" [autoHeight]="true" [spaceBetween]="24"
    [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="w-100">
    <ng-template swiperSlide *ngFor="let plancard of (this.block.plan_locacao.data | sort : 'id') " class="h-100"
      style="flex: 1">
      <app-plan-card class="h-100" [planCard]="plancard.attributes"></app-plan-card>
    </ng-template>
  </swiper>

  <div class="container next-prev estilo1" [style.top]="(this.marginFundo | async)+'px'">
    <div class="swiper-button-prev" #prev></div>
    <div class="swiper-button-next" #next></div>
  </div>

  <div class="pt64 botao-todos" style="position: relative; z-index: 2;" *ngIf="this.block.links">
    <div class="flex flex-items-center flex-wrap flex-justify-center" style="gap: 16px; ">
      <app-botao-tipo1 class="redondo" [strapilink]="block.links" [cor2]="'var(--primary)'"
        [cor1]="'var(--white)'"></app-botao-tipo1>
    </div>
  </div>
</div>
