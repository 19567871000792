import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrapiimageDirective } from './strapiimage.directive';



@NgModule({
  declarations: [
    StrapiimageDirective
  ],
  exports: [
    StrapiimageDirective
  ],
  imports: [
    CommonModule
  ]
})
export class StrapiimageModule { }
