<app-content class="container pt64 flex flex-column" [content]="content" *ngIf="this.block.content as content"
  [id]="this.block.blockIdentifier"></app-content>
<div class="container flex pb64" style="position: relative;">
  <swiper [config]="swiperConfigMobile" [slidesPerView]="4" [spaceBetween]="0" [navigation]="true" class="mt40 mb96"
    [navigation]="{enabled:true, nextEl:next , prevEl: prev }" class="estilo1 w-100">
    <ng-template swiperSlide *ngFor="let badge of this.block.badges">
      <app-badge class="flex flex-column" [badge]="badge"></app-badge>
    </ng-template>
  </swiper>
  <div class="container next-prev estilo1">
    <div class="swiper-button-prev" #prev></div>
    <div class="swiper-button-next" #next></div>
  </div>
</div>