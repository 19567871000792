import { Component, Input } from '@angular/core';
import { IMedal } from 'src/models/zeus';

@Component({
  selector: 'app-medal',
  templateUrl: './medal.component.html',
  styleUrls: ['./medal.component.scss']
})
export class MedalComponent {

  @Input() public medal!: IMedal;


}
