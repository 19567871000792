import { Component, Input } from '@angular/core';
import { IBadge } from 'src/models/zeus';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  @Input() public badge!: IBadge;

}
