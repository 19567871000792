import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, switchMap, tap } from 'rxjs';
import { HttpStateService } from 'src/app/services/http.state.service';
import { SeoService } from 'src/app/services/seo.service';
import { TradutorService } from 'src/app/services/tradutor.service';
import { environment } from 'src/environments/environment';
import { IPost } from 'src/models/IPost';
import { StrapiRetorno } from 'src/models/strapi_retorno';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent {

  constructor(
    private httpStateService: HttpStateService,
    public tradutorService: TradutorService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService
  ) {}

  public slug$ = this.activatedRoute.params.pipe(map(params => {
    return params['slug'];
  }))

  erro = false;
  public post$?: Observable<IPost> = this.slug$.pipe(switchMap(slug => {
    return this.tradutorService.locale$.pipe(switchMap(idioma => {
      const url = environment.API_URL + environment.API_PATH_BLOG + '?populate=deep&locale=' + idioma + '&filters[slug][$eq]=' + slug;
      return this.httpStateService.get<StrapiRetorno<IPost>>(url)
    })).pipe(map(x => x.data?.[0]?.attributes)).pipe(tap(d => this.erro = !d)).pipe(tap(o => {
      this.seoService.update(o.seo);
    }));
  }))

}
