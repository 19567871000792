import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type AgendamentoFilial = {
  filialEnum: number;
  embed: string;
};

@Injectable({
  providedIn: "root",
})
export class AgendamentoFilialService {
  constructor(private httpClient: HttpClient) {}

  getFilialCalendario(idFilial: number): Observable<AgendamentoFilial> {
    return this.httpClient.get<AgendamentoFilial>(
      `${environment.API_URL}/api/veiculosdb/filial-por-id?filial=${idFilial}`
    );
  }
}
