import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IBlockPlanCard } from 'src/models/zeus';

@Component({
  selector: 'app-block-plan-card',
  templateUrl: './block-plan-card.component.html',
  styleUrls: ['./block-plan-card.component.scss']
})
export class BlockPlanCardComponent implements OnInit {

  public marginFundo = new BehaviorSubject<number>(240);
  public heightFundo: number = 0;
  public subscription!: Subscription;

  @Input() block!: IBlockPlanCard;
  @ViewChild('element', {static: true}) element?: ElementRef<HTMLDivElement>;

  constructor(
    public elementRef: ElementRef<HTMLElement>
    ) { }

  public ngOnInit(): void {

    this.subscription = this.marginFundo.subscribe(tamanho => {
      this.calcularHeight(tamanho);
    });

  }
  
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public calcularHeight(margin: number){
    this.heightFundo = this.elementRef.nativeElement.parentElement!.offsetHeight - (margin) - (this.element?.nativeElement.offsetTop ?? 0);

  }

  public swiperConfigMobile: any = {
    slidesPerView: 'auto',
    breakpoints: {
      0: {
        slidesPerView: 1,
        autoHeight: false,
      },
      768: {
        slidesPerView: 2,
        autoHeight: false,
      },
      992: {
        slidesPerView: 3,
      }
    }
  }
}
