import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IBlockTestimonial } from 'src/models/zeus';

@Component({
  selector: 'app-block-testimonial',
  templateUrl: './block-testimonial.component.html',
  styleUrls: ['./block-testimonial.component.scss']
})
export class BlockTestimonialComponent implements OnInit, OnDestroy {

  public marginFundo = new BehaviorSubject<number>(240);
  public heightFundo: number = 0;
  public subscription!: Subscription;
  @Input() block!: IBlockTestimonial;
  @ViewChild('element', {static: true}) element?: ElementRef<HTMLDivElement>;

  constructor(
    public elementRef: ElementRef<HTMLElement>
    ) { }

  public ngOnInit(): void {

   this.subscription = this.marginFundo.subscribe(tamanho => {
      this.calcularHeight(tamanho);
    });
  }
  
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();

  }

  public calcularHeight(margin: number){
    this.heightFundo = this.elementRef.nativeElement.parentElement!.offsetHeight - (margin) - (this.element?.nativeElement.offsetTop ?? 0);

  }

}
