import { Component, Input, OnInit } from '@angular/core';
import { IBlockImage } from 'src/models/zeus/block/block-image';

@Component({
  selector: 'app-block-image',
  templateUrl: './block-image.component.html',
  styleUrls: ['./block-image.component.scss']
})
export class BlockImageComponent {
  @Input() block!: IBlockImage;

  public sortAllImageTexsByOrder(){
    if(this.block.ordenacao == "decrescente"){
      this.block.images = this.block.images.sort().reverse();
    } if(this.block.ordenacao == "crescente"){
      this.block.images = this.block.images.sort();
    }
  }
}
