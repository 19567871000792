import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const id = val.url.split("#")[1];

        Object.keys(this.openClose).forEach((prop) => {
          this.openClose[prop] = false;
        });

        if (id) {
          // abre o modal pelo fragmento
          this.openClose[id] = true;
        }

        this.openClose$.next(this.openClose);
      }
    });
  }

  openClose: { [key: string]: boolean } = {};
  openClose$ = new BehaviorSubject(this.openClose);
}
