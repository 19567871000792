<form [formGroup]="form" [class.feedback]="sendStatus" (submit)="submit()" #f>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.NOME.LABEL' | translate }}</label>
    <input formControlName="nome" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="text">
    <app-errormessage [form]="form" field="nome"></app-errormessage>
  </div>
  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.EMAIL.LABEL' | translate }}</label>
      <input formControlName="email" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="email">
      <app-errormessage [form]="form" field="email"></app-errormessage>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.TELEFONE.LABEL' | translate }}</label>
      <input formControlName="telefone" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" type="tel"
        (keyup)="this.onTextBox($event)" maxlength="15" style="width: 290px;">
      <app-errormessage [form]="form" field="telefone"></app-errormessage>
    </div>
  </div>
  <div class="bloco-column">
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">{{ 'FORM.CONTACTUS.ESTADO.LABEL' | translate }}</label>
      <select formControlName="estado" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16" style="width: 290px;">
        <option value="" disabled selected>{{ 'FORM.BOTAO.SELECIONAR' | translate }}</option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO1' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO1' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO2' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO2' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO3' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO3' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO4' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO4' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO5' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO5' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO6' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO6' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO7' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO7' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO8' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO8' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO9' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO9' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO10' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO10' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO11' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO11' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO12' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO12' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO13' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO13' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO14' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO14' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO15' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO15' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO16' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO16' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO17' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO17' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO18' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO18' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO19' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO19' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO20' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO20' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO21' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO21' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO22' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO22' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO23' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO23' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO24' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO24' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO25' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO25' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO26' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO26' | translate }}
        </option>
        <option value="{{ 'FORM.CONTACTUS.ESTADO.OPCAO27' | translate }}">
          {{ 'FORM.CONTACTUS.ESTADO.OPCAO27' | translate }}
        </option>
      </select>
    </div>
    <div class="flex flex-column mb32">
      <label class="cor-black s2 fw600 mb8">
        {{ 'FORM.CONTACTUS.MOTIVOCONTATO.LABEL' | translate }}</label>
      <select formControlName="motivocontato" class="bg-transparent cor-black s2 pt8 pb8 pl16 pr16">
        <option value="" disabled selected>{{ 'FORM.BOTAO.SELECIONAR' | translate }}</option>
        <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO3' | translate }}">
          {{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO3' | translate }}</option>
        <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO4' | translate }}">
          {{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO4' | translate }}</option>
        <option value="{{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO6' | translate }}">
          {{ 'FORM.CONTACTUS.MOTIVOCONTATO.OPCAO6' | translate }}</option>
      </select>
    </div>
  </div>
  <div class="flex flex-column mb32">
    <label class="cor-black s2 fw600 mb8">{{ 'FORM.CAMPO.MENSAGEM.LABEL' | translate }}</label>
    <textarea formControlName="mensagem" class="bg-transparent cor-black s2 pt16 pb16 pl16 pr16" name=""
      id=""></textarea>
    <app-errormessage [form]="form" field="mensagem"></app-errormessage>
  </div>

  <div class="flex flex-column mb32 confirmo">
    <label class="flex s2 cor-black flex-items-center">
      <input formControlName="confirmarenvio" type="checkbox" name="finance" [value]="true"> {{
      'FORM.CONTACTUS.CONFIRMARENVIO' | translate }}
    </label>
  </div>
  <div>
    <button type="submit" [disabled]="(!form.valid)"
      class="bg-primary cor-white hv-bg-secondary s4 fw400 pt16 pb16 pl64 pr64 brfull">{{ 'FORM.BOTAO.ENVIAR' |
      translate }}</button>
  </div>

  <app-submitfeedbackmessage *ngIf="sendStatus" [form]="f" [(status)]="sendStatus"></app-submitfeedbackmessage>
</form>