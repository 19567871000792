import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function documentValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const validCNPJ = validateCNPJ(value);
    const validCPF = validateCPF(value);
    
    return validCNPJ || validCPF ? null : {
        document: true,
    };
  };
}

export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return validateCPF(value) ? null : {
        cpf: true
    };
  };
}

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return validateCNPJ(value) ? null : {
        cnpj: true
    };
  };
}

function validateCPF(cpf: string): boolean {
  const cleanCPF = cpf.replace(/\D/g, '');
  
  if (cleanCPF == "00000000000") return false;
  if (cleanCPF.length != 11) return false;

  let sum = 0;
  for (let i = 1; i <= 9; i++) {
    let digit = Number(cleanCPF.substring(i - 1, i));
    sum = sum + digit * (11 - i);
  }

  let rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11)
    rest = 0;
  if (rest !== Number(cleanCPF.substring(9, 10)))
    return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    let digit = Number(cleanCPF.substring(i - 1, i));
    sum = sum + digit * (12 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11)
    rest = 0;
  if (rest !== Number(cleanCPF.substring(10, 11)))
    return false;

  return true;
}

function validateCNPJ(cnpj: string): boolean {
    const sanitized = cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(sanitized == '') return false;
     
    if (sanitized.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (sanitized == "00000000000000" || 
        sanitized == "11111111111111" || 
        sanitized == "22222222222222" || 
        sanitized == "33333333333333" || 
        sanitized == "44444444444444" || 
        sanitized == "55555555555555" || 
        sanitized == "66666666666666" || 
        sanitized == "77777777777777" || 
        sanitized == "88888888888888" || 
        sanitized == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0,tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0), 10))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != parseInt(digitos.charAt(1), 10))
          return false;
           
    return true;
    
}